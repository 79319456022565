import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PhoneService {
  constructor() {}

  /**
   * Format the phone number with hyphens separating each part.
   *
   * @param phoneNumber phone number as string
   * @returns the phone number with each part separated by hyphens
   */
  public formatPhoneNumber(phoneNumber: string): string {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    }

    return phoneNumber;
  }
}
