export enum PaymentTypes {
  CreditCard = 'Credit Card',
  ApplePay = 'Apple Pay',
  GooglePay = 'Google Pay',
  Venmo = 'Venmo',
  Paypal = 'PayPal',
  BitPay = 'Bitcoin/Bitcoin Cash',
  Recharge = 'recharge',
  PayLater = 'payLater',
  GiftCard = 'giftCard',
  Free = 'free',
}
