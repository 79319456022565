export class DateSelection {
  month: number;
  day: number;
  year: number;

  constructor(month: number, day: number, year: number) {
    this.month = month;
    this.day = day;
    this.year = year;
  }

  /**
   * Gets a date object based on the date selection.
   */
  get date(): Date | null {
    return this.month && this.day && this.year ? new Date(this.year, this.month - 1, this.day) : null;
  }

  /**
   * Gets the age of the patient based on the date selection.
   *
   * @returns {number} the patient's age
   */
  getAge(): number {
    const today = new Date();
    const currentYear = today.getFullYear();
    const age = currentYear - this.date.getFullYear();

    return today >= new Date(currentYear, this.date.getMonth(), this.date.getDate()) ? age : age - 1;
  }

  /**
   * Determines if the date selection is valid.
   *
   * @param {number} minAge the minimum patient age
   * @param {number} maxAge the maximum patient age
   *
   * @returns {boolean} true if the date selection is valid, false otherwise
   */
  isAgeValid(minAge: number = 18, maxAge?: number): boolean {
    if (!this.date) {
      return true;
    }

    const age = this.getAge();

    return age >= minAge && (!maxAge || age < maxAge);
  }
}
