// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `iframe {
  border-radius: 0.5rem !important;
  max-width: 100% !important;
}

app-healthlabs-layout .clinician-notification {
  background-color: #4ab873;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
