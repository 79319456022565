export class ReviewsFeature {
  orderId: string;
  name: string;
  email: string;

  constructor(orderId: string, name: string, email: string) {
    this.orderId = orderId;
    this.name = name;
    this.email = email;
  }
}
