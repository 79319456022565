import { Component, Inject } from '@angular/core';
import { UtiConsultationRequestUpsellComponent } from '@consultation-upsells/uti-consultation-request-upsell.component';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { FormService } from '@services/form.service';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-be-prepared-for-the-unexpected',
  templateUrl: './be-prepared-for-the-unexpected.component.html',
  styleUrls: ['./be-prepared-for-the-unexpected.component.scss'],
})
export class BePreparedForTheUnexpectedComponent extends UtiConsultationRequestUpsellComponent {
  protected override slug = UpsellSlugs.Levonorgestrel;

  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected formService: FormService,
    protected sessionStorageService: SessionStorageService
  ) {
    super(config, formService, sessionStorageService);
  }
}
