import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Gender } from '@enums/gender';

export class FormHelper {
  /**
   * Creates a list of numbers.
   *
   * @param start The number to start at.
   * @param end The number to finish at.
   */
  getListOfNumbers(start: number, end: number): number[] {
    let numbers: number[] = [];
    const [highest, lowest] = start > end ? [start, end] : [end, start];
    for (let i = lowest; i <= highest; i++) {
      numbers.push(i);
    }

    return start < end ? numbers : numbers.reverse();
  }

  /**
   * Determines if a form control is invalid.
   *
   * @param {AbstractControl} control The form control to check for validity.
   */
  isInvalid(control: AbstractControl): boolean {
    return control?.invalid && (control?.dirty || control?.touched);
  }

  /**
   * Split DB date formats into separate month, day, year.
   *
   * @param date Date in Y-m-d format.
   */
  splitDateIntoPieces(date: string): { month: string; day: string; year: string } {
    if (date) {
      const dateObj = new Date(date);

      return {
        month: String(dateObj.getUTCMonth() + 1),
        day: String(dateObj.getUTCDate()),
        year: String(dateObj.getUTCFullYear()),
      };
    }

    return { month: null, year: null, day: null };
  }

  /**
   * Checks if the value belongs to the specified array of genders.
   *
   * @param {Gender[]} allowedGenders the allowed genders
   *
   * @returns {ValidatorFn} a ValidatorFn function that can be used in Angular reactive forms
   */
  genderValidator(allowedGenders: Gender[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }

      return allowedGenders.includes(value) ? null : { invalidGender: true };
    };
  }

  /**
   * Calculate age in years.
   *
   * @param dob Date of birth.
   */
  calculatePatientAge(dob: Date): number {
    const ageDate = new Date(Date.now() - dob.getTime());
    const minimumYear = 1970;
    return Math.abs(ageDate.getUTCFullYear() - minimumYear);
  }

  /**
   * Retrieves the validation error message for a form control.
   *
   * @param {AbstractControl} control the form control to validate
   * @param {string} displayName the display name of the control
   *
   * @returns {string} the validation error message
   */
  getControlValidationErrorMessage(control: AbstractControl, displayName: string): string {
    if (control.errors.required) {
      return `${displayName} is required`;
    }

    if (control.errors.minlength) {
      return `${displayName} must be at least ${control.errors.minlength.requiredLength} characters long`;
    }

    return `${displayName} is invalid`;
  }

  /**
   * Gets the date of birth from a form.
   */
  getDob(form: FormGroup): Date | null {
    const dob = form?.get('birthday')?.value;

    if (!dob) {
      return null;
    }

    return new Date(dob.year, dob.month - 1, dob.day);
  }
}
