// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.only-healthlabs.checkout-text {
  text-align: center;
  font-weight: bolder;
  font-size: 2.5rem;
  padding-top: 1.4rem;
  padding-bottom: 0;
}

.spacer {
  padding: 0.5rem;
}

@media screen and (min-width: 1150px) {
  .sticky {
    position: sticky;
    top: 1em;
  }
}
.sticky {
  z-index: 1;
}

.white-background-header {
  box-shadow: 0 0 0 100vmax #fff;
  clip-path: inset(0 -100vmax);
  background-color: #fff;
  margin-top: -1rem;
}

app-starfish-layout :host ::ng-deep .order-header-title strong {
  color: #fa676b !important;
}
app-starfish-layout :host ::ng-deep .order-header-subtitle {
  max-width: 450px;
  margin: 0 auto;
}

app-treatmyuti-layout :host ::ng-deep .order-header-title strong {
  color: #b46397 !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
