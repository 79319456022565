import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';

@Injectable({ providedIn: 'root' })
export class WhitelistGuard {
  constructor(@Inject(APP_CONFIG) private config: AppConfig, @Inject(DOCUMENT) private document: Document) {}

  /**
   * Checks if the user has access to the specified route.
   *
   * @param {ActivatedRouteSnapshot} route the activated route snapshot
   * @param {RouterStateSnapshot} state the current state of the route
   *
   * @returns {boolean} whether the route is allowed
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.isRouteAllowed(state.url)) {
      return true;
    }

    this.document.location.href = this.config.siteUrls.notFound;

    return false;
  }

  /**
   * Checks if the route is allowed based on the application configuration.
   *
   * @param {string} url the URL of the route to check
   *
   * @returns {boolean} true if the route is allowed, false otherwise
   */
  private isRouteAllowed(url: string): boolean {
    return this.config.allowedRoutes.some((route) => this.matchRoute(route, url));
  }

  /**
   * Checks if an allowed route matches the provided URL.
   *
   * @param {string} allowedRoute the allowed route
   * @param {string} url the URL of the route to check
   *
   * @returns {boolean} true if the route matches, false otherwise
   */
  private matchRoute(allowedRoute: string, url: string): boolean {
    const cleanUrl = url.split('?')[0];

    return allowedRoute.endsWith('*') ? cleanUrl.startsWith(allowedRoute.slice(0, -1)) : cleanUrl === allowedRoute;
  }
}
