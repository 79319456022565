import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OrderTest } from '@models/order-test';
import { SelectableOption } from '@models/selectable-option';
import { TestVisibilities } from '@models/test-visibilities';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import * as Sentry from '@sentry/angular';
import { SitesService } from '@services/sites.service';
import { finalize } from 'rxjs';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-consultation-request-preferred-medication-card',
  templateUrl: './consultation-request-preferred-medication-card.component.html',
  styleUrls: ['./consultation-request-preferred-medication-card.component.scss'],
})
export class ConsultationRequestPreferredMedicationCardComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() tests: OrderTest[] = [];
  preferredMedicationOptions: SelectableOption[] = [];

  private initialPreferredMedication: string;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private sitesService: SitesService,
    private sessionStorageService: SessionStorageService
  ) {}

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.setPreferredMedicationOptions();
  }

  /**
   * Gets whether the initial preferred medication is an option in the preferred medication options.
   *
   * @returns {boolean} whether the initial preferred medication is an option
   */
  private get isInitialPreferredMedicationAnOption(): boolean {
    return this.preferredMedicationOptions.some((option) => option.value === this.initialPreferredMedication);
  }

  /**
   * Sets preferred medication options by fetching test visibilities from the sites service.
   */
  private setPreferredMedicationOptions(): void {
    this.sitesService
      .getTestVisibilities(this.config.siteId)
      .pipe(
        finalize(() => {
          this.ensureThereIsAtLeastOneOption();
          this.prefillPreferredMedication();
        })
      )
      .subscribe({
        next: (testVisibilities) => this.handleTestVisibilities(testVisibilities),
        error: (err) => this.handleGetTestVisibilitiesError(err),
      });
  }

  /**
   * Handles test visibilities received from the sites service by mapping the data to dropdown options and setting the
   * initial preferred medication value from the order tests.
   *
   * @param {TestVisibilities} testVisibilities the test visibilities data
   */
  private handleTestVisibilities(testVisibilities: TestVisibilities): void {
    this.preferredMedicationOptions = testVisibilities.data.map((data) => new SelectableOption(data.test.name));
    this.initialPreferredMedication = this.getInitialPreferredMedication();
  }

  /**
   * Gets the initial preferred medication value from the order tests.
   *
   * @returns {string} the initial preferred medication value
   */
  private getInitialPreferredMedication(): string {
    return this.sessionStorageService.preferredMedication
      ? this.sessionStorageService.preferredMedication
      : this.tests.find((orderTest) =>
          this.preferredMedicationOptions.some(({ value }) => value === orderTest.customer_tests_name)
        )?.customer_tests_name;
  }

  /**
   * Handles errors that occur during the retrieval of test visibilities by capturing them with Sentry.
   *
   * @param {any} error the error that occurred during the site visibilities request
   */
  private handleGetTestVisibilitiesError(error: any): void {
    try {
      Sentry.captureException(error);
    } catch (err) {}
  }

  /**
   * Prefills the preferred medication value in the medical questionnaire form if available.
   */
  private prefillPreferredMedication(): void {
    if (!this.isInitialPreferredMedicationAnOption) {
      return;
    }

    this.form?.patchValue({
      preferredMedication: this.initialPreferredMedication,
    });
  }

  /**
   * Ensures that there is at least one option available for preferred medication. If there are no options available, it
   * initializes the options with a default value. This can happen if either the test visibilities request failed or
   * returned an empty array.
   */
  private ensureThereIsAtLeastOneOption(): void {
    if (this.preferredMedicationOptions.length > 0) {
      return;
    }

    this.initialPreferredMedication = 'Let the doctor decide!';
    this.preferredMedicationOptions = [new SelectableOption(this.initialPreferredMedication)];
  }
}
