// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-content {
  background-color: #fceedc !important;
}

.separator {
  background-color: hsl(0, 0%, 86%);
  height: 1px;
}

.subtitle {
  color: #ed9013;
  font-size: 1.5em;
  font-weight: bold;
}

.child-test {
  padding-bottom: 0;
}
.child-test i.fa {
  color: #809c58;
  padding-right: 5px;
}
.child-test span {
  color: #416e8b;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  button.action {
    font-size: 1rem !important;
  }
  .subtitle {
    font-size: 1.125rem !important;
  }
  .upgrade__price_container span {
    font-size: 1.25rem !important;
  }
  .upgrade__button_container {
    padding-bottom: 0px;
  }
  .upgrade__price_container {
    text-align: center !important;
    padding-top: 0px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
