import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormHelper } from '@common/form-helper';

@Component({
  selector: 'app-question-answer-field',
  templateUrl: './question-answer-field.component.html',
  styleUrls: ['./question-answer-field.component.scss'],
})
export class QuestionAnswerFieldComponent extends FormHelper {
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() label: string;
  @Input() index: number;

  /**
   * Checks if the form control is invalid by required validation.
   */
  get invalidByRequired(): boolean {
    const control = this.form?.get(this.controlName);

    return control?.errors?.['required'] && (control?.dirty || control?.touched);
  }
}
