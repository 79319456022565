import { environment } from '@environments/environment';
import { treatmyutiCommonEnvironment } from '@environments/treatmyuti/environment.common';

/**
 * Environment variables for use on the TreatMyUTI.com site, in production environment.
 */
export const treatmyutiEnvironment = {
  ...environment,
  ...treatmyutiCommonEnvironment,
  googleMapApiKey: 'AIzaSyDIEZHrvUCVq39wvL0mBOanghqkHIk8cRE',
  apiUrl: 'https://api.analytecare.com',
  siteUrls: {
    home: 'https://treatmyuti.com',
    termsOfService: 'https://treatmyuti.com/terms-of-service/',
    myAccount: 'https://my-account.treatmyuti.com',
    tests: 'https://treatmyuti.com/antibiotics/',
    notFound: 'https://treatmyuti.com/404',
  },
  siteId: '17',
};
