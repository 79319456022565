import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigService } from './services/config.service';
import { OrderServiceProvider } from '@modules/config/providers/order.service.provider';
import { ConfigServiceProviders } from '@modules/config/providers/config.service.provider';
import { ConsultationRequestServiceProvider } from '@modules/config/providers/consultation.service.provider';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [ConfigService],
})
export class ConfigModule {
  constructor(@Optional() @SkipSelf() parentModule?: ConfigModule) {
    if (parentModule) {
      throw new Error('ConfigModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<ConfigModule> {
    return {
      ngModule: ConfigModule,
      providers: [...ConfigServiceProviders, OrderServiceProvider, ConsultationRequestServiceProvider],
    };
  }
}
