// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul {
  -moz-column-count: 2;
       column-count: 2;
}
@media screen and (min-width: 769px), print {
  ul {
    -moz-column-count: 3;
         column-count: 3;
  }
}
@media screen and (min-width: 1150px) {
  ul {
    -moz-column-count: 4;
         column-count: 4;
  }
}

a:hover {
  color: #355a73;
}

input {
  padding: 0.4rem 0.5rem !important;
}

.modal-card {
  width: 90%;
}
@media screen and (min-width: 1150px) {
  .modal-card {
    width: 1150px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
