import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormService } from '@services/form.service';
import { UtiConsultationRequestUpsellComponent } from '@consultation-upsells/uti-consultation-request-upsell.component';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { SelectableOption } from '@models/selectable-option';
import { SessionStorageService } from '@services/session-storage.service';
import { PaymentTypes } from '@enums/payment-types';
import { UpsellStoreRequest } from '@models/requests/upsell-store.model';
import { OrderService } from '@services/order.service';
import { PlaceOrderComponent } from '@components/place-order/place-order.component';
import { ErrorHandlerService } from '@services/error-handler.service';
import { TreatmentConsultationQuestionnaireService } from '@services/treatment-consultation-questionnaire.service';
import { UpsellStoreResponse } from '@models/upsell-store-response.model';
import { OrderUpsell } from '@models/order-upsell';
import { NavigationService } from '@services/navigation.service';
import * as Sentry from '@sentry/angular';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';

@Component({
  selector: 'app-medication-pickup-delivery',
  templateUrl: './medication-pickup-delivery.component.html',
  styleUrls: ['./medication-pickup-delivery.component.scss'],
  encapsulation: ViewEncapsulation.None, // Disable encapsulation
})
export class MedicationPickupDeliveryComponent extends UtiConsultationRequestUpsellComponent implements OnInit {
  @ViewChild('placeOrder', { static: false }) placeOrderComponent: PlaceOrderComponent;
  protected override slug = UpsellSlugs.DeliverMedication;
  readonly deliverMedicationControlName = 'deliverMedication';
  readonly shippingAddressControlName = 'shippingAddress';
  showAddress: boolean = false;
  showPaymentInformation: boolean = false;
  upsellsPaymentForm: FormGroup;
  upsells: OrderUpsell[] = [];
  processing: boolean = false;

  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected formService: FormService,
    protected sessionStorageService: SessionStorageService,
    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private errorHandlerService: ErrorHandlerService,
    private treatmentConsultationQuestionnaireService: TreatmentConsultationQuestionnaireService,
    private navigationService: NavigationService
  ) {
    super(config, formService, sessionStorageService);
  }

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.addFormControlValidators();
    this.deliverMedication.valueChanges.subscribe(() => this.handleDeliveryOptionChange());
  }

  /**
   * Gets the deliver medication control.
   */
  get deliverMedication(): AbstractControl {
    return this.upsellsForm.get(this.deliverMedicationControlName);
  }

  /**
   * Gets the shipping address.
   */
  get shippingAddress(): FormGroup {
    return this.formService.utiConsultationRequest.get(this.shippingAddressControlName) as FormGroup;
  }

  /**
   * Gets the hints.
   */
  get hints(): string[] {
    return [
      `This option requires an additional $${this.price} shipping and handling charge.`,
      'Overnight shipping is available Monday - Friday 12AM - 4PM CT. If your order was placed over the weekend, your medication will ship first thing Monday.',
    ];
  }

  /**
   * Asserts that the control is valid and navigates to the next page.
   */
  continue(): void {
    if (
      !this.isValidControl(this.deliverMedication) ||
      (this.showAddress && !this.isValidControl(this.shippingAddress))
    ) {
      return;
    }

    this.processing = true;
    this.orderService.storeUpsell(this.getUpsellPayload(), this.sessionStorageService.transactionId).subscribe({
      next: this.handleSuccessfulUpsellStore.bind(this),
      error: (error) => {
        this.processing = false;
        if (this.showPaymentInformation) {
          this.placeOrderComponent.submissionErrors = this.errorHandlerService.handleResponseError(error);

          return;
        }
        this.displayPaymentInformation();
      },
    });
  }

  /**
   * Handles the successful upsell store.
   */
  handleSuccessfulUpsellStore(response: UpsellStoreResponse): void {
    if (this.showAddress) {
      this.sessionStorageService.shippingAddress = this.shippingAddress.value;
    }

    if (response?.transaction_id) {
      this.sessionStorageService.upsell = [response.transaction_id];
    }

    this.navigationService.navigateToNextConsultationRequestPage(this.sessionStorageService.treatmentType);
  }

  /**
   * Gets the upsells total price.
   */
  upsellsTotalPrice(): number {
    return this.upsells.reduce((acc, upsell) => acc + upsell.upsell_price, 0);
  }

  /**
   * Sets the options for the medication delivery.
   */
  protected override setOptions(): void {
    this.options = [
      new SelectableOption('Yes, overnight my medicine', this.slug),
      new SelectableOption('No, I will pickup from my Pharmacy', 'No'),
    ];
  }

  /**
   * Asserts that the control is valid.
   *
   * @param {AbstractControl} control The control to validate.
   */
  private isValidControl(control: AbstractControl): boolean {
    if (control.valid) {
      return true;
    }

    control.markAllAsTouched();

    return false;
  }

  /**
   * Handles the delivery option change.
   *
   * If the delivery option is to ship to the patient's address, the address form control is added to the form.
   * Also, the address form control is set to the value of the address form control in the consultation request form.
   * If the delivery option is pickup from the pharmacy, the address form control is removed from the form.
   */
  private handleDeliveryOptionChange(): void {
    this.upsellsForm.updateValueAndValidity();
    this.populateUpsellsData();
    if (this.deliverMedication.value === this.slug) {
      this.formService.utiConsultationRequest.addControl(this.shippingAddressControlName, this.formService.address);
      this.shippingAddress.setValue(this.formService.utiConsultationRequest.get('address').value);
      this.showAddress = true;

      return;
    }

    this.showAddress = false;
    this.formService.utiConsultationRequest.removeControl(this.shippingAddressControlName);
  }

  /**
   * Adds form control validators.
   */
  private addFormControlValidators(): void {
    this.deliverMedication?.addValidators(Validators.required);
    this.deliverMedication?.updateValueAndValidity();
  }

  /**
   * Displays the payment information.
   */
  private displayPaymentInformation(): void {
    this.initPaymentForm();
    this.showPaymentInformation = true;
  }

  /**
   * Initializes the additional recommended testing form.
   */
  private initPaymentForm(): void {
    this.upsellsPaymentForm = this.formBuilder.group({
      tests: new FormGroup({}),
      payment: this.formService.getNewPaymentForm(),
    });

    this.upsells.forEach((upsell) => {
      (this.upsellsPaymentForm.controls.tests as FormGroup).addControl(upsell.name, new FormControl(true));
    });
  }

  /**
   * Populates the upsells data.
   */
  private populateUpsellsData(): void {
    const upsells = this.sessionStorageService.upsells;

    if (!this.deliverMedication.value) {
      return;
    }

    if (this.deliverMedication.value === QuestionnaireAnswers.No) {
      this.sessionStorageService.upsells = upsells.filter((upsell) => upsell !== this.slug);
    } else if (!upsells.includes(this.slug)) {
      this.sessionStorageService.upsells = [...upsells, this.slug];
    }

    this.upsells = this.treatmentConsultationQuestionnaireService.getUpsellsWithPrice();
  }

  /**
   * Generates the upsell payload.
   * We filter the ordered upsells from the upsells list and create the payload. With only the ones that were not
   * previously ordered.
   */
  private getUpsellPayload(): UpsellStoreRequest {
    const orderedUpsells = this.sessionStorageService.orderedUpsells;
    const filterOrderedUpsells = this.upsells.filter((upsell) => !orderedUpsells.includes(upsell.name));

    if (!this.showPaymentInformation) {
      return new UpsellStoreRequest(filterOrderedUpsells, PaymentTypes.Recharge);
    }

    return new UpsellStoreRequest(
      filterOrderedUpsells,
      undefined,
      this.upsellsPaymentForm.get('payment').value,
      this.sessionStorageService.patient.name
    );
  }
}
