// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.ng-invalid.ng-touched, input.expired-card,
textarea.ng-invalid.ng-touched,
textarea.expired-card,
select.ng-invalid.ng-touched,
select.expired-card {
  background-color: #fceeee;
  outline: 1px solid #de4444;
}

.error {
  color: #de4444;
  font-weight: bold;
  font-size: 0.75rem;
  margin-top: 0.5em;
}
.error a {
  color: #de4444;
  text-decoration: underline;
}

.label {
  font-weight: normal;
}

.pointer {
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.cvv-info:hover + .cvv-info-container {
  display: block;
}

.cvv-info-container {
  background: #fff;
  display: none;
  width: 182px;
  height: 151px;
  padding: 10px;
  border-radius: 5px;
  z-index: 2;
}

.cvv-info-container-arrow {
  position: absolute;
  top: -4px;
  background: #adb5c8;
  left: 3.5rem;
}

.cvv-info-container.cvv-info-container-arrow:before {
  content: " ";
  position: absolute;
  background: #adb5c8;
  width: 30px;
  height: 30px;
  top: 25px;
  left: -15px;
  transform: rotate(45deg);
  z-index: -1;
}

@media only screen and (max-width: 1150px) {
  .cvv-info-container {
    left: unset;
    right: 15%;
  }
  .cvv-info-container.cvv-info-container-arrow:before {
    top: 26px;
    left: 160px;
  }
}
@media only screen and (max-width: 768px) {
  .cvv-info {
    margin-left: -1rem;
  }
  .cvv-info-container-arrow {
    position: absolute;
    top: 4rem;
    background: #adb5c8;
    left: -9rem;
  }
  .cvv-info-container {
    right: 15%;
  }
  .cvv-info-container.cvv-info-container-arrow:before {
    top: -0.5rem;
    left: 8.2rem;
  }
}
.gift-card-balance-button {
  background-color: #3e5971;
  border-radius: 9px;
  border: none;
}

.apply-gift-card-button {
  border-radius: 9px;
  border: none;
}

.gift-card-balance {
  letter-spacing: 1.7px;
}

.orange-background {
  background-color: #e09339;
  color: #fff;
}

.grey-background {
  background-color: #d3d3d3;
  color: #5f5f5f;
}

.different-gift-card-button {
  padding: 0;
  margin: 12px 0 0 18px;
  font-size: 14px;
  height: 24px;
}
.different-gift-card-button:focus {
  outline: none;
  box-shadow: none;
}

.help {
  color: #355a73 !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
