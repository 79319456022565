/**
 * Environment variables for use in all sites, both in local environment
 * and in production environment.
 */
export const commonEnvironment = {
  phone: '1-800-456-2323',
  email: null,
  inHomeCollectionCostInCents: 11900,
  inHomeCollectionProviderIds: [1],
  enableGiftCard: true,
  doxyPepUpsell: {
    id: 2640,
    name: 'DoxyPEP Treatment',
    slug: 'doxy-pep',
    price: 4900,
    upsell_price: 4900,
    recommended: false,
    description: '',
  },
  partialResultsDelivery: {
    id: 2657,
    name: 'Partial Results Delivery',
    slug: 'partial-results-delivery',
    price: 2900,
    upsell_price: 2900,
    recommended: false,
    description: '',
  },
  mycoplasmaGenitaliumUpsell: {
    id: 1133,
    name: 'Mycoplasma genitalium, NAA, Urine',
    slug: 'mycoplasma-genitalium-naa-urine',
    price: 7900,
    upsell_price: 7900,
    recommended: false,
    description: '',
  },
  metronidazole: {
    id: 2794,
    name: 'Metronidazole 500mg (Flagyl)',
    slug: 'flagyl',
    price: 7000,
    upsell_price: 0,
  },
  clindamycin: {
    id: 2792,
    name: 'Clindamycin 300mg (Cleocin)',
    slug: 'clindamycin-300mg-cleocin',
    price: 7000,
    upsell_price: 0,
  },
  deliveryPharmacyId: 1,
  externalConsultationTestId: 2689,
  minimumNameLength: 2,
  noPeriodLastYearQuestionId: '52',
};
