import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '@services/storage.service';
import { DOCUMENT } from '@angular/common';
import { DomainService } from '@services/domain.service';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';

@Injectable({
  providedIn: 'root',
})
export class OrderGuard {
  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private storageService: StorageService,
    private domainService: DomainService
  ) {}

  /**
   * Determines if the order page should be loaded.
   */
  canActivate(): boolean {
    if (this.domainService.isTreatMyUtiDomain()) {
      return this.canActivateOrderForTMU();
    }

    if (this.domainService.isSTDCheckDomain() && !this.canActivateOrderForSTDCheck()) {
      return false;
    }

    if (this.storageService.transactionId && !this.hasReorder()) {
      this.router.navigateByUrl('/order-address.php');

      return false;
    }

    return true;
  }

  /**
   * Determines if tests are included in the URL.
   */
  hasTests(): boolean {
    const url = new URL(this.document.location.href);

    return (
      !!url.searchParams.get('panel') ||
      !!url.searchParams.get('tests') ||
      !!url.searchParams.get('tests[]') ||
      !!url.searchParams.get('panel[]') ||
      !!this.storageService.tests.length
    );
  }

  /**
   * Determines if a reorder is included in the URL.
   */
  hasReorder(): boolean {
    const url = new URL(this.document.location.href);

    return !!url.searchParams.get('reorderId') && !!this.storageService.getCookie('account_token');
  }

  /**
   * Checks if the order page should be loaded for TreatMyUTI.com.
   *
   * @returns {boolean} true if the order page should be loaded
   */
  private canActivateOrderForTMU(): boolean {
    if (!this.storageService.tests?.length) {
      this.document.location.href = this.config.siteUrls.tests;

      return false;
    }

    return true;
  }

  /**
   * Checks if the order page should be loaded for STDCheck.com.
   *
   * @returns {boolean} true if the order page should be loaded
   */
  private canActivateOrderForSTDCheck(): boolean {
    const hasTests = this.hasTests() || this.hasReorder();

    if (!hasTests) {
      this.document.location.href = this.config.siteUrls.tests;

      return false;
    }

    return true;
  }
}
