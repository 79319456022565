import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FormHelper } from '@common/form-helper';
import { DisqualifiedFieldEvent } from '@models/disqualified-field-event';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-checkbox-array-field',
  templateUrl: './checkbox-array-field.component.html',
  styleUrls: ['./checkbox-array-field.component.scss'],
})
export class CheckboxArrayFieldComponent extends FormHelper implements OnInit, OnDestroy {
  @Input() controlName: string;
  @Input() warningMessage: string;
  @Input() label: string = '';
  @Input() options: string[] = [];
  @Input() private warningInducingOptions: string[] = [];
  @Input() private form: FormGroup;
  @Input() private validatorsWhenVisible: ValidatorFn | ValidatorFn[];
  @Output() disqualifiedEvent: EventEmitter<DisqualifiedFieldEvent> = new EventEmitter<DisqualifiedFieldEvent>();

  private valueChangesSubscription: Subscription;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.valueChangesSubscription = this.formArray?.valueChanges.subscribe(() => {
      this.disqualifiedEvent.emit({
        controlName: this.controlName,
        isDisqualified: this.selectedWarningInducingOption,
      });
    });

    this.initValidatorsWhenVisible();
  }

  ngOnDestroy(): void {
    this.formArray.clear();
    this.valueChangesSubscription?.unsubscribe();
    this.valueChangesSubscription = undefined;
    this.removeValidatorsWhenHidden();
  }

  /**
   * Gets the form array from the form group.
   *
   * @returns {FormArray} the form array
   */
  get formArray(): FormArray {
    return this.form?.get(this.controlName) as FormArray;
  }

  /**
   * Gets whether any of the warning inducing options are selected.
   *
   * @returns {boolean} true if any of the warning inducing options are selected; otherwise, false
   */
  get selectedWarningInducingOption(): boolean {
    return this.warningInducingOptions.some((option: string) => this.formArray?.value.includes(option));
  }

  /**
   * Updates the form array with the selected value.
   *
   * @param {any} event the chekbox input change event
   */
  onCheckChange(event: any): void {
    this.formArray.markAllAsTouched();
    if (event.target.checked) {
      this.formArray.push(new FormControl(event.target.value));

      return;
    }

    this.formArray.controls.forEach((control: FormControl, index: number) => {
      if (control.value == event.target.value) {
        this.formArray.removeAt(index);
      }
    });
  }

  /**
   * Checks if the form control is invalid.
   *
   * @returns {boolean} true if the form control is invalid; otherwise, false
   */
  isFormControlInvalid(): boolean {
    return this.isInvalid(this.formArray);
  }

  /**
   * Adds the validators when the control is visible, without affecting other validators.
   */
  private initValidatorsWhenVisible(): void {
    this.formArray?.addValidators(this.validatorsWhenVisible || []);
    this.formArray?.updateValueAndValidity();
  }

  /**
   * Removes the visible only validators, without affecting other validators.
   */
  private removeValidatorsWhenHidden(): void {
    this.formArray?.removeValidators(this.validatorsWhenVisible || []);
    this.formArray?.updateValueAndValidity();
  }
}
