import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Patient } from '@models/patient';
import { PhoneService } from '@services/phone.service';
import { normalizeDiacritics } from 'normalize-text';

@Injectable({
  providedIn: 'root',
})
export class ScheduleOnceService {
  constructor(private phoneService: PhoneService, private router: Router) {}

  /**
   * Builds the schedule once URL with the necessary query params.
   *
   * @param {string} consultationId the consultation request id
   * @param {string} scheduleOnceId the schedule once id
   * @param {string} orderId the order id
   * @param {string} hash the order hash
   * @param {Patient} patientInfo the patient info
   *
   * @returns {string} the URL with the query params
   */
  getScheduleOnceUrl(
    consultationId: string,
    scheduleOnceId: string,
    orderId: string,
    hash: string,
    patientInfo: Patient
  ): string {
    const baseUrl = '/consultation/schedule-consultation';
    const queryParams: Params = this.buildScheduleConsultationQueryParams(
      consultationId,
      scheduleOnceId,
      orderId,
      hash,
      patientInfo
    );
    const urlTree = this.router.createUrlTree([baseUrl], { queryParams });

    return this.router.serializeUrl(urlTree);
  }

  /**
   * Builds an object with the necessary query params to navigate to schedule-consultation after ordering/updating a consultation
   * request.
   *
   * @param {string} consultationId the consultation request id
   * @param {string} scheduleOnceId the schedule once id
   * @param {string} orderId the order id
   * @param {string} hash the order hash
   * @param {Patient} patientInfo the patient info
   *
   * @returns {Params} the Params object
   */
  private buildScheduleConsultationQueryParams(
    consultationId: string,
    scheduleOnceId: string,
    orderId: string,
    hash: string,
    patientInfo: Patient
  ): Params {
    return {
      order_id: orderId,
      hash: hash,
      consultationId: consultationId,
      scheduleOnceId: scheduleOnceId,
      consultation: `https://www.analytecare.com/manage/consultationRequest/${consultationId}/edit`,
      first: patientInfo.name,
      last: patientInfo.last_name,
      name: this.getPatientFirstAndLastName(patientInfo),
      email: patientInfo.email,
      contact: this.phoneService.formatPhoneNumber(patientInfo.phone),
      mobile: `1${patientInfo.phone}`,
      skip: '1',
    };
  }

  /**
   * Gets the patient's first and last name, excluding any middle names included in the first name field.
   *
   * @param {Patient} patientInfo the patient info from localStorage
   */
  private getPatientFirstAndLastName(patientInfo: Patient): string {
    return `${patientInfo.name.split(' ')[0]} ${patientInfo.last_name}`;
  }
}
