export enum UpsellSlugs {
  Pyridium = 'Pyridium',
  FollowUpUrineTest = 'Follow-Up Urine Test',
  Fluconazole = 'Fluconazole (Yeast Prevention)',
  Probiotics = 'Probiotics',
  DoctorsNote = "Doctor's Note",
  Levonorgestrel = 'Levonorgestrel (Plan B)',
  DeliverMedication = 'Delivery',
  OndansetronPill = 'Zofran (Ondansetron) Pill',
  OndansetronDissolvableTablet = 'Zofran (Ondansetron) Dissolvable Tablet',
  MensIntimateWash = 'Mens Intimate Wash',
  MetronidazoleVaginalGel = 'Upsell - Metronidazole Vaginal Gel .75% (Metrogel)',
  ClindamycinVaginalCream = 'Upsell - Clindamycin Vaginal Cream 2% (Cleocin)',
  DoctorDecideWhichBVCream = 'Upsell - Vaginal Cream let doctor decide',
  Metronidazole = 'Metronidazole 500mg (Flagyl)',
  ClotrimazoleVaginalCream = 'Clotrimazole Vaginal Cream',
}
