import { Gender } from '@enums/gender';
import { ConsultationRequestHealthData } from './consultation-request-health-data';
import { ConsultationRequestHelper } from './consultation-request-helper';

export class ConsultationRequestPartner extends ConsultationRequestHealthData {
  name: { first: string; last: string };
  height: { feet: number; inches: number };
  weight: Number;

  constructor(partnerFormValues: any) {
    super(
      partnerFormValues.allergies,
      partnerFormValues.birthday,
      partnerFormValues.gender,
      partnerFormValues.gender === Gender.Female ? partnerFormValues.pregnant : '',
      partnerFormValues.prescriptions,
      ConsultationRequestHelper.getSymptomsArrayFromValues(partnerFormValues.symptoms)
    );

    this.name = {
      first: partnerFormValues.firstName,
      last: partnerFormValues.lastName,
    };
    this.height = {
      feet: partnerFormValues.height_feet,
      inches: partnerFormValues.height_inches,
    };
    this.weight = partnerFormValues.weight;
  }
}
