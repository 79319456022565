import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

export function scrollToFirstInvalidControl(): void {
  const firstInvalidControl: HTMLElement = document.querySelector('.ng-invalid:not(form)');

  if (!firstInvalidControl) {
    return;
  }

  const labelOffset = 50;
  const offset = firstInvalidControl.getBoundingClientRect().top + window.scrollY - labelOffset;

  window.scroll({
    top: offset,
    left: 0,
    behavior: 'smooth',
  });

  fromEvent(window, 'scroll')
    .pipe(debounceTime(100), take(1))
    .subscribe(() => firstInvalidControl.focus());
}
