import { Component, Inject, OnInit } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-treatment-consultation-request-completed',
  templateUrl: './treatment-consultation-request-completed.component.html',
  styleUrls: ['./treatment-consultation-request-completed.component.scss'],
})
export class TreatmentConsultationRequestCompletedComponent implements OnInit {
  readonly button = {
    text: 'Back to Home',
    href: this.config.siteUrls.home,
  };
  patientName: string;
  transactionId: string;

  constructor(@Inject(APP_CONFIG) private config: AppConfig, private sessionStorageService: SessionStorageService) {}

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.patientName = this.sessionStorageService.patient?.name ?? '';
    this.transactionId = this.sessionStorageService.transactionId;
    this.sessionStorageService.removeConsultationRequestCompletedData();
  }
}
