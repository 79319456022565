import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { SessionStorageService } from '@services/session-storage.service';
import * as Sentry from '@sentry/angular';
import { NgxImageCompressService } from 'ngx-image-compress';
import { FileService } from '@services/file.service';
import { Extras } from '@sentry/types/types/extra';

@Component({
  selector: 'app-file-attach',
  templateUrl: './file-attach.component.html',
  styleUrls: ['./file-attach.component.scss'],
})
export class FileAttachComponent implements OnInit {
  @Input() filesControl: FormArray;
  @Input() allowedExtensions: { [fileExtension: string]: string } = {};
  @Input() maxMbFileSize: number;
  @Input() maxFiles: number;
  @Input() label: string;
  @Input() isLoading: boolean = false;
  // Tells the parent component that the loading spinner should be shown
  @Output() toggleIsLoadingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  errorMessage: string;
  originalFiles: File[] = [];
  private maxSizeInBytes: number;
  private allowedExtensionsLabel: string;

  constructor(
    private formBuilder: FormBuilder,
    private sessionStorageService: SessionStorageService,
    private imageCompressService: NgxImageCompressService,
    private fileService: FileService
  ) {}

  /**
   * Initialize this component.
   */
  ngOnInit(): void {
    this.maxSizeInBytes = this.maxMbFileSize * 1024 * 1024;
    this.allowedExtensionsLabel = Object.values(this.allowedExtensions).join(', ');
  }

  /**
   * Detects the files uploaded.
   *
   * @param {FileList} fileList The list of files uploaded.
   */
  detectFiles(fileList: FileList): void {
    this.resetFileAttach();

    if (this.isFileLimitValid(fileList)) {
      this.toggleIsLoadingEvent.emit(true);
      this.setFiles(fileList);
      this.toggleIsLoadingEvent.emit(false);
    }
  }

  /**
   * Removes a file from the list.
   *
   * @param {number} index The index of the file to remove.
   */
  removeFile(index: number): void {
    this.filesControl.removeAt(index);
    this.originalFiles.splice(index, 1);
  }

  /**
   * Resets the file attach component.
   */
  private resetFileAttach(): void {
    this.errorMessage = '';
    this.filesControl.clear();
    this.originalFiles = [];
  }

  /**
   * Set the files into the form.
   *
   * @param {FileList} fileList The list of files to set.
   */
  private setFiles(fileList: FileList): void {
    Array.from(fileList).forEach((file: File) => {
      if (!this.isFileValid(file)) {
        return;
      }

      this.originalFiles.push(file);

      if (this.fileService.isConvertibleToImage(file)) {
        return this.fileService
          .convertToCompressedImage(file)
          .then((compressedFile) => this.pushFileToForm(compressedFile))
          .catch(() => {
            this.captureFileErrorSentryEvent("Couldn't convert and compress file to image", file);
            this.pushFileToForm(file);
          });
      }

      if (this.fileService.isCompressibleImage(file)) {
        return this.fileService
          .compressImage(file)
          .then((compressedFile) => this.pushFileToForm(compressedFile))
          .catch(() => {
            this.captureFileErrorSentryEvent("Couldn't compress image file", file);
            this.pushFileToForm(file);
          });
      }

      return this.pushFileToForm(file);
    });
  }

  /**
   * Checks if the file limit is valid.
   *
   * @param {FileList} fileList The list of files to check.
   */
  private isFileLimitValid(fileList: FileList): boolean {
    if (fileList.length > this.maxFiles) {
      this.errorMessage = `You can only upload ${this.maxFiles} ${this.maxFiles > 1 ? 'files' : 'file'}`;
      this.captureSentryEvent(this.errorMessage, { orderId: this.sessionStorageService.transactionId });

      return false;
    }

    return true;
  }

  /**
   * Checks if the file is valid.
   *
   * @param {File} file The file to check.
   */
  private isFileValid(file: File): boolean {
    return this.isFileSizeValid(file) && this.isFileTypeValid(file);
  }

  /**
   * Checks if the file size is valid.
   *
   * @param {File} file The file to check.
   */
  private isFileSizeValid(file: File): boolean {
    if (file.size > this.maxSizeInBytes) {
      this.errorMessage = `File size exceeds ${this.maxMbFileSize}MB`;
      this.captureFileErrorSentryEvent(this.errorMessage, file);

      return false;
    }

    return true;
  }

  /**
   * Checks if the file type is valid.
   *
   * @param {File} file The file to check.
   */
  private isFileTypeValid(file: File): boolean {
    if (!this.allowedExtensions.hasOwnProperty(file.type)) {
      this.errorMessage = `File type not allowed. Allowed types: ${this.allowedExtensionsLabel}`;
      this.captureFileErrorSentryEvent(this.errorMessage, file);

      return false;
    }

    return true;
  }

  /**
   * Pushes the file to the form.
   *
   * @param {File} file The file to be pushed.
   */
  private pushFileToForm(file: File): void {
    this.filesControl.push(this.formBuilder.group({ file: file }));
  }

  /**
   * Dispatch a Sentry capture event when an error is detected.
   *
   * @param {string} errorMessage The error message.
   * @param {File}   file         The file to grab context info from.
   */
  private captureFileErrorSentryEvent(errorMessage: string, file: File): void {
    const context_info = {
      file: file.name,
      size: file.size,
      type: file.type,
    };

    this.captureSentryEvent(errorMessage, {
      orderId: this.sessionStorageService.transactionId,
      ...context_info,
    });
  }

  /**
   * Dispatch a Sentry capture event.
   *
   * @param {string} errorMessage The error message to be captured.
   * @param {Extras} extraInfo    Extra context to be captured.
   */
  private captureSentryEvent(errorMessage: string, extraInfo: Extras): void {
    Sentry.captureEvent({
      message: errorMessage,
      level: 'error',
      extra: extraInfo,
    });
  }
}
