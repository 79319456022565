import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Sites } from '@enums/sites';

@Injectable({
  providedIn: 'root',
})

/**
 * Service class that handles website domain information
 */
export class DomainService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Determines if we are in the domain of STDCheck.
   */
  isSTDCheckDomain(): boolean {
    return this.getDomain().includes('stdcheck');
  }

  /**
   * Determines if we are in the domain of Healthlabs.
   */
  isHealthlabsDomain(): boolean {
    return this.getDomain().includes('healthlabs');
  }

  /**
   * Determines if we are in the domain of TreatMyUTI.com
   *
   * @returns {boolean} true if we are in the domain of TreatMyUTI.com, false otherwise
   */
  isTreatMyUtiDomain(): boolean {
    return this.getDomain().includes('treatmyuti');
  }

  /**
   * Determines if we are in the domain of Starfish.
   *
   * @returns {boolean} true if we are in the domain of Starfish, false otherwise
   */
  isStarfishDomain(): boolean {
    return this.getDomain().includes('starfish');
  }

  /**
   * Gets the current site name.
   *
   * @returns {Sites} the site domain we are in
   */
  getSiteDomain(): Sites {
    if (this.isSTDCheckDomain()) {
      return Sites.Stdcheck;
    }

    if (this.isTreatMyUtiDomain()) {
      return Sites.Treatmyuti;
    }

    if (this.isStarfishDomain()) {
      return Sites.Starfish;
    }

    return Sites.Healthlabs;
  }

  /**
   * Checks the website document location and gets the host name for it.
   *
   * @returns string containing the hostname of the website
   */
  private getDomain(): string {
    return this.document.location.hostname;
  }
}
