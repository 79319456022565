import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateSelection } from '../models/date-selection';

export function ageLimitValidator(minAge: number, maxAge?: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const dateSelection = new DateSelection(
      Number(control.get('month').value),
      Number(control.get('day').value),
      Number(control.get('year').value)
    );

    return dateSelection.isAgeValid(minAge, maxAge) ? null : { invalidAge: true };
  };
}
