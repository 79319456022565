export class SelectableOption {
  label: string;
  value: string;
  disabled?: boolean;

  constructor(label: string, value?: string, disabled?: boolean) {
    this.label = label;
    this.value = value ?? label;
    this.disabled = disabled ?? false;
  }
}
