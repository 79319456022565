import { Injectable } from '@angular/core';
import { Renderer2, RendererFactory2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})

/**
 * This service class provides an angularized way to inject external JS files, loading them only when a given
 * component is initialized.
 */
export class ScriptService {
  renderer: Renderer2;

  constructor(@Inject(DOCUMENT) private document: Document, private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  /**
   * Load an external js script based on src provided
   *
   * @param { string } src external js file source
   */
  public loadScript(src: string): HTMLScriptElement {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    this.renderer.appendChild(this.document.body, script);

    return script;
  }

  /**
   * Load an external js script based on src provided with retries
   *
   * @param {string} src     external js file source
   * @param {number} retries number of retries in case of failure
   */
  public loadScriptWithRetries(src: string, retries: number = 3): Promise<HTMLScriptElement> {
    return new Promise((resolve, reject) => {
      const script = this.loadScript(src);

      script.onload = () => resolve(script);
      script.onerror = (err: Event) => {
        if (--retries < 1) {
          reject(err);

          return;
        }

        this.removeScript(script);
        setTimeout(() => resolve(this.loadScriptWithRetries(src, retries)), 3000);
      };
    });
  }

  /**
   * Remove an external js script based on script provided
   *
   * @param {HTMLScriptElement} script The script tag to be removed.
   */
  public removeScript(script: HTMLScriptElement): void {
    this.renderer.removeChild(this.document.body, script);
  }
}
