import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ApplePayService } from '@services/external-payments/apple-pay.service';
import { BraintreeService } from '@services/external-payments/braintree.service';
import { DomainService } from '@services/domain.service';
import { Observable, delay, of } from 'rxjs';
import { ErrorHandlerService } from '@services/error-handler.service';

@Injectable()
export class ApplePayMockService extends ApplePayService {
  constructor(
    protected braintreeService: BraintreeService,
    @Inject(DOCUMENT) protected document: Document,
    protected domainService: DomainService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(braintreeService, document, domainService, errorHandlerService);
  }

  /**
   * Mocks the Apple Pay is available.
   */
  override get isAvailable(): boolean {
    return true;
  }

  /**
   * Mocks the Apple Pay session.
   *
   * @param {number} amount the amount to charge the user
   *
   * @returns {Promise<string>} a promise that resolves with a fake nonce
   */
  override pay(amount: number): Promise<string> {
    if ((window as any)._forceApplePayError) {
      return Promise.reject(this.genericAppleErrorMessage);
    }

    return Promise.resolve('fake-apple-pay-visa-nonce');
  }

  /**
   * Mocks the Apple Pay button.
   *
   * @returns {Observable<void>} an Observable that emits a void value
   */
  override renderPaymentButton(): Observable<void> {
    return of(undefined).pipe(delay(3000));
  }
}
