import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TestVisibilities } from '@models/test-visibilities';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SitesService {
  constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient) {}

  /**
   * Retrieves test visibilities for a given site ID.
   *
   * @param {string} siteId the ID of the site for which test visibilities are requested
   *
   * @returns {Observable<TestVisibilities>} an observable that emits test visibilities
   */
  getTestVisibilities(siteId: string): Observable<TestVisibilities> {
    return this.http.get<TestVisibilities>(`${this.config.analyteCareApi}/api/v2/sites/${siteId}/test-visibilities`);
  }
}
