import { Treatment } from '@enums/treatment';
import { ConsultationRequestPartner } from './consultation-request-partner';
import { ConsultationRequestPharmacy } from './consultation-request-pharmacy';

export class ConsultationRequestAdditionalInfo {
  partner: ConsultationRequestPartner;
  pharmacy: ConsultationRequestPharmacy;
  treatment: Treatment;
  height: { feet: number; inches: number };
  weight: number;

  constructor(partnerFormValues: any, treatment: Treatment, personalFormValues: any) {
    this.treatment = treatment;
    if ([Treatment.Partner, Treatment.SelfAndPartner].includes(this.treatment)) {
      this.partner = new ConsultationRequestPartner(partnerFormValues);
    }
    this.height = {
      feet: personalFormValues.height_feet,
      inches: personalFormValues.height_inches,
    };
    this.weight = personalFormValues.weight;
  }
}
