import { Component, Input, ViewEncapsulation } from '@angular/core';
import { OrderSummaryRow } from '@common/order-summary-row';

@Component({
  selector: 'app-embedded-order-summary',
  templateUrl: './embedded-order-summary.component.html',
  styleUrls: ['./embedded-order-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmbeddedOrderSummaryComponent {
  @Input() itemRows: OrderSummaryRow[] = [];
  @Input() totalInCents: number = 0;

  constructor() {}
}
