import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardComponent {
  @Input() title: string;
  @Input() headerClasses: string | string[] | Set<string> | { [klass: string]: any } = '';
  @Input() contentClasses: string | string[] | Set<string> | { [klass: string]: any } = '';

  constructor() {}
}
