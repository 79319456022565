import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { Router } from '@angular/router';
import { SelectableOption } from '@models/selectable-option';
import { NavigationService } from '@services/navigation.service';
import { SessionStorageService } from '@services/session-storage.service';
import { FormService } from '@services/form.service';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';

@Component({
  selector: 'app-upsell-card',
  templateUrl: './upsell-card.component.html',
  styleUrls: ['./upsell-card.component.scss'],
})
export class UpsellCardComponent implements OnInit {
  form: FormGroup;
  readonly controlName: string = 'upsell';

  @Input() htmlTitle: string;
  @Input() options: SelectableOption[];
  @Input() question: string;
  @Input() treatmentType: ConsultationTreatmentTypes;
  @Input() upsellPrice: number;

  constructor(
    private navigationService: NavigationService,
    private formService: FormService,
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) {}

  /**
   * Initializes the component by adding the validators to the form control.
   */
  ngOnInit(): void {
    this.form = this.formService.getUpsellCardForm();
    this.markSelectedUpsell();
  }

  /**
   * Gets the form control with the name set in the controlName property.
   *
   * @returns {FormControl} the form control
   */
  private get control(): FormControl {
    return this.form?.get(this.controlName) as FormControl;
  }

  /**
   * Asserts that the control is valid and navigates to the next page.
   */
  continue(): void {
    if (this.control.invalid) {
      this.control.markAsTouched();

      return;
    }

    this.sessionStorageService.upsells = this.updateUpsells(this.getUpsells(), this.getUpsellCardValue());
    this.navigationService.navigateToNextConsultationRequestPage(this.treatmentType);
  }

  /**
   * Returns an immutable copy of the upsells array.
   */
  private getUpsells(): string[] {
    return [...this.sessionStorageService.upsells];
  }

  /**
   * Gets the value of the upsell card form control.
   */
  private getUpsellCardValue(): string {
    return this.form.get(this.controlName).value;
  }

  /**
   * Updates the upsells array based on the answer.
   * If the answer is 'No', the upsells are removed from the array.
   * If the answer is 'Yes', the upsells are added to the array.
   *
   * @param {string[]} upsells The upsells array.
   * @param {string}   answer  The answer to the question.
   */
  private updateUpsells(upsells: string[], answer: string): string[] {
    upsells = this.removeUpsellOptions(upsells);

    return answer === QuestionnaireAnswers.No ? upsells : this.addUpsellOption(upsells, answer);
  }

  /**
   * Removes the upsell options from the upsells array.
   *
   * @param {string[]} upsells The upsells array.
   */
  private removeUpsellOptions(upsells: string[]): string[] {
    return upsells.filter((upsell) => !this.options.map((option) => option.value).includes(upsell));
  }

  /**
   * Adds the answer to the upsells array if it is not already included.
   *
   * @param {string[]} upsells The upsells array.
   * @param {string}   answer  The answer to the question.
   */
  private addUpsellOption(upsells: string[], answer: string): string[] {
    return [...upsells, answer];
  }

  /**
   * Marks the selected upsell option in the form control.
   * Useful when the user navigates back to the page.
   */
  private markSelectedUpsell(): void {
    const upsells = this.sessionStorageService.upsells;
    const selectedOption = this.options.find((option) => upsells.includes(option.value));

    if (selectedOption) {
      this.form.get(this.controlName).setValue(selectedOption.value);
    }
  }
}
