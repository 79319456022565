import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from '@services/form.service';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';

@Component({
  selector: 'app-consultation-request-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TermsOfServiceComponent implements OnInit {
  showTerms: boolean = false;
  wmgTermsContent: string = 'Loading...';
  consultationForm: FormGroup;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private formService: FormService,
    private http: HttpClient
  ) {}

  /**
   * Set the consultation form.
   */
  ngOnInit(): void {
    this.consultationForm = this.formService.consultationRequest;
    this.getWmgTermsOfService();
  }

  /**
   * Show/hide terms.
   */
  toggleTerms(): void {
    this.showTerms = !this.showTerms;
  }

  /**
   * Get Wilcrest Medical Group's terms and conditions.
   */
  private getWmgTermsOfService(): void {
    this.http.get(`${this.config.stdcheckPage}/tos/wilcrest-content`, { responseType: 'text' }).subscribe({
      next: (response) => (this.wmgTermsContent = response),
      error: () => (this.wmgTermsContent = undefined),
    });
  }
}
