import { Inject, Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PaymentTypes } from '@enums/payment-types';
import { PaymentMethodOption } from '@models/payments/payment-method-option';
import { Recharge } from '@models/recharge';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ApplePayService } from '@services/external-payments/apple-pay.service';
import { BitPayService } from '@services/external-payments/bitpay.service';
import { ExternalPaymentMethodService } from '@services/external-payments/external-payment-method.service';
import { GooglePayService } from '@services/external-payments/google-pay.service';
import { PaypalService } from '@services/external-payments/paypal.service';
import { VenmoService } from '@services/external-payments/venmo.service';

@Injectable({
  providedIn: 'root',
})
export class PayService {
  constructor(
    private applePayService: ApplePayService,
    private bitPayService: BitPayService,
    @Inject(APP_CONFIG) private config: AppConfig,
    private googlePayService: GooglePayService,
    private paypalService: PaypalService,
    private venmoService: VenmoService
  ) {}

  /**
   * Retrieves the available payment method options, marking any that are disabled as not visible.
   *
   * @param {PaymentTypes[]} disabledPaymentMethods array of payment method types that should be marked as not visible
   * @param {Recharge} recharge recharge details used for the recharge payment method
   *
   * @returns {PaymentMethodOption[]} an array of payment method options
   */
  getPaymentMethodOptions(disabledPaymentMethods: PaymentTypes[] = [], recharge?: Recharge): PaymentMethodOption[] {
    return [
      {
        value: PaymentTypes.CreditCard,
        label: 'Credit Card',
        visible: true,
      },
      {
        value: PaymentTypes.ApplePay,
        label: 'Apple Pay',
        visible: this.isPaymentMethodAvailable(PaymentTypes.ApplePay),
      },
      {
        value: PaymentTypes.BitPay,
        label: 'Bitcoin/Bitcoin Cash',
        visible: this.isPaymentMethodAvailable(PaymentTypes.BitPay),
      },
      {
        value: PaymentTypes.GooglePay,
        label: 'Google Pay',
        visible: this.isPaymentMethodAvailable(PaymentTypes.GooglePay),
      },
      {
        value: PaymentTypes.Paypal,
        label: 'PayPal',
        visible: true,
      },
      {
        value: PaymentTypes.Venmo,
        label: 'Venmo',
        visible: this.isPaymentMethodAvailable(PaymentTypes.Venmo),
      },
      {
        value: PaymentTypes.GiftCard,
        label: `${this.config.domain} Gift Card`,
        visible: true,
      },
      {
        value: PaymentTypes.Recharge,
        label: `Recharge: ${recharge?.detail?.display_name ?? ''}`,
        visible: !!recharge,
      },
      {
        value: PaymentTypes.Free,
        label: 'Free',
        visible: true,
      },
    ].map((method) => ({
      ...method,
      visible: method.visible && !disabledPaymentMethods.includes(method.value),
    }));
  }

  /**
   * Initializes the payment methods.
   *
   * We need to render the Venmo button to initialize the Venmo instance here, because we need to have the instance to
   * check the browser support.
   */
  initializePaymentMethods(): void {
    this.venmoService.renderPaymentButton().subscribe();

    this.googlePayService.initialize().subscribe();

    if (this.config.enableBitPay) {
      this.bitPayService.initialize().subscribe();
    }
  }

  /**
   * Gets whether the payment method is available.
   *
   * @param {PaymentTypes} paymentType the payment method to check
   *
   * @returns {boolean} true if the payment method is available, false otherwise
   */
  isPaymentMethodAvailable(paymentType: PaymentTypes): boolean {
    return this.getExternalPaymentService(paymentType).isAvailable;
  }

  /**
   * Charges the user using the specified payment service.
   *
   * @param {number} amount the amount to charge
   * @param {PaymentTypes} paymentType the payment method to use
   *
   * @returns {Promise<string>} a promise that resolves with the nonce when the payment is authorized or rejects if the
   * payment fails
   */
  pay(amount: number, paymentType: PaymentTypes): Promise<string> {
    return this.getExternalPaymentService(paymentType).pay(amount);
  }

  /**
   * Sets the nonce value for the external payment method to the form control.
   *
   * @param {AbstractControl} paymentMethodControl the form control where the nonce will be set
   * @param {string} nonce the nonce value to set
   * @param {PaymentTypes} paymentType the payment method to use
   */
  setNonceToForm(paymentMethodControl: AbstractControl, nonce: string, paymentType: PaymentTypes): void {
    return this.getExternalPaymentService(paymentType).setNonceToForm(paymentMethodControl, nonce);
  }

  /**
   * Gets the external payment service to use based on the payment type.
   *
   * @param {PaymentTypes} paymentType the payment method to use
   *
   * @returns {ExternalPaymentMethodService} the external payment service to use
   */
  private getExternalPaymentService(paymentType: PaymentTypes): ExternalPaymentMethodService {
    switch (paymentType) {
      case PaymentTypes.ApplePay:
        return this.applePayService;
      case PaymentTypes.BitPay:
        return this.bitPayService;
      case PaymentTypes.GooglePay:
        return this.googlePayService;
      case PaymentTypes.Paypal:
        return this.paypalService;
      case PaymentTypes.Venmo:
        return this.venmoService;
      default:
        throw new Error('Invalid payment type');
    }
  }
}
