import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BitPayService } from '@services/external-payments/bitpay.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bitpay-button',
  templateUrl: './bitpay-button.component.html',
  styleUrls: ['./bitpay-button.component.scss'],
})
export class BitpayButtonComponent implements OnInit, OnDestroy {
  @Input() processing: boolean = false;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

  bitpayStatus: string | null = null;
  private subscriptions: Subscription[] = [];

  constructor(private bitpayService: BitPayService) {}

  /**
   * Gets whether the button should be displayed.
   */
  get displayButton(): boolean {
    return !this.status;
  }

  /**
   * Gets the status message to display on the button.
   */
  get status(): string | null {
    if (this.processing) {
      return 'Processing BitPay payment, please wait...';
    }

    return this.bitpayStatus;
  }

  ngOnInit(): void {
    this.subscriptions.push(this.listenForInvoiceCreation());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Emits the buttonClick event.
   */
  onButtonClicked(event: PointerEvent): void {
    event.preventDefault();
    this.buttonClick.emit();
  }

  /**
   * Listens for the creation of a BitPay invoice to update the loading message.
   *
   * @returns {Subscription} a subscription object for the event
   */
  private listenForInvoiceCreation(): Subscription {
    return this.bitpayService.bitpayStatus$.subscribe((bitpayStatus) => (this.bitpayStatus = bitpayStatus));
  }
}
