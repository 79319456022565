import { Symptom } from '@enums/symptoms';
import { DateData } from '@models/date-data';

export class ConsultationRequestHelper {
  /**
   * Get a string of symptoms from the symptoms form group.
   *
   * @param symptoms symptoms form group
   * @returns a string with the readable name of each symptom
   */
  static getSymptomsArrayFromValues(symptoms: Object): string[] {
    return Object.keys(symptoms)
      .map((symptom) => ({
        name: Object.keys(Symptom).find((key) => Symptom[key] === symptom),
        selected: symptoms[symptom],
      }))
      .filter((symptom) => symptom.selected)
      .map((symptom) => symptom.name);
  }

  /**
   * Get a string with this format '1995-06-04' from a date object like the following:
   * { day: '6', month: '4', year: '1995' }
   *
   * @param date object containing the day, month, and year as strings
   * @returns string with the date
   */
  static getDateDataString(date: DateData): string {
    if (!date?.day || !date?.month || !date?.year) {
      return null;
    }

    return `${date.year}-${('0' + date.month).slice(-2)}-${('0' + date.day).slice(-2)}`;
  }

  /**
   * Convert each number in an object to a string.
   *
   * @param object the original object
   * @returns the object with the numbers converted to strings
   */
  static convertObjectNumbersToStrings(object: Object): Object {
    Object.keys(object).forEach((key) => {
      if (object[key] !== null && !isNaN(+object[key])) {
        object[key] = (+object[key] as number).toString();
      }
    });

    return object;
  }
}
