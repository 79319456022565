import { Injectable } from '@angular/core';
import { StdPreventionConsultationRequest } from '@models/consultation-request/std-prevention/std-prevention-consultation-request';
import { FormControl, FormGroup } from '@angular/forms';
import { FormService } from '@services/form.service';
import { ConsultationQuestionAnswer } from '@models/consultation-request/consultation-question-answer';
import { ConsultationQuestionnaireService } from '@services/consultation-questionnaire.service';
import { ConsultationRequestRequest } from '@models/consultation-request/consultation-request-request';

@Injectable({
  providedIn: 'root',
})
export class ConsultationStdPreventiveQuestionnaireService extends ConsultationQuestionnaireService {
  doxyPepConsultationForm: FormGroup;
  additionalInfoForm: FormGroup;
  questionnaireForm: FormGroup;

  protected questions = {
    hasUnprotectedSex: 'Have you recently had oral, anal, or vaginal intercourse without a condom?',
    unprotectedSexDaysAgo: 'When did you last have unprotected sex?',
    hasSymptoms:
      'Are you currently having symptoms such as urethral, vaginal, or anal discharge, painful urination, ulcers, or other symptoms?',
    sexualQuestions: 'Is there anything about your sexual health that you have questions about?',
    hasHypersensitivity: 'Do you have a hypersensitivity to Doxycycline or other Tetracyclines?',
    alreadyOnMedication: 'Are you currently taking isotretinoin, barbiturates, carbamazepine, phenytoin, or warfarin?',
    hasMyastheniaGravis: 'Have you been diagnosed with Myasthenia Gravis?',
    hasOesophagitis: 'Do you have a history of oesophagitis (inflamed esophagus)?',
  };

  private femaleOnlyQuestions = {
    pregnant: 'Are you currently pregnant?',
    breastfeeding: 'Are you currently breastfeeding?',
  };

  constructor(public formService: FormService) {
    super();
    this.doxyPepConsultationForm = this.formService.doxyPepConsultationRequest;
    this.additionalInfoForm = this.doxyPepConsultationForm.get('additional_info') as FormGroup;
    this.questionnaireForm = this.additionalInfoForm.get('questionnaire') as FormGroup;
  }

  /**
   * Get the question label
   *
   * @param {string} questionKey the question key to get the label for
   */
  getQuestionLabel(questionKey: string): string {
    return super.getQuestionLabel(questionKey) || this.femaleOnlyQuestions[questionKey];
  }

  /**
   * Get the answer for the question, mapping to the details question if necessary
   *
   * @param {string} questionKey the question key to get the answer for
   */
  getAnswer(questionKey: string): string {
    return this.questionnaireForm.value[questionKey] || 'NO';
  }

  /**
   * Returns the StdPreventionConsultationRequest model that represents the backend payload
   *
   * @param {boolean} isFemale true if the patient is female
   */
  getConsultationRequestPayload(isFemale: boolean): ConsultationRequestRequest {
    let stdPreventionConsultationRequest = super.consultationRequestPayload(new StdPreventionConsultationRequest());
    stdPreventionConsultationRequest.pharmacy_id = this.doxyPepConsultationForm.value.pharmacy.id ?? null;
    stdPreventionConsultationRequest.consultation_status = this.doxyPepConsultationForm.value.consultation_status;
    stdPreventionConsultationRequest.additional_info = this.getAdditionalInfo(isFemale);

    return stdPreventionConsultationRequest;
  }

  /**
   * Parse the form group questionnaire to a ConsultationQuestionAnswer array,
   * the sort is based on the order of the questions
   *
   * @param {boolean} isFemale true if the patient is female
   */
  parseFormGroupToAdditionalInfoQuestionnaire(isFemale: boolean): ConsultationQuestionAnswer[] {
    let questions = isFemale ? { ...this.questions, ...this.femaleOnlyQuestions } : this.questions;
    let questionnaire: ConsultationQuestionAnswer[] = [];

    Object.keys(questions).forEach((question) => {
      questionnaire.push({
        question: this.questions[question],
        answer: this.getAnswer(question),
      });
    });

    return questionnaire;
  }

  /**
   * Get the additional info for the consultation
   *
   * @param {boolean} isFemale true if the patient is female
   *
   * @returns {string} the additional info array JSON encoded
   */
  private getAdditionalInfo(isFemale: boolean): string {
    let additional_info = this.additionalInfoForm.value;
    additional_info.treatment = this.doxyPepConsultationForm.value.prescription.treatment;
    additional_info.height = {
      feet: additional_info.questionnaire.height_feet,
      inches: additional_info.questionnaire.height_inches,
    };
    additional_info.weight = additional_info.questionnaire.weight;
    additional_info.questionnaire = this.parseFormGroupToAdditionalInfoQuestionnaire(isFemale);

    // removes the custom pharmacy info if it's already set in choose a pharmacy
    if (this.doxyPepConsultationForm.value.pharmacy.id) {
      delete additional_info.pharmacy;
    }

    return JSON.stringify(additional_info);
  }
}
