// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-treatmyuti-layout app-radio-buttons-question-card .section,
app-starfish-layout app-radio-buttons-question-card .section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
app-treatmyuti-layout app-radio-buttons-question-card .container,
app-starfish-layout app-radio-buttons-question-card .container {
  max-width: 50rem !important;
}
app-treatmyuti-layout app-radio-buttons-question-card .card,
app-starfish-layout app-radio-buttons-question-card .card {
  padding: 2rem 1rem;
  border: none;
  box-shadow: none;
}
app-treatmyuti-layout app-radio-buttons-question-card .card .card-inner-container,
app-starfish-layout app-radio-buttons-question-card .card .card-inner-container {
  display: block;
  margin: 0 auto;
  max-width: 37rem;
}
app-treatmyuti-layout app-radio-buttons-question-card .card .card-inner-container .card-title,
app-starfish-layout app-radio-buttons-question-card .card .card-inner-container .card-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
}
app-treatmyuti-layout app-radio-buttons-question-card .button,
app-starfish-layout app-radio-buttons-question-card .button {
  min-width: 200px;
}

app-treatmyuti-layout app-radio-buttons-question-card .card .card-inner-container .card-title strong {
  color: #b46397;
}

app-starfish-layout app-radio-buttons-question-card .card {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}
app-starfish-layout app-radio-buttons-question-card .card .card-inner-container .card-title strong {
  color: #fa676b;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
