import { Injectable } from '@angular/core';
import { ConsultationRequestRequest } from '@models/consultation-request/consultation-request-request';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';

@Injectable({
  providedIn: 'root',
})

/**
 * The ConsultationQuestionnaireService abstract class
 *
 * This class is responsible for handling the consultation questionnaire service defining common methods and properties
 */
export abstract class ConsultationQuestionnaireService {
  /**
   * The questions object that contains the questions for the questionnaire
   *
   * Which each key is the form control name and the value is the question.
   */
  protected abstract questions: object;

  /**
   * Questions that appear on every consultation request form/questionnaire.
   *
   * The responses for these questions are stored on the ConsultationRequest model itself, not within additional_info.
   */
  protected externalQuestions = {
    medicationDetails: 'Are you currently on any medication?',
    allergiesDetails: 'Do you have any allergies that you are aware of?',
    otherConditionsDetails: 'Do you have any other conditions?',
  };

  /**
   * Get the answer for the question.
   *
   * @param {string} questionKey the question key to get the answer for
   */
  abstract getAnswer(questionKey: string): string;

  /**
   * Get the question label
   *
   * @param {string} questionKey the question key to get the label for
   */
  getQuestionLabel(questionKey: string): string {
    return this.questions[questionKey] || this.externalQuestions[questionKey];
  }

  /**
   * Returns the ConsultationRequestRequest common variables that represents the backend payload
   *
   * @param {ConsultationRequestRequest} consultationPayload The consultation request payload
   */
  consultationRequestPayload(consultationPayload: ConsultationRequestRequest): ConsultationRequestRequest {
    consultationPayload.prescriptions = this.getAnswer('medicationDetails') ?? QuestionnaireAnswers.No;
    consultationPayload.allergies = this.getAnswer('allergiesDetails') ?? QuestionnaireAnswers.No;
    consultationPayload.health_info = this.getAnswer('otherConditionsDetails') ?? QuestionnaireAnswers.No;

    return consultationPayload;
  }
}
