import { Component, OnInit, ViewChild } from '@angular/core';
import { LoadingService } from '@services/loading.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PaymentTypes } from '@enums/payment-types';
import { StorageService } from '@services/storage.service';
import { Upsell } from '@models/upsell';
import { UpsellStoreRequest } from '@models/requests/upsell-store.model';
import { FormService } from '@services/form.service';
import { Router } from '@angular/router';
import { PlaceOrderComponent } from '@components/place-order/place-order.component';
import { OrderService } from '@services/order.service';
import { UpsellStoreResponse } from '@models/upsell-store-response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from '@services/error-handler.service';
import { OrderUpsell } from '@models/order-upsell';
import { PartialResultsDeliveryService } from '@services/partial-results-delivery.service';
import { PartialResultsDeliveryResponse } from '@models/partial-results-delivery-response';
import { environment } from '@environments/environment.prod';

@Component({
  selector: 'app-partial-results-delivery',
  templateUrl: './partial-results-delivery.component.html',
  styleUrls: ['./partial-results-delivery.component.scss'],
})
export class PartialResultsDeliveryComponent implements OnInit {
  @ViewChild('placeOrder', { static: false }) placeOrderComponent: PlaceOrderComponent;

  partialResultsDeliveryContent: PartialResultsDeliveryResponse;
  partialResultsDeliveryForm: FormGroup;
  submissionErrors: string[] = [];
  processing: boolean = false;
  showPaymentInformation: boolean = false;
  rechargeAttempt: boolean = false;

  /** The PartialResultsDelivery test. */
  readonly partialResultsDeliveryTest: OrderUpsell = environment.partialResultsDelivery;

  /**
   * Gets the price of the FastResult test.
   */
  get partialResultsDeliveryPrice(): number {
    return Number(this.partialResultsDeliveryContent.price);
  }

  constructor(
    private loadingService: LoadingService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private router: Router,
    private orderService: OrderService,
    private errorHandlerService: ErrorHandlerService,
    private partialResultsDeliveryService: PartialResultsDeliveryService
  ) {}

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.getPartialResultsDeliveryContent();
  }

  /**
   * Redirects to the DoxyPEP treatment page.
   */
  redirectToDoxyPepTreatment(): void {
    this.router.navigateByUrl('doxy-pep-treatment');
  }

  /**
   * Gets the content for the PartialResultsDelivery test.
   */
  getPartialResultsDeliveryContent(): void {
    this.loadingService.toggleLoader(true);
    this.partialResultsDeliveryService.getPartialResultsDeliveryContent().subscribe({
      next: (response) => {
        this.partialResultsDeliveryContent = response;
        this.loadingService.toggleLoader(false);
      },
      error: (error) => {
        this.loadingService.toggleLoader(false);
        setTimeout(() => this.redirectToDoxyPepTreatment(), 0);
      },
    });
  }

  /**
   * Determine workflow to perform recharge or manual payment.
   */
  submit(): void {
    this.submissionErrors = [];
    this.processing = true;

    if (this.rechargeAttempt) {
      this.submitPayment();

      return;
    }

    this.rechargeAttempt = true;

    if (this.storageService.paymentType === PaymentTypes.PayLater) {
      this.displayPaymentInformation();
      this.processing = false;

      return;
    }

    this.submitRecharge();
  }

  /**
   * Submits the recharge.
   */
  private submitRecharge(): void {
    const partialResultsDeliveryUpsell = new Upsell(this.partialResultsDeliveryTest.name);
    this.orderService
      .storeUpsell(new UpsellStoreRequest(partialResultsDeliveryUpsell, PaymentTypes.Recharge))
      .subscribe({
        next: this.handleSuccessfulStoreUpsell.bind(this, this.partialResultsDeliveryTest),
        error: (error) => {
          this.displayPaymentInformation();
          this.handleRequestError(error, false);
        },
      });
  }

  /**
   * Displays the payment information.
   */
  private displayPaymentInformation(): void {
    this.initPaymentForm();
    this.showPaymentInformation = true;
  }

  /**
   * Initializes the additional recommended testing form.
   */
  private initPaymentForm(): void {
    this.partialResultsDeliveryForm = this.formBuilder.group({
      tests: new FormGroup({}),
      payment: this.formService.getNewPaymentForm(),
    });
    (this.partialResultsDeliveryForm.controls.tests as FormGroup).addControl(
      this.partialResultsDeliveryTest.slug,
      new FormControl(true)
    );
  }

  /**
   * Submits the payment.
   */
  private submitPayment(): void {
    this.placeOrderComponent.processing = true;
    this.orderService
      .storeUpsell(
        new UpsellStoreRequest(
          this.partialResultsDeliveryTest,
          undefined,
          this.partialResultsDeliveryForm.get('payment').value,
          this.storageService.patient.name
        )
      )
      .subscribe({
        next: this.handleSuccessfulStoreUpsell.bind(this, this.partialResultsDeliveryTest),
        error: (error) => {
          this.placeOrderComponent.processing = false;
          this.handleRequestError(error);
        },
      });
  }

  /**
   * Handler for the StoreUpsell request when it succeeds.
   *
   * @param { Upsell }              upsell              the upsell test that was successfully placed
   * @param { UpsellStoreResponse } upsellStoreResponse the successful response object
   */
  private handleSuccessfulStoreUpsell(upsell: Upsell, upsellStoreResponse: UpsellStoreResponse): void {
    if (this.partialResultsDeliveryForm) {
      this.storageService.paymentType = this.partialResultsDeliveryForm.get('payment').value.method;
    }

    this.orderService.saveUpsellTestInStorage(this.partialResultsDeliveryTest);

    if (upsellStoreResponse.transaction_id) {
      let upsellTransactionIds = this.storageService.upsell;
      upsellTransactionIds.push(upsellStoreResponse.transaction_id);
      this.storageService.upsell = upsellTransactionIds;
    }

    this.processing = false;
    this.storageService.datalayerPool = [upsell];
    this.redirectToDoxyPepTreatment();
  }

  /**
   * General purpose error handler for failing requests
   *
   * @param { HttpErrorResponse } error The response error object
   * @param { boolean } setSubmissionErrors Whether to set the submission errors or not
   */
  private handleRequestError(error: HttpErrorResponse, setSubmissionErrors: boolean = true): void {
    this.processing = false;
    this.submissionErrors = setSubmissionErrors ? this.errorHandlerService.handleResponseError(error) : [];
  }
}
