export class GiftCardPaymentRequest {
  charge_type: string = 'charge_gift_card';
  gift_card_code: string;
  charge_amount: number;
  order_id: string;

  constructor(giftCardCode: string, chargeAmount: number, orderId: string) {
    this.gift_card_code = giftCardCode;
    this.charge_amount = chargeAmount;
    this.order_id = orderId;
  }
}
