// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-site-layout {
  counter-reset: section;
}

.hide-gm-address-autocomplete .pac-container {
  display: none;
}

.container {
  max-width: 68.75rem !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
