export class Questionnaire {
  id: string;
  questionIds: string[];
  navigationUrls: string[];
  upsellIds: string[];

  constructor(options: { id: string; questionIds?: string[]; navigationUrls?: string[]; upsellIds?: string[] }) {
    this.id = options.id;
    this.questionIds = options.questionIds || [];
    this.navigationUrls = options.navigationUrls || [];
    this.upsellIds = options.upsellIds || [];
  }
}
