import { PaymentTypes } from '@enums/payment-types';

export class ConsultationRequestPayment {
  billing_country: string;
  billing_zip_code: string;
  ccNumber: string;
  cvv: string;
  discount: number;
  expirationMonth: string;
  expirationYear: string;
  paymentType: string;
  nonce: string;
  deviceData?: string;

  constructor(paymentFormValues: any, orderSubtotal: number, deviceData: string) {
    this.discount = paymentFormValues.discount * 100;
    this.parsePaymentType(paymentFormValues.payment, orderSubtotal);
    if (deviceData) {
      this.deviceData = deviceData;
    }
  }

  /**
   * Set the value of the object attributes based on the payment method.
   *
   * @param payment payment form group
   * @param orderSubtotal order subtotal
   */
  private parsePaymentType(payment: any, orderSubtotal: number): void {
    switch (payment.method) {
      case PaymentTypes.CreditCard:
        this.paymentType = 'creditCard';
        this.billing_country = payment.creditCard.billingCountry;
        this.billing_zip_code = payment.creditCard.billingZipCode;
        this.ccNumber = payment.creditCard.cardNumber;
        this.cvv = payment.creditCard.cvv;
        this.expirationMonth = payment.creditCard.cardMonth;
        this.expirationYear = payment.creditCard.cardYear;

        break;
      case PaymentTypes.ApplePay:
      case PaymentTypes.GooglePay:
      case PaymentTypes.Venmo:
      case PaymentTypes.Paypal:
        this.paymentType = 'braintree';
        this.nonce = payment.nonce;

        break;
      case PaymentTypes.Free:
        this.paymentType = payment.method;
        this.discount = orderSubtotal;

        break;
      case PaymentTypes.Recharge:
        this.paymentType = payment.method;

        break;
    }
  }
}
