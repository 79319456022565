import { HttpHeaders, HttpParams } from '@angular/common/http';

export abstract class LoadCartRequest {
  constructor(params: { [key: string]: any } = {}) {
    Object.assign(this, params);
  }

  /**
   * Build query params object for load cart url.
   *
   * @returns { HttpParams } the query params object for load cart url.
   **/
  abstract getHttpParams(): HttpParams;

  /**
   * Gets the required HTTP headers for the load-cart request.
   */
  getHttpHeaders(): HttpHeaders | null {
    return null;
  }
}
