export class OrderSummary {
  tests: string[];
  payments: string[];
  total: number;

  constructor(tests: string[], payments: string[], total: number) {
    this.tests = tests;
    this.payments = payments;
    this.total = total;
  }
}
