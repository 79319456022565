// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-healthlabs-layout app-cookies-disabled .cookies-required-banner {
  background-color: #efefef;
}
app-healthlabs-layout app-cookies-disabled .enabled-cookies-button {
  background: linear-gradient(#43ac6a, #388f58);
  border: 1px solid #61c185;
}

app-stdcheck-layout app-cookies-disabled .cookies-required-banner {
  color: #fff;
  background-color: #355a73;
}
app-stdcheck-layout app-cookies-disabled .enabled-cookies-button {
  background-color: #ed9013;
  border: none;
}

app-cookies-disabled .enabled-cookies-button {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  height: auto;
}
app-cookies-disabled .enabled-cookies-button:hover {
  color: #fff;
}
@media screen and (max-width: 768px) {
  app-cookies-disabled .enabled-cookies-button {
    margin-top: 1em;
  }
}
@media screen and (min-width: 769px), print {
  app-cookies-disabled .enabled-cookies-button {
    margin-left: 1em;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
