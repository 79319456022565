import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConsultationStatuses } from '@enums/consultation-statuses';
import { Gender } from '@enums/gender';
import { MissingFormFieldsError } from '@errors/missing-form-fields-error';
import { UpdatePharmacyError } from '@errors/update-pharmacy-error';
import { ConsultationRequestOrderDetail } from '@models/consultation-request/consultation-request-order-detail';
import { DateData } from '@models/date-data';
import { DateSelection } from '@models/date-selection';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { FormService } from '@services/form.service';
import { LoadingService } from '@services/loading.service';
import { SessionStorageService } from '@services/session-storage.service';
import { Subscription } from 'rxjs';
import { DynamicFormsService } from '@services/dynamic-forms.service';
import { DynamicQuestionnaireFormComponent } from '@components/dynamic-forms/dynamic-questionnaire-form/dynamic-questionnaire-form.component';
import { ExternalData } from '@models/dynamic-forms/external-data';
import { ErrorHandlerService } from '@services/error-handler.service';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { NavigationService } from '@services/navigation.service';
import { MedicalHistoryQuestionsComponent } from '@components/consultation-request/medical-history-questions/medical-history-questions.component';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { OrderTest } from '@models/order-test';

@Component({
  selector: 'app-consultation-request-form',
  templateUrl: './consultation-request-form.component.html',
  styleUrls: ['./consultation-request-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConsultationRequestFormComponent implements OnInit, OnDestroy {
  @ViewChild('medicalQuestionsForm', { static: false }) private medicalQuestionsForm: DynamicQuestionnaireFormComponent;
  @ViewChild('upsellQuestionsForm', { static: false }) private upsellQuestionsForm: DynamicQuestionnaireFormComponent;
  @ViewChild('medicalHistoryQuestionsForm', { static: false })
  private medicalHistoryQuestionsForm: MedicalHistoryQuestionsComponent;

  consultationRequestTreatmentType: string;
  upsellQuestionsSessionKey: string = 'upsells';
  consultationErrorMessage: string | null = null;
  isProcessing: boolean = false;
  responseError: string | null = null;
  submissionError: string | null = null;

  questionIds: string[] = [];
  upsellIds: string[] = [];

  private subscriptions: Subscription[] = [];

  /**
   * Determines whether the bottom error message should be displayed.
   */
  get shouldDisplayBottomError(): boolean {
    return this.submittedDataIsIncomplete || !!this.responseError;
  }

  /**
   * Checks if the submitted data is incomplete.
   */
  get submittedDataIsIncomplete(): boolean {
    return (
      (this.consultationRequestForm.invalid ||
        !this.upsellQuestionsForm?.form?.valid ||
        !this.medicalQuestionsForm?.form?.valid ||
        !this.medicalHistoryQuestionsForm?.form?.valid) &&
      this.submissionError !== null
    );
  }

  constructor(
    public loadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    @Inject(APP_CONFIG) private config: AppConfig,
    private consultationRequestService: ConsultationRequestService,
    private formService: FormService,
    private sessionStorageService: SessionStorageService,
    private title: Title,
    private dynamicFormsService: DynamicFormsService,
    private errorHandlerService: ErrorHandlerService,
    private navigationService: NavigationService
  ) {}

  /**
   * Gets the patient address form group.
   */
  get addressForm(): FormGroup {
    return this.consultationRequestForm.get('address') as FormGroup;
  }

  /**
   * Gets the current consultation request.
   */
  get consultationRequest(): any {
    return this.consultationRequestOrder?.consultationRequest;
  }

  /**
   * Gets the consultation request form group.
   */
  get consultationRequestForm(): FormGroup {
    return this.formService.consultationRequestForm;
  }

  /**
   * Gets the preferred medication form group.
   */
  get preferredMedicationForm(): FormGroup {
    return this.formService.preferredMedicationForm;
  }

  /**
   * Gets whether the current consultation request is a UTI consultation request.
   */
  get isUtiTreatmentType(): boolean {
    return this.consultationRequestTreatmentType === ConsultationTreatmentTypes.Uti;
  }

  /**
   * Gets the current consutation request order.
   */
  get consultationRequestOrder(): ConsultationRequestOrderDetail {
    return this.consultationRequestService.consultationOrderDetail;
  }

  /**
   * Gets the maximum age allowed for the consultation.
   */
  get maxAge(): number {
    return this.config.maxAgeAllowed;
  }

  /**
   * Gets the minimum age allowed for the consultation.
   */
  get minAge(): number {
    return this.config.order.minAgeAllowed;
  }

  /**
   * Gets the questionnaire form.
   */
  get questionnaireForm(): FormGroup {
    return this.medicalQuestionsForm.form;
  }

  /**
   * Gets the upsell questions form.
   */
  get upsellForm(): FormGroup {
    return this.upsellQuestionsForm.form;
  }

  /**
   * Gets the medical history form.
   */
  get medicalHistoryForm(): FormGroup {
    return this.medicalHistoryQuestionsForm.form;
  }

  /**
   * Gets the external validation data for the dynamic questionnaire form.
   */
  get externalData(): ExternalData {
    return {
      age: this.patientAge,
    };
  }

  /**
   * Gets the patient information form group.
   */
  get patientInfoForm(): FormGroup {
    return this.consultationRequestForm.get('patient') as FormGroup;
  }

  /**
   * Gets the FormGroup for the pharmacy section.
   */
  get pharmacyForm(): FormGroup {
    return this.consultationRequestForm.get('pharmacy') as FormGroup;
  }

  /**
   * Gets the preferred medication form control.
   */
  get preferredMedicationControl(): FormControl {
    return this.preferredMedicationForm.get('preferredMedication') as FormControl;
  }

  /**
   * Gets the patient's age.
   */
  get patientAge(): number {
    const { day, month, year } = this.patientInfoForm.get('birthday').value;

    return new DateSelection(+month, +day, +year).getAge();
  }

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.title.setTitle(this.config.titles.consultationRequest);
    this.consultationRequestService.storeOrderedUpsellsOnSessionStorage();
    this.handleConsultationOrderDetails();
  }

  /**
   * Sets the consultation status and unsubscribes from all subscriptions.
   */
  ngOnDestroy() {
    this.setConsultationStatus();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Stores the consultation request data and navigates to the next page.
   */
  continue(): void {
    this.submissionError = null;
    this.responseError = null;

    if (!this.shouldContinue()) {
      this.markAllFormControlsAsTouched();
      this.submissionError = new MissingFormFieldsError().message;

      return;
    }

    this.isProcessing = true;

    if (this.shouldUpdatePharmacy()) {
      return this.updatePharmacy();
    }

    this.handleSuccessWorkflow();
  }

  /**
   * Calculates the difference in days between the current date and the provided date.
   *
   * @param {Date} date the date to compare with the current date
   *
   * @returns {number} the absolute difference in days between the current date and the provided date
   */
  private getDateDiffInDays(date: Date): number {
    const timeDiff = new Date().getTime() - date.getTime();

    return Math.abs(Math.floor(timeDiff / (1000 * 3600 * 24)));
  }

  /**
   * Gets the consultation request order from the resolver and handles the error if any.
   *
   * @returns {Subscription} a Subscription to the resolver data
   */
  private handleConsultationOrderDetails(): Subscription {
    return this.activatedRoute.data.subscribe(async ({ consultationOrderDetails }) => {
      if (consultationOrderDetails?.error) {
        this.consultationErrorMessage = consultationOrderDetails.errorMessage;
      } else {
        this.consultationRequestTreatmentType = consultationOrderDetails?.consultationRequest?.treatment_type;
        this.addPreferredMedicationValidator();
        this.prefillPreferredMedication();
        await this.setQuestions(this.consultationRequestTreatmentType);
      }

      this.loadingService.toggleLoader(false);
    });
  }

  /**
   * Sets the preferred medication form control as required if the consultation request is a UTI consultation request.
   */
  private addPreferredMedicationValidator(): void {
    if (this.isUtiTreatmentType) {
      this.preferredMedicationControl?.addValidators(Validators.required);
      this.preferredMedicationControl?.updateValueAndValidity();
    }
  }

  /**
   * Prefills the preferred medication with the first ordered test.
   */
  private prefillPreferredMedication(): void {
    this.sessionStorageService.preferredMedication = this.getFirstOrderedTest()?.customer_tests_name;
  }

  /**
   * Gets the first ordered test.
   *
   * @returns {OrderTest} the first ordered test from consultation request order tests
   */
  private getFirstOrderedTest(): OrderTest {
    return this.consultationRequestOrder?.tests.filter((test) => test.customer_tests_type === 'Individual')?.[0];
  }

  /**
   * Handles the success workflow for the consultation request continue button.
   */
  private handleSuccessWorkflow(): void {
    this.storeConsultationRequestCompletedData();
    this.isProcessing = false;

    this.navigationService.navigateToNextConsultationRequestPage(this.sessionStorageService.treatmentType);
  }

  /**
   * Determines if it has been less than 28 days since the last treatment was prescribed.
   *
   * @returns {boolean} true if it has been less than 28 days, otherwise false
   */
  private isLessThan28DaysSinceLastTreatment(): boolean {
    const lastPrescribedAt = this.consultationRequestOrder.last_prescribed_at;
    if (!lastPrescribedAt) {
      return false;
    }

    return this.getDateDiffInDays(new Date(lastPrescribedAt)) < 28;
  }

  /**
   * Determines if a patient's age makes them eligible for a consultation request.
   *
   * @param {DateData} patientBirthday the patient's date of birth
   *
   * @returns {boolean} true if the patient's age is within the valid range, otherwise false
   */
  private isPatientAgeValid(patientBirthday: DateData): boolean {
    const { day, month, year } = patientBirthday;

    return new DateSelection(+month, +day, +year).isAgeValid(this.minAge, this.maxAge);
  }

  /**
   * Marks all form controls as touched.
   */
  private markAllFormControlsAsTouched(): void {
    this.patientInfoForm.markAllAsTouched();
    this.pharmacyForm.markAllAsTouched();
    this.preferredMedicationControl.markAllAsTouched();
    this.addressForm.markAllAsTouched();
    this.questionnaireForm.markAllAsTouched();
    this.upsellForm.markAllAsTouched();
    this.medicalHistoryForm.markAllAsTouched();
  }

  /**
   * Determines if the patient has disqualifying answers in the questionnaire.
   *
   * @returns {boolean} true if the patient has disqualifying answers, otherwise false
   */
  private hasDisqualifyingAnswers(): boolean {
    return this.medicalQuestionsForm.questions.some(
      (question) =>
        this.dynamicFormsService.getQuestionVisibleWarnings(question, this.medicalQuestionsForm.form).length > 0
    );
  }

  /**
   * Sets the consultation status to disqualified if the patient is not eligible for the consultation.
   */
  private setConsultationStatus(): void {
    this.sessionStorageService.consultationStatus =
      this.isLessThan28DaysSinceLastTreatment() ||
      this.sessionStorageService.patient.gender === Gender.Male ||
      !this.isPatientAgeValid(this.sessionStorageService.patient.birthday) ||
      this.hasDisqualifyingAnswers()
        ? ConsultationStatuses.Disqualified
        : ConsultationStatuses.Pending;
  }

  /**
   * Checks if the forms displayed on the page are valid.
   *
   * @returns {boolean} true if all forms are valid, otherwise false
   */
  private shouldContinue(): boolean {
    return (
      this.medicalHistoryForm.valid &&
      this.upsellForm.valid &&
      this.questionnaireForm.valid &&
      this.patientInfoForm.valid &&
      this.pharmacyForm.valid &&
      this.preferredMedicationControl.valid &&
      this.addressForm.valid
    );
  }

  /**
   * Checks if the pharmacy should be updated.
   *
   * @returns {boolean} true if the pharmacy should be updated, otherwise false
   */
  private shouldUpdatePharmacy(): boolean {
    return this.consultationRequest.pharmacy.id !== this.pharmacyForm.value.id;
  }

  /**
   * Stores the data that is needed for the consultation request completed page.
   */
  private storeConsultationRequestCompletedData(): void {
    this.sessionStorageService.patient = {
      name: this.patientInfoForm.get('first_name').value,
      last_name: this.patientInfoForm.get('last_name').value,
      email: this.consultationRequestOrder.email,
      phone: this.consultationRequestOrder.phone,
      birthday: this.patientInfoForm.get('birthday').value,
      gender: this.patientInfoForm.get('gender').value,
      height_feet: this.patientInfoForm.get('height_feet').value,
      height_inches: this.patientInfoForm.get('height_inches').value,
      weight: this.patientInfoForm.get('weight').value,
    };
    this.sessionStorageService.transactionId = this.consultationRequestOrder.transaction_id;
    this.sessionStorageService.consultationId = this.consultationRequest.id;
    this.sessionStorageService.hash = this.consultationRequestOrder.hash;
    this.sessionStorageService.preferredMedication =
      this.preferredMedicationControl.value ?? this.sessionStorageService.preferredMedication;
    this.sessionStorageService.address = this.addressForm.value;
    this.sessionStorageService.pharmacy = this.pharmacyForm.value;
    this.sessionStorageService.upsells = this.getUpsells();
    this.sessionStorageService.medicalHistoryQuestionnaireAnswers = this.medicalHistoryForm.value;
  }

  /**
   * Gets the upsells selected by the patient.
   */
  private getUpsells(): string[] {
    return Object.values(this.sessionStorageService.getQuestionnaireAnswers(this.upsellQuestionsSessionKey)).filter(
      (value: string) => value !== QuestionnaireAnswers.No
    );
  }

  /**
   * Updates the consultation request pharmacy.
   */
  private updatePharmacy(): void {
    this.consultationRequestService
      .update(
        this.consultationRequest.id,
        this.consultationRequestOrder.transaction_id,
        this.consultationRequestOrder.hash,
        {
          pharmacy_id: this.pharmacyForm.value.id,
        }
      )
      .subscribe({
        next: () => this.handleSuccessWorkflow(),
        error: () => {
          this.isProcessing = false;
          this.responseError = new UpdatePharmacyError().message;
        },
      });
  }

  /**
   * Sets the questions and upsells ids for the current treatment type.
   *
   * @param {string} treatmentType the treatment type to get the questions for
   *
   * @returns {Promise<void | string>} the question ids or an error message
   */
  private setQuestions(treatmentType: string): Promise<void | string> {
    return this.dynamicFormsService
      .getQuestionnaire(treatmentType)
      .then((questionnaire) => {
        this.questionIds = questionnaire.questionIds;
        this.upsellIds = questionnaire.upsellIds;
      })
      .catch(() => (this.consultationErrorMessage = this.errorHandlerService.defaultErrorMessage));
  }
}
