// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs {
  margin-bottom: 0;
}

li.is-active {
  font-weight: bold;
}

.tabs-content {
  padding: 0 !important;
  border: none;
}

.bordered-component {
  padding: 1rem;
  border: 1px solid hsl(0, 0%, 86%);
  border-top: none;
}
.bordered-component.rounded-top-border {
  border-top: 1px solid hsl(0, 0%, 86%);
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}
.bordered-component.rounded-bottom-border {
  border-radius: 0 0 0.5rem 0.5rem;
}

.paylater__content ul {
  margin-left: 1.2em !important;
  margin-top: 0px !important;
}
.paylater__content ul li {
  color: #131516;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
