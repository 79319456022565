import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as Sentry from '@sentry/angular';
import braintree from 'braintree-web';

@Injectable({
  providedIn: 'root',
})
export class BraintreeService {
  deviceData: any = null;

  private clientInstance: any;

  constructor() {}

  /**
   * Retrieves or creates the Braintree client instance.
   *
   * @returns {Promise<any>} a promise that resolves to a Client instance
   */
  async getClient(): Promise<any> {
    return this.clientInstance
      ? Promise.resolve(this.clientInstance)
      : this.createClient().then((client: any) => {
          this.clientInstance = client;

          return client;
        });
  }

  /**
   * Initializes the device data with retries.
   *
   * @param {number} retries the number of retries in case of failure
   */
  initializeDeviceData(retries: number = 2): void {
    this.getDeviceData()
      .then((deviceData) => (this.deviceData = deviceData))
      .catch((err: any) => {
        if (retries > 0) {
          setTimeout(() => this.initializeDeviceData(retries - 1), 3000);
        } else {
          this.deviceData = null;
          Sentry.captureException(err);
        }
      });
  }

  /**
   * Creates a Braintree client instance.
   *
   * @returns {Promise<any>} a promise that resolves to a Client instance
   */
  private createClient(): Promise<any> {
    return braintree.client.create({ authorization: environment.braintreeToken });
  }

  /**
   * Gets the device data from the Braintree data collector.
   *
   * @returns {Promise<any>} a promise that resolves to the device data
   */
  private async getDeviceData(): Promise<void> {
    return this.getClient().then((client) =>
      braintree.dataCollector.create({ client }).then((dataCollectorInstance: any) => dataCollectorInstance.deviceData)
    );
  }
}
