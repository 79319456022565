import { Component, Inject } from '@angular/core';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { SelectableOption } from '@models/selectable-option';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { FormService } from '@services/form.service';
import { SessionStorageService } from '@services/session-storage.service';
import { UtiConsultationRequestUpsellComponent } from '@consultation-upsells/uti-consultation-request-upsell.component';

@Component({
  selector: 'app-vaginal-cream',
  templateUrl: './vaginal-cream.component.html',
  styleUrls: ['./vaginal-cream.component.scss'],
})
export class VaginalCreamComponent extends UtiConsultationRequestUpsellComponent {
  protected slug: UpsellSlugs = UpsellSlugs.DoctorDecideWhichBVCream;

  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected formService: FormService,
    protected sessionStorageService: SessionStorageService
  ) {
    super(config, formService, sessionStorageService);
  }

  get price(): number {
    return this.config.consultationRequestUpsellPrices[this.slug];
  }

  /**
   * Sets the options for the nausea and vomiting upsell.
   */
  protected override setOptions(): void {
    this.options = [
      new SelectableOption('I prefer taking oral medication.'),
      new SelectableOption(
        'I prefer to let the doctor decide which cream is best for me.',
        UpsellSlugs.DoctorDecideWhichBVCream
      ),
      new SelectableOption('I prefer Metronidazole Vaginal Gel 0.75% (Metrogel).', UpsellSlugs.MetronidazoleVaginalGel),
      new SelectableOption('I prefer Clindamycin Vaginal Cream 2% (Cleocin).', UpsellSlugs.ClindamycinVaginalCream),
    ];
  }
}
