import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingSource = new ReplaySubject<boolean>();
  isLoading: boolean;
  loading$: Observable<boolean> = this.loadingSource.asObservable();

  /**
   * Toggle the loader variable status
   *
   * @param {boolean} loadingStatus
   */
  toggleLoader(loadingStatus: boolean): void {
    this.isLoading = loadingStatus;
    this.loadingSource.next(loadingStatus);
  }
}
