import { APP_CONFIG } from '@modules/config/types/config';
import { ConfigService } from '@modules/config/services/config.service';
import { APP_INITIALIZER } from '@angular/core';

const configServiceFactory = (configService: ConfigService) => () => configService.loadAppConfig();

export const ConfigServiceProviders = [
  {
    provide: APP_INITIALIZER,
    useFactory: configServiceFactory,
    deps: [ConfigService],
    multi: true,
  },
  {
    provide: APP_CONFIG,
    useFactory: ConfigService.config,
    deps: [ConfigService],
  },
];
