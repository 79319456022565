// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: helvetica-c;
  src: url('helveticaneue-medium.d694f29acd2cda93.woff2') format("woff2"), url('helveticaneue-medium.8694148ae70f8a8a.woff') format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
.only-healthlabs {
  display: none !important;
}

.only-stdcheck {
  display: none !important;
}

body {
  color: #2f2f2f;
  font-family: Nunito, serif;
}
body .main-content {
  background-color: #f4f5f7;
  padding: 1rem;
}

app-treatmyuti-layout input,
app-treatmyuti-layout textarea,
app-treatmyuti-layout select {
  font-family: Nunito, serif !important;
}
app-treatmyuti-layout .treatmyuti-header {
  padding: 15px 20px;
  background-color: #fdd8d9;
  font-size: 16px;
  font-weight: 500;
  font-family: helvetica-c;
}
app-treatmyuti-layout .treatmyuti-header .logo-img {
  width: 200px;
}
@media screen and (min-width: 568px) {
  app-treatmyuti-layout .treatmyuti-header .logo-img {
    width: 300px;
  }
}
@media screen and (min-width: 1200px) {
  app-treatmyuti-layout .treatmyuti-header .logo-img {
    width: 235px;
  }
}
@media screen and (min-width: 1440px) {
  app-treatmyuti-layout .treatmyuti-header .logo-img {
    width: 280px;
  }
}
@media screen and (min-width: 1720px) {
  app-treatmyuti-layout .treatmyuti-header .logo-img {
    width: 360px;
  }
}
@media screen and (max-width: 1199px) {
  app-treatmyuti-layout .treatmyuti-header .phone-number-container {
    display: none !important;
  }
}
app-treatmyuti-layout .treatmyuti-header .phone-number-container .phone-number {
  color: #2f2f2f;
  text-decoration: none;
  margin-left: 8px;
}
app-treatmyuti-layout .treatmyuti-header .phone-number-container .phone-number:hover {
  color: #b46397;
}
app-treatmyuti-layout .treatmyuti-footer {
  padding: 15px 20px;
  background-image: url('treatmyuti-footer-bg.163659f6322a9c0a.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  color: #fff;
}
app-treatmyuti-layout .treatmyuti-footer .logo-img {
  width: 288px;
}
@media screen and (min-width: 568px) {
  app-treatmyuti-layout .treatmyuti-footer .logo-img {
    width: 360px;
  }
}
@media screen and (min-width: 1200px) {
  app-treatmyuti-layout .treatmyuti-footer .logo-img {
    width: 405px;
  }
}
app-treatmyuti-layout .card {
  border: solid 1px rgba(19, 21, 22, 0.25);
  border-radius: 8px;
}
app-treatmyuti-layout .card .card-header {
  background: #b46397;
  border-radius: 0.5rem 0.5rem 0 0;
  line-height: 1.6rem;
  padding: 0.8rem 0.9rem !important;
  box-shadow: none;
  color: white;
  font-size: 1.9rem;
}
@media screen and (min-width: 769px) {
  app-treatmyuti-layout .card .card-header {
    padding: 1.5rem 1.2rem !important;
  }
}
app-treatmyuti-layout .card .card-body {
  border-radius: 0 0 5px 5px;
}
app-treatmyuti-layout .card .card-content h1 {
  color: #15232d;
}
app-treatmyuti-layout .card .title {
  font-weight: 700;
}
app-treatmyuti-layout .card h3.title {
  font-size: 1.75rem;
  line-height: 2.45rem;
  margin-bottom: 8px !important;
}
app-treatmyuti-layout .card p,
app-treatmyuti-layout .card h6.title,
app-treatmyuti-layout .card h3.title {
  color: #15232d !important;
}
app-treatmyuti-layout .card p {
  font-size: 1rem;
}
app-treatmyuti-layout .card p small {
  font-size: 0.75rem;
}
app-treatmyuti-layout app-order-summary .card .card-header {
  padding: 0.7rem 0.5rem !important;
  background-color: #b46397;
  font-size: 1rem;
}
app-treatmyuti-layout app-order-summary .card .card-header .card-header-icon,
app-treatmyuti-layout app-order-summary .card .card-header .card-header-title {
  padding: 0;
}
app-treatmyuti-layout app-order-summary .card .card-header .card-header-icon {
  font-weight: bold;
  text-decoration: underline;
}
app-treatmyuti-layout app-order-summary .card .card-body {
  padding: 0 0.5rem;
}
app-treatmyuti-layout app-order-summary table th,
app-treatmyuti-layout app-order-summary table td {
  padding-left: 0;
}
app-treatmyuti-layout app-place-order .button-place-your-order,
app-treatmyuti-layout .default-button {
  background-color: #b46397 !important;
  font-family: Nunito, serif;
  font-weight: 700;
}
app-treatmyuti-layout app-place-order .button-place-your-order:hover,
app-treatmyuti-layout .default-button:hover {
  background-color: #cfb0c8 !important;
  color: #b46397 !important;
}
app-treatmyuti-layout app-order-address #submit-address-button {
  background-color: #b46397 !important;
}
app-treatmyuti-layout app-order-complete-instructions app-card .card-content {
  background-color: white !important;
}
app-treatmyuti-layout .tabs-content {
  padding: 1rem !important;
  border-radius: 0 0 0.5rem 0.5rem !important;
}
app-treatmyuti-layout .tabs.is-boxed {
  border: solid 1px #c9cbcc;
  border-radius: 0.5rem 0.5rem 0 0;
}
app-treatmyuti-layout .tabs.is-boxed ul {
  align-items: center;
  border: none;
}
app-treatmyuti-layout .tabs.is-boxed ul li a {
  font-size: 0.875rem;
  padding: 1.25rem 1.5rem;
  font-weight: 700;
  cursor: pointer;
  margin-top: -1px;
  border-radius: 0;
  color: #3398db;
}
app-treatmyuti-layout .tabs.is-boxed ul li a:hover {
  background: #fff !important;
  color: #3d6884;
}
app-treatmyuti-layout .tabs.is-boxed ul li.is-active a {
  border: none;
  background: #3d6884 !important;
  color: #fff;
}
app-treatmyuti-layout .help_text {
  color: #355a73;
  font-size: 0.8125rem;
  font-style: italic;
  margin-top: -0.5rem;
}
app-treatmyuti-layout .select select,
app-treatmyuti-layout .textarea,
app-treatmyuti-layout .input {
  border-color: #b46397;
}
app-treatmyuti-layout .notification.is-danger.notification-error {
  background-color: #fcdfdf;
}
@media screen and (max-width: 768px) {
  app-treatmyuti-layout .tabs.is-boxed ul {
    flex-direction: column;
  }
  app-treatmyuti-layout .tabs.is-boxed ul li {
    width: 100%;
  }
  app-treatmyuti-layout .tabs.is-boxed ul li a {
    justify-content: start !important;
    font-size: 0.75rem;
    text-align: left;
    padding: 0.4rem;
  }
}
app-treatmyuti-layout input.ng-valid:not(.ng-empty),
app-treatmyuti-layout textarea.ng-valid:not(.ng-empty),
app-treatmyuti-layout select.ng-valid:not(.ng-empty) {
  background-color: #f9faf6;
  border-color: #b46397;
  transition: 0.2s ease all;
}
app-treatmyuti-layout input.ng-valid:not(.ng-empty)[type=text], app-treatmyuti-layout input.ng-valid:not(.ng-empty)[type=number], app-treatmyuti-layout input.ng-valid:not(.ng-empty)[type=tel], app-treatmyuti-layout input.ng-valid:not(.ng-empty)[type=email],
app-treatmyuti-layout textarea.ng-valid:not(.ng-empty)[type=text],
app-treatmyuti-layout textarea.ng-valid:not(.ng-empty)[type=number],
app-treatmyuti-layout textarea.ng-valid:not(.ng-empty)[type=tel],
app-treatmyuti-layout textarea.ng-valid:not(.ng-empty)[type=email],
app-treatmyuti-layout select.ng-valid:not(.ng-empty)[type=text],
app-treatmyuti-layout select.ng-valid:not(.ng-empty)[type=number],
app-treatmyuti-layout select.ng-valid:not(.ng-empty)[type=tel],
app-treatmyuti-layout select.ng-valid:not(.ng-empty)[type=email] {
  background-image: url('success-check.11794522dd3edda5.svg');
  background-origin: content-box;
  background-position: right center;
  background-repeat: no-repeat;
}
app-treatmyuti-layout textarea.ng-valid-required:not(.ng-empty, .ng-invalid) {
  background-image: url('success-check.11794522dd3edda5.svg');
  background-origin: content-box;
  background-position: right bottom;
  background-repeat: no-repeat;
}
app-treatmyuti-layout .label {
  font-weight: 700 !important;
}
app-treatmyuti-layout .button.button.is-primary {
  background-color: #b46397;
  font-weight: 700;
  font-family: Nunito, serif;
}
app-treatmyuti-layout .button.button.is-primary:hover {
  background-color: #cfb0c8;
  color: #b46397;
}
app-treatmyuti-layout app-order-service-agreement a {
  color: #b46397;
  text-decoration: none;
}
app-treatmyuti-layout app-order-service-agreement a:hover {
  color: #cfb0c8;
}
app-treatmyuti-layout app-order-summary .coupon .has-text-right {
  color: red;
}
app-treatmyuti-layout app-icon-and-button-card .container {
  max-width: 50rem !important;
}
app-treatmyuti-layout app-icon-and-button-card .card {
  padding: 2rem 1rem;
  border: none;
  box-shadow: none;
}
app-treatmyuti-layout app-icon-and-button-card .card .card-inner-container {
  display: block;
  margin: 0 auto;
  max-width: 37rem;
}
app-treatmyuti-layout app-icon-and-button-card .card .card-inner-container .card-image {
  height: 100px;
  width: 100px;
  margin-bottom: 2rem;
  filter: brightness(0) saturate(100%) invert(78%) sepia(9%) saturate(5231%) hue-rotate(283deg) brightness(73%) contrast(88%);
}
app-treatmyuti-layout app-icon-and-button-card .card .card-inner-container .card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #b46397;
  text-align: center;
}
app-treatmyuti-layout app-icon-and-button-card .card .card-inner-container .card-button {
  min-width: 200px;
}
app-treatmyuti-layout app-consult-attachment .card {
  border: none;
  box-shadow: none;
}
app-treatmyuti-layout .schedule-consultation-title-container {
  padding-top: 1.5rem;
  margin-bottom: 0 !important;
}
app-treatmyuti-layout .schedule-consultation-title-container h1 {
  font-weight: 700;
}
app-treatmyuti-layout .schedule-information {
  background-color: #b46397 !important;
}
app-treatmyuti-layout .clinician-notification {
  background-color: #fcdfdf !important;
  color: black !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
