import { HttpParams } from '@angular/common/http';
import { CustomURLEncoder } from '@models/custom-url-encoder';
import { LoadCartRequest } from '@models/load-cart-request';
import { Test } from '@models/test';

export class TreatMyUTILoadCartRequest extends LoadCartRequest {
  tests: Partial<Test>[];
  coupon: string;

  /**
   * Builds the query params object to make the load cart request for TreatMyUTI.com.
   *
   * @returns { HttpParams } the query params to be used in the load cart request
   **/
  override getHttpParams(): HttpParams {
    let params = new HttpParams({ encoder: new CustomURLEncoder() });

    if (this.coupon) {
      params = params.append('coupon', this.coupon);
    }

    if (this.tests) {
      this.tests.forEach((test) => (params = params.append('tests[][name]', test.name)));
    }

    return params;
  }
}
