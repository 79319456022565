export class AnalyteCareCouponDto {
  name: string;
  code: string;
  value: number;

  constructor(name: string, code: string, value: number) {
    this.name = name;
    this.code = code;
    this.value = value;
  }
}
