import { Component, ViewEncapsulation } from '@angular/core';
import { DomainService } from '@services/domain.service';
import { LoadingService } from '@services/loading.service';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SiteLayoutComponent {
  /** Loading status */
  isLoading: boolean = false;

  constructor(public domainService: DomainService, private loadingService: LoadingService) {
    this.loadingService.loading$.subscribe((loadingStatus) => (this.isLoading = loadingStatus));
  }
}
