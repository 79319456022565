import { Component, Inject } from '@angular/core';
import { UtiConsultationRequestUpsellComponent } from '@consultation-upsells/uti-consultation-request-upsell.component';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { SelectableOption } from '@models/selectable-option';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { FormService } from '@services/form.service';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-alleviate-nausea-and-vomiting',
  templateUrl: './alleviate-nausea-and-vomiting.component.html',
  styleUrls: ['./alleviate-nausea-and-vomiting.component.scss'],
})
export class AlleviateNauseaAndVomitingComponent extends UtiConsultationRequestUpsellComponent {
  protected override slug = UpsellSlugs.OndansetronPill;

  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected formService: FormService,
    protected sessionStorageService: SessionStorageService
  ) {
    super(config, formService, sessionStorageService);
  }

  /**
   * Sets the options for the nausea and vomiting upsell.
   */
  protected override setOptions(): void {
    this.options = [
      new SelectableOption('Yes, add Zofran in pill form', this.slug),
      new SelectableOption('Yes, add Zofran in dissolvable tablet form', UpsellSlugs.OndansetronDissolvableTablet),
      new SelectableOption("No, I don't want to include Zofran", QuestionnaireAnswers.No),
    ];
  }
}
