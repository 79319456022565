// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-consultation-request-terms-of-service .tos {
  max-height: 13rem;
  overflow-y: scroll;
}
app-consultation-request-terms-of-service .tos p {
  font-size: inherit;
  line-height: 1.6;
  margin-bottom: 1rem;
}
app-consultation-request-terms-of-service .tos ol {
  list-style-type: decimal;
}
app-consultation-request-terms-of-service .tos ul {
  list-style-type: disc;
}
app-consultation-request-terms-of-service .tos ol,
app-consultation-request-terms-of-service .tos ul {
  margin-left: 1.25rem;
}
app-consultation-request-terms-of-service .tos dl,
app-consultation-request-terms-of-service .tos ol,
app-consultation-request-terms-of-service .tos ul {
  line-height: 1.6;
  list-style-position: outside;
  margin-bottom: 1rem;
}
app-consultation-request-terms-of-service .tos li {
  display: list-item;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
