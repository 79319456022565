import { Component, Inject, Input, OnInit } from '@angular/core';
import { OrderCompleteDetails } from '@models/order-complete-details';
import { DomainService } from '@services/domain.service';
import { StorageService } from '@services/storage.service';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { InHomeBookingResult } from '@Medology/ng-findalab';
import { Sites } from '@enums/sites';

@Component({
  selector: 'app-order-complete-instructions',
  templateUrl: './order-complete-instructions.component.html',
})
export class OrderCompleteInstructionsComponent implements OnInit {
  @Input() orderCompleteDetails: OrderCompleteDetails;
  hasEmail: boolean;
  isFree: boolean;
  sendSms: boolean;
  isPayLater: boolean;
  inHomeBookingResult: InHomeBookingResult;
  assistanceDetails: string = `If you have any questions or need assistance in the meantime, our Patient Services and
  Support teams are available Monday - Sunday 6 a.m. - 10 p.m. (Center Standard Time - CST).`;
  Sites: typeof Sites = Sites;

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    public domainService: DomainService,
    public storageService: StorageService
  ) {}

  ngOnInit(): void {
    if (this.orderCompleteDetails) {
      this.hasEmail = this.orderCompleteDetails.hasEmail;
      this.isFree = this.orderCompleteDetails.free;
      this.sendSms = this.orderCompleteDetails.sendSms;
      this.isPayLater = this.orderCompleteDetails.isPayLater;
      this.inHomeBookingResult = this.orderCompleteDetails.inHomeBookingResult;
    }
  }

  /**
   * Determine if the cancellation policy should be shown.
   */
  get showCancellationPolicy(): boolean {
    return !this.domainService.isTreatMyUtiDomain() && !this.domainService.isStarfishDomain();
  }

  /**
   * Render different headerClass based on domain.
   */
  get headerClasses(): string {
    const baseClasses = 'p-6 is-size-3 is-size-4-mobile has-text-weight-bold card-header-title has-text-white';

    return this.domainService.isSTDCheckDomain() ? `${baseClasses} has-background-green ` : baseClasses;
  }
}
