import { InputTypes } from '@enums/input-types';
import { LogicalOperators } from '@enums/logical-operators';
import { QuestionValidator } from '@models/dynamic-forms/question-validator';
import { QuestionVisibility } from '@models/dynamic-forms/question-visibility';
import { QuestionWarning } from '@models/dynamic-forms/question-warning';
import { SelectableOption } from '@models/selectable-option';

export class Question {
  id: string;
  type: InputTypes;
  value: any;
  label: string;
  validators: QuestionValidator[];
  options: SelectableOption[];
  visibility: QuestionVisibility;
  warnings: QuestionWarning[];
  hint: string;
  placeholder: string;

  constructor(options: {
    id: string;
    type: InputTypes;
    value?: any;
    label?: string;
    validators?: QuestionValidator[];
    options?: SelectableOption[];
    visibility?: QuestionVisibility;
    warnings?: QuestionWarning[];
    hint?: string;
    placeholder?: string;
  }) {
    this.id = options.id;
    this.type = options.type;
    this.value = options.value;
    this.label = options.label || '';
    this.validators = options.validators || [];
    this.options = options.options || [];
    this.visibility = options.visibility || {
      externalConditions: {
        conditions: [],
        logicalOperator: LogicalOperators.And,
      },
      triggerQuestions: {
        questions: [],
        logicalOperator: LogicalOperators.And,
      },
      logicalOperator: LogicalOperators.And,
    };
    this.warnings = options.warnings || [];
    this.hint = options.hint || '';
    this.placeholder = options.placeholder || '';
  }
}
