// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.apple-pay-button-with-text {
  cursor: pointer;
}

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button-with-text {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: check-out;
    height: 50.42px;
    width: 240px;
  }
  .apple-pay-button-black-with-text {
    -apple-pay-button-style: black;
  }
}
@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button-with-text {
    width: 240px;
    height: 50.42px;
    background-image: url('check-out-with-apple-pay.4ec750a03add4fd7.png');
    background-size: cover;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
