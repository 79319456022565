import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '@services/storage.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MycoplasmaGenitaliumResponse } from '@models/mycoplasma-genitalium-response';

@Injectable({
  providedIn: 'root',
})
export class MycoplasmaGenitaliumService {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private storage: StorageService
  ) {}

  /**
   * Checks if the Mycoplasma Genitalium Test is already ordered.
   */
  isMycoplasmaGenitaliumTestOrdered(): boolean {
    return this.storage.tests.some((test) => test.id === this.config.mycoplasmaGenitaliumUpsell.id);
  }

  /**
   * Get the Mycoplasma Genitalium content.
   *
   * @returns an Observable of the MycoplasmaGenitaliumResponse
   */
  getMycoplasmaGenitaliumContent(): Observable<MycoplasmaGenitaliumResponse> {
    return this.http
      .get(`${this.config.stdcheckPage}/json-mycoplasma-genitalium`, { responseType: 'text' })
      .pipe(map((responseText) => JSON.parse(responseText)));
  }
}
