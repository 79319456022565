import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() active: boolean = false;
  @Output() dismiss: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Emits an event to dismiss the modal.
   */
  dismissModal(): void {
    this.dismiss.emit();
  }
}
