// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-tooltip]:not(.is-loading), [data-tooltip]:not(.is-disabled), [data-tooltip]:not([disabled]) {
  cursor: pointer;
  overflow: visible;
  position: relative;
}
[data-tooltip]:not(.is-loading)::before, [data-tooltip]:not(.is-disabled)::before, [data-tooltip]:not([disabled])::before {
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 13px;
  -webkit-hyphens: auto;
          hyphens: auto;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  visibility: hidden;
  z-index: 1020;
  background: #4a4a4a;
  border-radius: 8px;
  content: attr(data-tooltip);
  padding: 0.5rem 1rem;
  text-overflow: ellipsis;
  white-space: pre;
}
[data-tooltip]:not(.is-loading)::before, [data-tooltip]:not(.is-disabled)::before, [data-tooltip]:not([disabled])::before {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  top: 0;
  margin-top: -5px;
  margin-bottom: auto;
  transform: translate(-50%, -100%);
}
[data-tooltip]:not(.is-loading).has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-arrow::after {
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 13px;
  -webkit-hyphens: auto;
          hyphens: auto;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  visibility: hidden;
  z-index: 1020;
  content: "";
  border-style: solid;
  border-width: 6px;
  border-color: #4a4a4a transparent transparent transparent;
  margin-bottom: -5px;
}
[data-tooltip]:not(.is-loading).has-tooltip-arrow.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-arrow.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-arrow.has-tooltip-arrow::after {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  margin-top: -5px;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: -5px;
  border-color: #4a4a4a transparent transparent transparent;
}
[data-tooltip]:not(.is-loading).has-tooltip-bottom.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-bottom.has-tooltip-arrow::after {
  top: auto;
  right: auto;
  bottom: -1px;
  left: 50%;
  margin-top: auto;
  margin-right: auto;
  margin-bottom: -5px;
  margin-left: -5px;
  border-color: transparent transparent #4a4a4a transparent;
}
[data-tooltip]:not(.is-loading).has-tooltip-bottom::before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom::before, [data-tooltip]:not([disabled]).has-tooltip-bottom::before {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  margin-top: auto;
  margin-bottom: -5px;
  transform: translate(-50%, 100%);
}
[data-tooltip]:not(.is-loading).has-tooltip-left.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-left.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-left.has-tooltip-arrow::after {
  top: auto;
  right: auto;
  bottom: 50%;
  left: 0;
  margin-top: auto;
  margin-right: auto;
  margin-bottom: -6px;
  margin-left: -5px;
  border-color: transparent transparent transparent #4a4a4a;
}
[data-tooltip]:not(.is-loading).has-tooltip-left::before, [data-tooltip]:not(.is-disabled).has-tooltip-left::before, [data-tooltip]:not([disabled]).has-tooltip-left::before {
  top: auto;
  right: auto;
  bottom: 50%;
  left: -5px;
  transform: translate(-100%, 50%);
}
[data-tooltip]:not(.is-loading).has-tooltip-right.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-right.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-right.has-tooltip-arrow::after {
  top: auto;
  right: 0;
  bottom: 50%;
  left: auto;
  margin-top: auto;
  margin-right: -6px;
  margin-bottom: -6px;
  margin-left: auto;
  border-color: transparent #4a4a4a transparent transparent;
}
[data-tooltip]:not(.is-loading).has-tooltip-right::before, [data-tooltip]:not(.is-disabled).has-tooltip-right::before, [data-tooltip]:not([disabled]).has-tooltip-right::before {
  top: auto;
  right: -5px;
  bottom: 50%;
  left: auto;
  margin-top: auto;
  transform: translate(100%, 50%);
}
[data-tooltip]:not(.is-loading).has-tooltip-multiline::before, [data-tooltip]:not(.is-disabled).has-tooltip-multiline::before, [data-tooltip]:not([disabled]).has-tooltip-multiline::before {
  height: auto;
  width: 15rem;
  max-width: 15rem;
  text-overflow: clip;
  white-space: normal;
  word-break: keep-all;
}
[data-tooltip]:not(.is-loading).has-tooltip-text-left::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left::before, [data-tooltip]:not([disabled]).has-tooltip-text-left::before {
  text-align: left;
}
[data-tooltip]:not(.is-loading).has-tooltip-text-centered::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered::before, [data-tooltip]:not([disabled]).has-tooltip-text-centered::before {
  text-align: center;
}
[data-tooltip]:not(.is-loading).has-tooltip-text-right::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right::before, [data-tooltip]:not([disabled]).has-tooltip-text-right::before {
  text-align: right;
}
[data-tooltip]:not(.is-loading).has-tooltip-white::after, [data-tooltip]:not(.is-disabled).has-tooltip-white::after, [data-tooltip]:not([disabled]).has-tooltip-white::after {
  border-color: white transparent transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-white.has-tooltip-bottom::after, [data-tooltip]:not(.is-disabled).has-tooltip-white.has-tooltip-bottom::after, [data-tooltip]:not([disabled]).has-tooltip-white.has-tooltip-bottom::after {
  border-color: transparent transparent white transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-white.has-tooltip-left::after, [data-tooltip]:not(.is-disabled).has-tooltip-white.has-tooltip-left::after, [data-tooltip]:not([disabled]).has-tooltip-white.has-tooltip-left::after {
  border-color: transparent transparent transparent white !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-white.has-tooltip-right::after, [data-tooltip]:not(.is-disabled).has-tooltip-white.has-tooltip-right::after, [data-tooltip]:not([disabled]).has-tooltip-white.has-tooltip-right::after {
  border-color: transparent white transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-white:before, [data-tooltip]:not(.is-disabled).has-tooltip-white:before, [data-tooltip]:not([disabled]).has-tooltip-white:before {
  background-color: white;
  color: hsl(0, 0%, 4%);
}
[data-tooltip]:not(.is-loading).has-tooltip-black::after, [data-tooltip]:not(.is-disabled).has-tooltip-black::after, [data-tooltip]:not([disabled]).has-tooltip-black::after {
  border-color: #0a0a0a transparent transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-black.has-tooltip-bottom::after, [data-tooltip]:not(.is-disabled).has-tooltip-black.has-tooltip-bottom::after, [data-tooltip]:not([disabled]).has-tooltip-black.has-tooltip-bottom::after {
  border-color: transparent transparent #0a0a0a transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-black.has-tooltip-left::after, [data-tooltip]:not(.is-disabled).has-tooltip-black.has-tooltip-left::after, [data-tooltip]:not([disabled]).has-tooltip-black.has-tooltip-left::after {
  border-color: transparent transparent transparent #0a0a0a !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-black.has-tooltip-right::after, [data-tooltip]:not(.is-disabled).has-tooltip-black.has-tooltip-right::after, [data-tooltip]:not([disabled]).has-tooltip-black.has-tooltip-right::after {
  border-color: transparent #0a0a0a transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-black:before, [data-tooltip]:not(.is-disabled).has-tooltip-black:before, [data-tooltip]:not([disabled]).has-tooltip-black:before {
  background-color: #0a0a0a;
  color: #fff;
}
[data-tooltip]:not(.is-loading).has-tooltip-light::after, [data-tooltip]:not(.is-disabled).has-tooltip-light::after, [data-tooltip]:not([disabled]).has-tooltip-light::after {
  border-color: #dce0e3 transparent transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-light.has-tooltip-bottom::after, [data-tooltip]:not(.is-disabled).has-tooltip-light.has-tooltip-bottom::after, [data-tooltip]:not([disabled]).has-tooltip-light.has-tooltip-bottom::after {
  border-color: transparent transparent #dce0e3 transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-light.has-tooltip-left::after, [data-tooltip]:not(.is-disabled).has-tooltip-light.has-tooltip-left::after, [data-tooltip]:not([disabled]).has-tooltip-light.has-tooltip-left::after {
  border-color: transparent transparent transparent #dce0e3 !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-light.has-tooltip-right::after, [data-tooltip]:not(.is-disabled).has-tooltip-light.has-tooltip-right::after, [data-tooltip]:not([disabled]).has-tooltip-light.has-tooltip-right::after {
  border-color: transparent #dce0e3 transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-light:before, [data-tooltip]:not(.is-disabled).has-tooltip-light:before, [data-tooltip]:not([disabled]).has-tooltip-light:before {
  background-color: #dce0e3;
  color: rgba(0, 0, 0, 0.7);
}
[data-tooltip]:not(.is-loading).has-tooltip-dark::after, [data-tooltip]:not(.is-disabled).has-tooltip-dark::after, [data-tooltip]:not([disabled]).has-tooltip-dark::after {
  border-color: #303030 transparent transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-dark.has-tooltip-bottom::after, [data-tooltip]:not(.is-disabled).has-tooltip-dark.has-tooltip-bottom::after, [data-tooltip]:not([disabled]).has-tooltip-dark.has-tooltip-bottom::after {
  border-color: transparent transparent #303030 transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-dark.has-tooltip-left::after, [data-tooltip]:not(.is-disabled).has-tooltip-dark.has-tooltip-left::after, [data-tooltip]:not([disabled]).has-tooltip-dark.has-tooltip-left::after {
  border-color: transparent transparent transparent #303030 !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-dark.has-tooltip-right::after, [data-tooltip]:not(.is-disabled).has-tooltip-dark.has-tooltip-right::after, [data-tooltip]:not([disabled]).has-tooltip-dark.has-tooltip-right::after {
  border-color: transparent #303030 transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-dark:before, [data-tooltip]:not(.is-disabled).has-tooltip-dark:before, [data-tooltip]:not([disabled]).has-tooltip-dark:before {
  background-color: #303030;
  color: #fff;
}
[data-tooltip]:not(.is-loading).has-tooltip-primary::after, [data-tooltip]:not(.is-disabled).has-tooltip-primary::after, [data-tooltip]:not([disabled]).has-tooltip-primary::after {
  border-color: #5b90b3 transparent transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-primary.has-tooltip-bottom::after, [data-tooltip]:not(.is-disabled).has-tooltip-primary.has-tooltip-bottom::after, [data-tooltip]:not([disabled]).has-tooltip-primary.has-tooltip-bottom::after {
  border-color: transparent transparent #5b90b3 transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-primary.has-tooltip-left::after, [data-tooltip]:not(.is-disabled).has-tooltip-primary.has-tooltip-left::after, [data-tooltip]:not([disabled]).has-tooltip-primary.has-tooltip-left::after {
  border-color: transparent transparent transparent #5b90b3 !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-primary.has-tooltip-right::after, [data-tooltip]:not(.is-disabled).has-tooltip-primary.has-tooltip-right::after, [data-tooltip]:not([disabled]).has-tooltip-primary.has-tooltip-right::after {
  border-color: transparent #5b90b3 transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-primary:before, [data-tooltip]:not(.is-disabled).has-tooltip-primary:before, [data-tooltip]:not([disabled]).has-tooltip-primary:before {
  background-color: #5b90b3;
  color: #fff;
}
[data-tooltip]:not(.is-loading).has-tooltip-link::after, [data-tooltip]:not(.is-disabled).has-tooltip-link::after, [data-tooltip]:not([disabled]).has-tooltip-link::after {
  border-color: #3398db transparent transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-link.has-tooltip-bottom::after, [data-tooltip]:not(.is-disabled).has-tooltip-link.has-tooltip-bottom::after, [data-tooltip]:not([disabled]).has-tooltip-link.has-tooltip-bottom::after {
  border-color: transparent transparent #3398db transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-link.has-tooltip-left::after, [data-tooltip]:not(.is-disabled).has-tooltip-link.has-tooltip-left::after, [data-tooltip]:not([disabled]).has-tooltip-link.has-tooltip-left::after {
  border-color: transparent transparent transparent #3398db !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-link.has-tooltip-right::after, [data-tooltip]:not(.is-disabled).has-tooltip-link.has-tooltip-right::after, [data-tooltip]:not([disabled]).has-tooltip-link.has-tooltip-right::after {
  border-color: transparent #3398db transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-link:before, [data-tooltip]:not(.is-disabled).has-tooltip-link:before, [data-tooltip]:not([disabled]).has-tooltip-link:before {
  background-color: #3398db;
  color: #fff;
}
[data-tooltip]:not(.is-loading).has-tooltip-info::after, [data-tooltip]:not(.is-disabled).has-tooltip-info::after, [data-tooltip]:not([disabled]).has-tooltip-info::after {
  border-color: #3398db transparent transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-info.has-tooltip-bottom::after, [data-tooltip]:not(.is-disabled).has-tooltip-info.has-tooltip-bottom::after, [data-tooltip]:not([disabled]).has-tooltip-info.has-tooltip-bottom::after {
  border-color: transparent transparent #3398db transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-info.has-tooltip-left::after, [data-tooltip]:not(.is-disabled).has-tooltip-info.has-tooltip-left::after, [data-tooltip]:not([disabled]).has-tooltip-info.has-tooltip-left::after {
  border-color: transparent transparent transparent #3398db !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-info.has-tooltip-right::after, [data-tooltip]:not(.is-disabled).has-tooltip-info.has-tooltip-right::after, [data-tooltip]:not([disabled]).has-tooltip-info.has-tooltip-right::after {
  border-color: transparent #3398db transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-info:before, [data-tooltip]:not(.is-disabled).has-tooltip-info:before, [data-tooltip]:not([disabled]).has-tooltip-info:before {
  background-color: #3398db;
  color: #fff;
}
[data-tooltip]:not(.is-loading).has-tooltip-success::after, [data-tooltip]:not(.is-disabled).has-tooltip-success::after, [data-tooltip]:not([disabled]).has-tooltip-success::after {
  border-color: #4ab873 transparent transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-success.has-tooltip-bottom::after, [data-tooltip]:not(.is-disabled).has-tooltip-success.has-tooltip-bottom::after, [data-tooltip]:not([disabled]).has-tooltip-success.has-tooltip-bottom::after {
  border-color: transparent transparent #4ab873 transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-success.has-tooltip-left::after, [data-tooltip]:not(.is-disabled).has-tooltip-success.has-tooltip-left::after, [data-tooltip]:not([disabled]).has-tooltip-success.has-tooltip-left::after {
  border-color: transparent transparent transparent #4ab873 !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-success.has-tooltip-right::after, [data-tooltip]:not(.is-disabled).has-tooltip-success.has-tooltip-right::after, [data-tooltip]:not([disabled]).has-tooltip-success.has-tooltip-right::after {
  border-color: transparent #4ab873 transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-success:before, [data-tooltip]:not(.is-disabled).has-tooltip-success:before, [data-tooltip]:not([disabled]).has-tooltip-success:before {
  background-color: #4ab873;
  color: #fff;
}
[data-tooltip]:not(.is-loading).has-tooltip-warning::after, [data-tooltip]:not(.is-disabled).has-tooltip-warning::after, [data-tooltip]:not([disabled]).has-tooltip-warning::after {
  border-color: #e8cd20 transparent transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-warning.has-tooltip-bottom::after, [data-tooltip]:not(.is-disabled).has-tooltip-warning.has-tooltip-bottom::after, [data-tooltip]:not([disabled]).has-tooltip-warning.has-tooltip-bottom::after {
  border-color: transparent transparent #e8cd20 transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-warning.has-tooltip-left::after, [data-tooltip]:not(.is-disabled).has-tooltip-warning.has-tooltip-left::after, [data-tooltip]:not([disabled]).has-tooltip-warning.has-tooltip-left::after {
  border-color: transparent transparent transparent #e8cd20 !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-warning.has-tooltip-right::after, [data-tooltip]:not(.is-disabled).has-tooltip-warning.has-tooltip-right::after, [data-tooltip]:not([disabled]).has-tooltip-warning.has-tooltip-right::after {
  border-color: transparent #e8cd20 transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-warning:before, [data-tooltip]:not(.is-disabled).has-tooltip-warning:before, [data-tooltip]:not([disabled]).has-tooltip-warning:before {
  background-color: #e8cd20;
  color: rgba(0, 0, 0, 0.7);
}
[data-tooltip]:not(.is-loading).has-tooltip-danger::after, [data-tooltip]:not(.is-disabled).has-tooltip-danger::after, [data-tooltip]:not([disabled]).has-tooltip-danger::after {
  border-color: #de4444 transparent transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-danger.has-tooltip-bottom::after, [data-tooltip]:not(.is-disabled).has-tooltip-danger.has-tooltip-bottom::after, [data-tooltip]:not([disabled]).has-tooltip-danger.has-tooltip-bottom::after {
  border-color: transparent transparent #de4444 transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-danger.has-tooltip-left::after, [data-tooltip]:not(.is-disabled).has-tooltip-danger.has-tooltip-left::after, [data-tooltip]:not([disabled]).has-tooltip-danger.has-tooltip-left::after {
  border-color: transparent transparent transparent #de4444 !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-danger.has-tooltip-right::after, [data-tooltip]:not(.is-disabled).has-tooltip-danger.has-tooltip-right::after, [data-tooltip]:not([disabled]).has-tooltip-danger.has-tooltip-right::after {
  border-color: transparent #de4444 transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-danger:before, [data-tooltip]:not(.is-disabled).has-tooltip-danger:before, [data-tooltip]:not([disabled]).has-tooltip-danger:before {
  background-color: #de4444;
  color: #fff;
}
[data-tooltip]:not(.is-loading).has-tooltip-orange::after, [data-tooltip]:not(.is-disabled).has-tooltip-orange::after, [data-tooltip]:not([disabled]).has-tooltip-orange::after {
  border-color: #ed9013 transparent transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-orange.has-tooltip-bottom::after, [data-tooltip]:not(.is-disabled).has-tooltip-orange.has-tooltip-bottom::after, [data-tooltip]:not([disabled]).has-tooltip-orange.has-tooltip-bottom::after {
  border-color: transparent transparent #ed9013 transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-orange.has-tooltip-left::after, [data-tooltip]:not(.is-disabled).has-tooltip-orange.has-tooltip-left::after, [data-tooltip]:not([disabled]).has-tooltip-orange.has-tooltip-left::after {
  border-color: transparent transparent transparent #ed9013 !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-orange.has-tooltip-right::after, [data-tooltip]:not(.is-disabled).has-tooltip-orange.has-tooltip-right::after, [data-tooltip]:not([disabled]).has-tooltip-orange.has-tooltip-right::after {
  border-color: transparent #ed9013 transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-orange:before, [data-tooltip]:not(.is-disabled).has-tooltip-orange:before, [data-tooltip]:not([disabled]).has-tooltip-orange:before {
  background-color: #ed9013;
  color: findColorInvert(#ed9013);
}
[data-tooltip]:not(.is-loading).has-tooltip-green::after, [data-tooltip]:not(.is-disabled).has-tooltip-green::after, [data-tooltip]:not([disabled]).has-tooltip-green::after {
  border-color: #809c58 transparent transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-green.has-tooltip-bottom::after, [data-tooltip]:not(.is-disabled).has-tooltip-green.has-tooltip-bottom::after, [data-tooltip]:not([disabled]).has-tooltip-green.has-tooltip-bottom::after {
  border-color: transparent transparent #809c58 transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-green.has-tooltip-left::after, [data-tooltip]:not(.is-disabled).has-tooltip-green.has-tooltip-left::after, [data-tooltip]:not([disabled]).has-tooltip-green.has-tooltip-left::after {
  border-color: transparent transparent transparent #809c58 !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-green.has-tooltip-right::after, [data-tooltip]:not(.is-disabled).has-tooltip-green.has-tooltip-right::after, [data-tooltip]:not([disabled]).has-tooltip-green.has-tooltip-right::after {
  border-color: transparent #809c58 transparent transparent !important;
}
[data-tooltip]:not(.is-loading).has-tooltip-green:before, [data-tooltip]:not(.is-disabled).has-tooltip-green:before, [data-tooltip]:not([disabled]).has-tooltip-green:before {
  background-color: #809c58;
  color: findColorInvert(#809c58);
}
[data-tooltip]:not(.is-loading):hover::before, [data-tooltip]:not(.is-loading):hover::after, [data-tooltip]:not(.is-loading).has-tooltip-active::before, [data-tooltip]:not(.is-loading).has-tooltip-active::after, [data-tooltip]:not(.is-disabled):hover::before, [data-tooltip]:not(.is-disabled):hover::after, [data-tooltip]:not(.is-disabled).has-tooltip-active::before, [data-tooltip]:not(.is-disabled).has-tooltip-active::after, [data-tooltip]:not([disabled]):hover::before, [data-tooltip]:not([disabled]):hover::after, [data-tooltip]:not([disabled]).has-tooltip-active::before, [data-tooltip]:not([disabled]).has-tooltip-active::after {
  opacity: 1;
  visibility: visible;
}
[data-tooltip]:not(.is-loading).has-tooltip-fade::before, [data-tooltip]:not(.is-loading).has-tooltip-fade::after, [data-tooltip]:not(.is-disabled).has-tooltip-fade::before, [data-tooltip]:not(.is-disabled).has-tooltip-fade::after, [data-tooltip]:not([disabled]).has-tooltip-fade::before, [data-tooltip]:not([disabled]).has-tooltip-fade::after {
  transition: opacity 0.3s linear, visibility 0.3s linear;
}
@media screen and (max-width: 768px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-mobile.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-top-mobile.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-top-mobile.has-tooltip-arrow::after {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    margin-top: -5px;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: -5px;
    border-color: #4a4a4a transparent transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-top-mobile::before, [data-tooltip]:not(.is-disabled).has-tooltip-top-mobile::before, [data-tooltip]:not([disabled]).has-tooltip-top-mobile::before {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    top: 0;
    margin-top: -5px;
    margin-bottom: auto;
    transform: translate(-50%, -100%);
  }
}
@media screen and (min-width: 769px), print {
  [data-tooltip]:not(.is-loading).has-tooltip-top-tablet.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-top-tablet.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-top-tablet.has-tooltip-arrow::after {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    margin-top: -5px;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: -5px;
    border-color: #4a4a4a transparent transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-top-tablet::before, [data-tooltip]:not(.is-disabled).has-tooltip-top-tablet::before, [data-tooltip]:not([disabled]).has-tooltip-top-tablet::before {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    top: 0;
    margin-top: -5px;
    margin-bottom: auto;
    transform: translate(-50%, -100%);
  }
}
@media screen and (min-width: 769px) and (max-width: 1149px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-tablet-only.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-top-tablet-only.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-top-tablet-only.has-tooltip-arrow::after {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    margin-top: -5px;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: -5px;
    border-color: #4a4a4a transparent transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-top-tablet-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-top-tablet-only::before, [data-tooltip]:not([disabled]).has-tooltip-top-tablet-only::before {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    top: 0;
    margin-top: -5px;
    margin-bottom: auto;
    transform: translate(-50%, -100%);
  }
}
@media screen and (max-width: 1149px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-touch.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-top-touch.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-top-touch.has-tooltip-arrow::after {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    margin-top: -5px;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: -5px;
    border-color: #4a4a4a transparent transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-top-touch::before, [data-tooltip]:not(.is-disabled).has-tooltip-top-touch::before, [data-tooltip]:not([disabled]).has-tooltip-top-touch::before {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    top: 0;
    margin-top: -5px;
    margin-bottom: auto;
    transform: translate(-50%, -100%);
  }
}
@media screen and (min-width: 1150px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-desktop.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-top-desktop.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-top-desktop.has-tooltip-arrow::after {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    margin-top: -5px;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: -5px;
    border-color: #4a4a4a transparent transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-top-desktop::before, [data-tooltip]:not(.is-disabled).has-tooltip-top-desktop::before, [data-tooltip]:not([disabled]).has-tooltip-top-desktop::before {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    top: 0;
    margin-top: -5px;
    margin-bottom: auto;
    transform: translate(-50%, -100%);
  }
}
@media screen and (min-width: 1150px) and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-desktop-only.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-top-desktop-only.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-top-desktop-only.has-tooltip-arrow::after {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    margin-top: -5px;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: -5px;
    border-color: #4a4a4a transparent transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-top-desktop-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-top-desktop-only::before, [data-tooltip]:not([disabled]).has-tooltip-top-desktop-only::before {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    top: 0;
    margin-top: -5px;
    margin-bottom: auto;
    transform: translate(-50%, -100%);
  }
}
@media screen and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-until-widescreen.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-top-until-widescreen.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-top-until-widescreen.has-tooltip-arrow::after {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    margin-top: -5px;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: -5px;
    border-color: #4a4a4a transparent transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-top-until-widescreen::before, [data-tooltip]:not(.is-disabled).has-tooltip-top-until-widescreen::before, [data-tooltip]:not([disabled]).has-tooltip-top-until-widescreen::before {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    top: 0;
    margin-top: -5px;
    margin-bottom: auto;
    transform: translate(-50%, -100%);
  }
}
@media screen and (min-width: 1216px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-widescreen.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-top-widescreen.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-top-widescreen.has-tooltip-arrow::after {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    margin-top: -5px;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: -5px;
    border-color: #4a4a4a transparent transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-top-widescreen::before, [data-tooltip]:not(.is-disabled).has-tooltip-top-widescreen::before, [data-tooltip]:not([disabled]).has-tooltip-top-widescreen::before {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    top: 0;
    margin-top: -5px;
    margin-bottom: auto;
    transform: translate(-50%, -100%);
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-widescreen-only.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-top-widescreen-only.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-top-widescreen-only.has-tooltip-arrow::after {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    margin-top: -5px;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: -5px;
    border-color: #4a4a4a transparent transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-top-widescreen-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-top-widescreen-only::before, [data-tooltip]:not([disabled]).has-tooltip-top-widescreen-only::before {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    top: 0;
    margin-top: -5px;
    margin-bottom: auto;
    transform: translate(-50%, -100%);
  }
}
@media screen and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-until-fullhd.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-top-until-fullhd.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-top-until-fullhd.has-tooltip-arrow::after {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    margin-top: -5px;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: -5px;
    border-color: #4a4a4a transparent transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-top-until-fullhd::before, [data-tooltip]:not(.is-disabled).has-tooltip-top-until-fullhd::before, [data-tooltip]:not([disabled]).has-tooltip-top-until-fullhd::before {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    top: 0;
    margin-top: -5px;
    margin-bottom: auto;
    transform: translate(-50%, -100%);
  }
}
@media screen and (min-width: 1408px) {
  [data-tooltip]:not(.is-loading).has-tooltip-top-fullhd.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-top-fullhd.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-top-fullhd.has-tooltip-arrow::after {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    margin-top: -5px;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: -5px;
    border-color: #4a4a4a transparent transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-top-fullhd::before, [data-tooltip]:not(.is-disabled).has-tooltip-top-fullhd::before, [data-tooltip]:not([disabled]).has-tooltip-top-fullhd::before {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    top: 0;
    margin-top: -5px;
    margin-bottom: auto;
    transform: translate(-50%, -100%);
  }
}
@media screen and (max-width: 768px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-mobile.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-right-mobile.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-right-mobile.has-tooltip-arrow::after {
    top: auto;
    right: 0;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    margin-right: -6px;
    margin-bottom: -6px;
    margin-left: auto;
    border-color: transparent #4a4a4a transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-right-mobile::before, [data-tooltip]:not(.is-disabled).has-tooltip-right-mobile::before, [data-tooltip]:not([disabled]).has-tooltip-right-mobile::before {
    top: auto;
    right: -5px;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    transform: translate(100%, 50%);
  }
}
@media screen and (min-width: 769px), print {
  [data-tooltip]:not(.is-loading).has-tooltip-right-tablet.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-right-tablet.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-right-tablet.has-tooltip-arrow::after {
    top: auto;
    right: 0;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    margin-right: -6px;
    margin-bottom: -6px;
    margin-left: auto;
    border-color: transparent #4a4a4a transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-right-tablet::before, [data-tooltip]:not(.is-disabled).has-tooltip-right-tablet::before, [data-tooltip]:not([disabled]).has-tooltip-right-tablet::before {
    top: auto;
    right: -5px;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    transform: translate(100%, 50%);
  }
}
@media screen and (min-width: 769px) and (max-width: 1149px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-tablet-only.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-right-tablet-only.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-right-tablet-only.has-tooltip-arrow::after {
    top: auto;
    right: 0;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    margin-right: -6px;
    margin-bottom: -6px;
    margin-left: auto;
    border-color: transparent #4a4a4a transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-right-tablet-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-right-tablet-only::before, [data-tooltip]:not([disabled]).has-tooltip-right-tablet-only::before {
    top: auto;
    right: -5px;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    transform: translate(100%, 50%);
  }
}
@media screen and (max-width: 1149px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-touch.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-right-touch.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-right-touch.has-tooltip-arrow::after {
    top: auto;
    right: 0;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    margin-right: -6px;
    margin-bottom: -6px;
    margin-left: auto;
    border-color: transparent #4a4a4a transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-right-touch::before, [data-tooltip]:not(.is-disabled).has-tooltip-right-touch::before, [data-tooltip]:not([disabled]).has-tooltip-right-touch::before {
    top: auto;
    right: -5px;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    transform: translate(100%, 50%);
  }
}
@media screen and (min-width: 1150px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-desktop.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-right-desktop.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-right-desktop.has-tooltip-arrow::after {
    top: auto;
    right: 0;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    margin-right: -6px;
    margin-bottom: -6px;
    margin-left: auto;
    border-color: transparent #4a4a4a transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-right-desktop::before, [data-tooltip]:not(.is-disabled).has-tooltip-right-desktop::before, [data-tooltip]:not([disabled]).has-tooltip-right-desktop::before {
    top: auto;
    right: -5px;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    transform: translate(100%, 50%);
  }
}
@media screen and (min-width: 1150px) and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-desktop-only.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-right-desktop-only.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-right-desktop-only.has-tooltip-arrow::after {
    top: auto;
    right: 0;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    margin-right: -6px;
    margin-bottom: -6px;
    margin-left: auto;
    border-color: transparent #4a4a4a transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-right-desktop-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-right-desktop-only::before, [data-tooltip]:not([disabled]).has-tooltip-right-desktop-only::before {
    top: auto;
    right: -5px;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    transform: translate(100%, 50%);
  }
}
@media screen and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-until-widescreen.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-right-until-widescreen.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-right-until-widescreen.has-tooltip-arrow::after {
    top: auto;
    right: 0;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    margin-right: -6px;
    margin-bottom: -6px;
    margin-left: auto;
    border-color: transparent #4a4a4a transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-right-until-widescreen::before, [data-tooltip]:not(.is-disabled).has-tooltip-right-until-widescreen::before, [data-tooltip]:not([disabled]).has-tooltip-right-until-widescreen::before {
    top: auto;
    right: -5px;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    transform: translate(100%, 50%);
  }
}
@media screen and (min-width: 1216px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-widescreen.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-right-widescreen.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-right-widescreen.has-tooltip-arrow::after {
    top: auto;
    right: 0;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    margin-right: -6px;
    margin-bottom: -6px;
    margin-left: auto;
    border-color: transparent #4a4a4a transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-right-widescreen::before, [data-tooltip]:not(.is-disabled).has-tooltip-right-widescreen::before, [data-tooltip]:not([disabled]).has-tooltip-right-widescreen::before {
    top: auto;
    right: -5px;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    transform: translate(100%, 50%);
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-widescreen-only.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-right-widescreen-only.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-right-widescreen-only.has-tooltip-arrow::after {
    top: auto;
    right: 0;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    margin-right: -6px;
    margin-bottom: -6px;
    margin-left: auto;
    border-color: transparent #4a4a4a transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-right-widescreen-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-right-widescreen-only::before, [data-tooltip]:not([disabled]).has-tooltip-right-widescreen-only::before {
    top: auto;
    right: -5px;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    transform: translate(100%, 50%);
  }
}
@media screen and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-until-fullhd.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-right-until-fullhd.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-right-until-fullhd.has-tooltip-arrow::after {
    top: auto;
    right: 0;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    margin-right: -6px;
    margin-bottom: -6px;
    margin-left: auto;
    border-color: transparent #4a4a4a transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-right-until-fullhd::before, [data-tooltip]:not(.is-disabled).has-tooltip-right-until-fullhd::before, [data-tooltip]:not([disabled]).has-tooltip-right-until-fullhd::before {
    top: auto;
    right: -5px;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    transform: translate(100%, 50%);
  }
}
@media screen and (min-width: 1408px) {
  [data-tooltip]:not(.is-loading).has-tooltip-right-fullhd.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-right-fullhd.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-right-fullhd.has-tooltip-arrow::after {
    top: auto;
    right: 0;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    margin-right: -6px;
    margin-bottom: -6px;
    margin-left: auto;
    border-color: transparent #4a4a4a transparent transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-right-fullhd::before, [data-tooltip]:not(.is-disabled).has-tooltip-right-fullhd::before, [data-tooltip]:not([disabled]).has-tooltip-right-fullhd::before {
    top: auto;
    right: -5px;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    transform: translate(100%, 50%);
  }
}
@media screen and (max-width: 768px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-mobile.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-mobile.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-bottom-mobile.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: -1px;
    left: 50%;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -5px;
    margin-left: -5px;
    border-color: transparent transparent #4a4a4a transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-mobile::before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-mobile::before, [data-tooltip]:not([disabled]).has-tooltip-bottom-mobile::before {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    margin-top: auto;
    margin-bottom: -5px;
    transform: translate(-50%, 100%);
  }
}
@media screen and (min-width: 769px), print {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-tablet.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-tablet.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-bottom-tablet.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: -1px;
    left: 50%;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -5px;
    margin-left: -5px;
    border-color: transparent transparent #4a4a4a transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-tablet::before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-tablet::before, [data-tooltip]:not([disabled]).has-tooltip-bottom-tablet::before {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    margin-top: auto;
    margin-bottom: -5px;
    transform: translate(-50%, 100%);
  }
}
@media screen and (min-width: 769px) and (max-width: 1149px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-tablet-only.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-tablet-only.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-bottom-tablet-only.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: -1px;
    left: 50%;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -5px;
    margin-left: -5px;
    border-color: transparent transparent #4a4a4a transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-tablet-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-tablet-only::before, [data-tooltip]:not([disabled]).has-tooltip-bottom-tablet-only::before {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    margin-top: auto;
    margin-bottom: -5px;
    transform: translate(-50%, 100%);
  }
}
@media screen and (max-width: 1149px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-touch.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-touch.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-bottom-touch.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: -1px;
    left: 50%;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -5px;
    margin-left: -5px;
    border-color: transparent transparent #4a4a4a transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-touch::before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-touch::before, [data-tooltip]:not([disabled]).has-tooltip-bottom-touch::before {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    margin-top: auto;
    margin-bottom: -5px;
    transform: translate(-50%, 100%);
  }
}
@media screen and (min-width: 1150px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-desktop.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-desktop.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-bottom-desktop.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: -1px;
    left: 50%;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -5px;
    margin-left: -5px;
    border-color: transparent transparent #4a4a4a transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-desktop::before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-desktop::before, [data-tooltip]:not([disabled]).has-tooltip-bottom-desktop::before {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    margin-top: auto;
    margin-bottom: -5px;
    transform: translate(-50%, 100%);
  }
}
@media screen and (min-width: 1150px) and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-desktop-only.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-desktop-only.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-bottom-desktop-only.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: -1px;
    left: 50%;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -5px;
    margin-left: -5px;
    border-color: transparent transparent #4a4a4a transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-desktop-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-desktop-only::before, [data-tooltip]:not([disabled]).has-tooltip-bottom-desktop-only::before {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    margin-top: auto;
    margin-bottom: -5px;
    transform: translate(-50%, 100%);
  }
}
@media screen and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-until-widescreen.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-until-widescreen.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-bottom-until-widescreen.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: -1px;
    left: 50%;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -5px;
    margin-left: -5px;
    border-color: transparent transparent #4a4a4a transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-until-widescreen::before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-until-widescreen::before, [data-tooltip]:not([disabled]).has-tooltip-bottom-until-widescreen::before {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    margin-top: auto;
    margin-bottom: -5px;
    transform: translate(-50%, 100%);
  }
}
@media screen and (min-width: 1216px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-widescreen.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-widescreen.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-bottom-widescreen.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: -1px;
    left: 50%;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -5px;
    margin-left: -5px;
    border-color: transparent transparent #4a4a4a transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-widescreen::before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-widescreen::before, [data-tooltip]:not([disabled]).has-tooltip-bottom-widescreen::before {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    margin-top: auto;
    margin-bottom: -5px;
    transform: translate(-50%, 100%);
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-widescreen-only.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-widescreen-only.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-bottom-widescreen-only.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: -1px;
    left: 50%;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -5px;
    margin-left: -5px;
    border-color: transparent transparent #4a4a4a transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-widescreen-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-widescreen-only::before, [data-tooltip]:not([disabled]).has-tooltip-bottom-widescreen-only::before {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    margin-top: auto;
    margin-bottom: -5px;
    transform: translate(-50%, 100%);
  }
}
@media screen and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-until-fullhd.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-until-fullhd.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-bottom-until-fullhd.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: -1px;
    left: 50%;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -5px;
    margin-left: -5px;
    border-color: transparent transparent #4a4a4a transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-until-fullhd::before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-until-fullhd::before, [data-tooltip]:not([disabled]).has-tooltip-bottom-until-fullhd::before {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    margin-top: auto;
    margin-bottom: -5px;
    transform: translate(-50%, 100%);
  }
}
@media screen and (min-width: 1408px) {
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-fullhd.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-fullhd.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-bottom-fullhd.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: -1px;
    left: 50%;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -5px;
    margin-left: -5px;
    border-color: transparent transparent #4a4a4a transparent;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-bottom-fullhd::before, [data-tooltip]:not(.is-disabled).has-tooltip-bottom-fullhd::before, [data-tooltip]:not([disabled]).has-tooltip-bottom-fullhd::before {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    margin-top: auto;
    margin-bottom: -5px;
    transform: translate(-50%, 100%);
  }
}
@media screen and (max-width: 768px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-mobile.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-left-mobile.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-left-mobile.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: 50%;
    left: 0;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -6px;
    margin-left: -5px;
    border-color: transparent transparent transparent #4a4a4a;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-left-mobile::before, [data-tooltip]:not(.is-disabled).has-tooltip-left-mobile::before, [data-tooltip]:not([disabled]).has-tooltip-left-mobile::before {
    top: auto;
    right: auto;
    bottom: 50%;
    left: -5px;
    transform: translate(-100%, 50%);
  }
}
@media screen and (min-width: 769px), print {
  [data-tooltip]:not(.is-loading).has-tooltip-left-tablet.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-left-tablet.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-left-tablet.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: 50%;
    left: 0;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -6px;
    margin-left: -5px;
    border-color: transparent transparent transparent #4a4a4a;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-left-tablet::before, [data-tooltip]:not(.is-disabled).has-tooltip-left-tablet::before, [data-tooltip]:not([disabled]).has-tooltip-left-tablet::before {
    top: auto;
    right: auto;
    bottom: 50%;
    left: -5px;
    transform: translate(-100%, 50%);
  }
}
@media screen and (min-width: 769px) and (max-width: 1149px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-tablet-only.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-left-tablet-only.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-left-tablet-only.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: 50%;
    left: 0;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -6px;
    margin-left: -5px;
    border-color: transparent transparent transparent #4a4a4a;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-left-tablet-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-left-tablet-only::before, [data-tooltip]:not([disabled]).has-tooltip-left-tablet-only::before {
    top: auto;
    right: auto;
    bottom: 50%;
    left: -5px;
    transform: translate(-100%, 50%);
  }
}
@media screen and (max-width: 1149px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-touch.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-left-touch.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-left-touch.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: 50%;
    left: 0;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -6px;
    margin-left: -5px;
    border-color: transparent transparent transparent #4a4a4a;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-left-touch::before, [data-tooltip]:not(.is-disabled).has-tooltip-left-touch::before, [data-tooltip]:not([disabled]).has-tooltip-left-touch::before {
    top: auto;
    right: auto;
    bottom: 50%;
    left: -5px;
    transform: translate(-100%, 50%);
  }
}
@media screen and (min-width: 1150px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-desktop.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-left-desktop.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-left-desktop.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: 50%;
    left: 0;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -6px;
    margin-left: -5px;
    border-color: transparent transparent transparent #4a4a4a;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-left-desktop::before, [data-tooltip]:not(.is-disabled).has-tooltip-left-desktop::before, [data-tooltip]:not([disabled]).has-tooltip-left-desktop::before {
    top: auto;
    right: auto;
    bottom: 50%;
    left: -5px;
    transform: translate(-100%, 50%);
  }
}
@media screen and (min-width: 1150px) and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-desktop-only.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-left-desktop-only.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-left-desktop-only.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: 50%;
    left: 0;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -6px;
    margin-left: -5px;
    border-color: transparent transparent transparent #4a4a4a;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-left-desktop-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-left-desktop-only::before, [data-tooltip]:not([disabled]).has-tooltip-left-desktop-only::before {
    top: auto;
    right: auto;
    bottom: 50%;
    left: -5px;
    transform: translate(-100%, 50%);
  }
}
@media screen and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-until-widescreen.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-left-until-widescreen.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-left-until-widescreen.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: 50%;
    left: 0;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -6px;
    margin-left: -5px;
    border-color: transparent transparent transparent #4a4a4a;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-left-until-widescreen::before, [data-tooltip]:not(.is-disabled).has-tooltip-left-until-widescreen::before, [data-tooltip]:not([disabled]).has-tooltip-left-until-widescreen::before {
    top: auto;
    right: auto;
    bottom: 50%;
    left: -5px;
    transform: translate(-100%, 50%);
  }
}
@media screen and (min-width: 1216px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-widescreen.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-left-widescreen.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-left-widescreen.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: 50%;
    left: 0;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -6px;
    margin-left: -5px;
    border-color: transparent transparent transparent #4a4a4a;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-left-widescreen::before, [data-tooltip]:not(.is-disabled).has-tooltip-left-widescreen::before, [data-tooltip]:not([disabled]).has-tooltip-left-widescreen::before {
    top: auto;
    right: auto;
    bottom: 50%;
    left: -5px;
    transform: translate(-100%, 50%);
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-widescreen-only.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-left-widescreen-only.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-left-widescreen-only.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: 50%;
    left: 0;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -6px;
    margin-left: -5px;
    border-color: transparent transparent transparent #4a4a4a;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-left-widescreen-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-left-widescreen-only::before, [data-tooltip]:not([disabled]).has-tooltip-left-widescreen-only::before {
    top: auto;
    right: auto;
    bottom: 50%;
    left: -5px;
    transform: translate(-100%, 50%);
  }
}
@media screen and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-until-fullhd.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-left-until-fullhd.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-left-until-fullhd.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: 50%;
    left: 0;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -6px;
    margin-left: -5px;
    border-color: transparent transparent transparent #4a4a4a;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-left-until-fullhd::before, [data-tooltip]:not(.is-disabled).has-tooltip-left-until-fullhd::before, [data-tooltip]:not([disabled]).has-tooltip-left-until-fullhd::before {
    top: auto;
    right: auto;
    bottom: 50%;
    left: -5px;
    transform: translate(-100%, 50%);
  }
}
@media screen and (min-width: 1408px) {
  [data-tooltip]:not(.is-loading).has-tooltip-left-fullhd.has-tooltip-arrow::after, [data-tooltip]:not(.is-disabled).has-tooltip-left-fullhd.has-tooltip-arrow::after, [data-tooltip]:not([disabled]).has-tooltip-left-fullhd.has-tooltip-arrow::after {
    top: auto;
    right: auto;
    bottom: 50%;
    left: 0;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: -6px;
    margin-left: -5px;
    border-color: transparent transparent transparent #4a4a4a;
  }
  [data-tooltip]:not(.is-loading).has-tooltip-left-fullhd::before, [data-tooltip]:not(.is-disabled).has-tooltip-left-fullhd::before, [data-tooltip]:not([disabled]).has-tooltip-left-fullhd::before {
    top: auto;
    right: auto;
    bottom: 50%;
    left: -5px;
    transform: translate(-100%, 50%);
  }
}
@media screen and (max-width: 768px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-mobile::after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-mobile::before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-mobile::after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-mobile::before, [data-tooltip]:not([disabled]).has-tooltip-hidden-mobile::after, [data-tooltip]:not([disabled]).has-tooltip-hidden-mobile::before {
    opacity: 0 !important;
    display: none !important;
  }
}
@media screen and (min-width: 769px), print {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-tablet::after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-tablet::before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-tablet::after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-tablet::before, [data-tooltip]:not([disabled]).has-tooltip-hidden-tablet::after, [data-tooltip]:not([disabled]).has-tooltip-hidden-tablet::before {
    opacity: 0 !important;
    display: none !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1149px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-tablet-only::after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-tablet-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-tablet-only::after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-tablet-only::before, [data-tooltip]:not([disabled]).has-tooltip-hidden-tablet-only::after, [data-tooltip]:not([disabled]).has-tooltip-hidden-tablet-only::before {
    opacity: 0 !important;
    display: none !important;
  }
}
@media screen and (max-width: 1149px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-touch::after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-touch::before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-touch::after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-touch::before, [data-tooltip]:not([disabled]).has-tooltip-hidden-touch::after, [data-tooltip]:not([disabled]).has-tooltip-hidden-touch::before {
    opacity: 0 !important;
    display: none !important;
  }
}
@media screen and (min-width: 1150px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-desktop::after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-desktop::before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-desktop::after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-desktop::before, [data-tooltip]:not([disabled]).has-tooltip-hidden-desktop::after, [data-tooltip]:not([disabled]).has-tooltip-hidden-desktop::before {
    opacity: 0 !important;
    display: none !important;
  }
}
@media screen and (min-width: 1150px) and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-desktop-only::after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-desktop-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-desktop-only::after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-desktop-only::before, [data-tooltip]:not([disabled]).has-tooltip-hidden-desktop-only::after, [data-tooltip]:not([disabled]).has-tooltip-hidden-desktop-only::before {
    opacity: 0 !important;
    display: none !important;
  }
}
@media screen and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-until-widescreen::after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-until-widescreen::before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-until-widescreen::after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-until-widescreen::before, [data-tooltip]:not([disabled]).has-tooltip-hidden-until-widescreen::after, [data-tooltip]:not([disabled]).has-tooltip-hidden-until-widescreen::before {
    opacity: 0 !important;
    display: none !important;
  }
}
@media screen and (min-width: 1216px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-widescreen::after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-widescreen::before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-widescreen::after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-widescreen::before, [data-tooltip]:not([disabled]).has-tooltip-hidden-widescreen::after, [data-tooltip]:not([disabled]).has-tooltip-hidden-widescreen::before {
    opacity: 0 !important;
    display: none !important;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-widescreen-only::after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-widescreen-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-widescreen-only::after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-widescreen-only::before, [data-tooltip]:not([disabled]).has-tooltip-hidden-widescreen-only::after, [data-tooltip]:not([disabled]).has-tooltip-hidden-widescreen-only::before {
    opacity: 0 !important;
    display: none !important;
  }
}
@media screen and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-until-fullhd::after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-until-fullhd::before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-until-fullhd::after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-until-fullhd::before, [data-tooltip]:not([disabled]).has-tooltip-hidden-until-fullhd::after, [data-tooltip]:not([disabled]).has-tooltip-hidden-until-fullhd::before {
    opacity: 0 !important;
    display: none !important;
  }
}
@media screen and (min-width: 1408px) {
  [data-tooltip]:not(.is-loading).has-tooltip-hidden-fullhd::after, [data-tooltip]:not(.is-loading).has-tooltip-hidden-fullhd::before, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-fullhd::after, [data-tooltip]:not(.is-disabled).has-tooltip-hidden-fullhd::before, [data-tooltip]:not([disabled]).has-tooltip-hidden-fullhd::after, [data-tooltip]:not([disabled]).has-tooltip-hidden-fullhd::before {
    opacity: 0 !important;
    display: none !important;
  }
}
@media screen and (max-width: 768px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-mobile::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-mobile::before, [data-tooltip]:not([disabled]).has-tooltip-text-left-mobile::before {
    text-align: left;
  }
}
@media screen and (min-width: 769px), print {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-tablet::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-tablet::before, [data-tooltip]:not([disabled]).has-tooltip-text-left-tablet::before {
    text-align: left;
  }
}
@media screen and (min-width: 769px) and (max-width: 1149px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-tablet-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-tablet-only::before, [data-tooltip]:not([disabled]).has-tooltip-text-left-tablet-only::before {
    text-align: left;
  }
}
@media screen and (max-width: 1149px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-touch::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-touch::before, [data-tooltip]:not([disabled]).has-tooltip-text-left-touch::before {
    text-align: left;
  }
}
@media screen and (min-width: 1150px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-desktop::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-desktop::before, [data-tooltip]:not([disabled]).has-tooltip-text-left-desktop::before {
    text-align: left;
  }
}
@media screen and (min-width: 1150px) and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-desktop-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-desktop-only::before, [data-tooltip]:not([disabled]).has-tooltip-text-left-desktop-only::before {
    text-align: left;
  }
}
@media screen and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-until-widescreen::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-until-widescreen::before, [data-tooltip]:not([disabled]).has-tooltip-text-left-until-widescreen::before {
    text-align: left;
  }
}
@media screen and (min-width: 1216px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-widescreen::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-widescreen::before, [data-tooltip]:not([disabled]).has-tooltip-text-left-widescreen::before {
    text-align: left;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-widescreen-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-widescreen-only::before, [data-tooltip]:not([disabled]).has-tooltip-text-left-widescreen-only::before {
    text-align: left;
  }
}
@media screen and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-until-fullhd::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-until-fullhd::before, [data-tooltip]:not([disabled]).has-tooltip-text-left-until-fullhd::before {
    text-align: left;
  }
}
@media screen and (min-width: 1408px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-left-fullhd::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-left-fullhd::before, [data-tooltip]:not([disabled]).has-tooltip-text-left-fullhd::before {
    text-align: left;
  }
}
@media screen and (max-width: 768px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-mobile::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-mobile::before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-mobile::before {
    text-align: center;
  }
}
@media screen and (min-width: 769px), print {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-tablet::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-tablet::before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-tablet::before {
    text-align: center;
  }
}
@media screen and (min-width: 769px) and (max-width: 1149px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-tablet-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-tablet-only::before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-tablet-only::before {
    text-align: center;
  }
}
@media screen and (max-width: 1149px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-touch::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-touch::before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-touch::before {
    text-align: center;
  }
}
@media screen and (min-width: 1150px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-desktop::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-desktop::before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-desktop::before {
    text-align: center;
  }
}
@media screen and (min-width: 1150px) and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-desktop-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-desktop-only::before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-desktop-only::before {
    text-align: center;
  }
}
@media screen and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-until-widescreen::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-until-widescreen::before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-until-widescreen::before {
    text-align: center;
  }
}
@media screen and (min-width: 1216px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-widescreen::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-widescreen::before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-widescreen::before {
    text-align: center;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-widescreen-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-widescreen-only::before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-widescreen-only::before {
    text-align: center;
  }
}
@media screen and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-until-fullhd::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-until-fullhd::before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-until-fullhd::before {
    text-align: center;
  }
}
@media screen and (min-width: 1408px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-centered-fullhd::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-centered-fullhd::before, [data-tooltip]:not([disabled]).has-tooltip-text-centered-fullhd::before {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-mobile::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-mobile::before, [data-tooltip]:not([disabled]).has-tooltip-text-right-mobile::before {
    text-align: right;
  }
}
@media screen and (min-width: 769px), print {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-tablet::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-tablet::before, [data-tooltip]:not([disabled]).has-tooltip-text-right-tablet::before {
    text-align: right;
  }
}
@media screen and (min-width: 769px) and (max-width: 1149px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-tablet-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-tablet-only::before, [data-tooltip]:not([disabled]).has-tooltip-text-right-tablet-only::before {
    text-align: right;
  }
}
@media screen and (max-width: 1149px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-touch::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-touch::before, [data-tooltip]:not([disabled]).has-tooltip-text-right-touch::before {
    text-align: right;
  }
}
@media screen and (min-width: 1150px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-desktop::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-desktop::before, [data-tooltip]:not([disabled]).has-tooltip-text-right-desktop::before {
    text-align: right;
  }
}
@media screen and (min-width: 1150px) and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-desktop-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-desktop-only::before, [data-tooltip]:not([disabled]).has-tooltip-text-right-desktop-only::before {
    text-align: right;
  }
}
@media screen and (max-width: 1215px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-until-widescreen::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-until-widescreen::before, [data-tooltip]:not([disabled]).has-tooltip-text-right-until-widescreen::before {
    text-align: right;
  }
}
@media screen and (min-width: 1216px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-widescreen::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-widescreen::before, [data-tooltip]:not([disabled]).has-tooltip-text-right-widescreen::before {
    text-align: right;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-widescreen-only::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-widescreen-only::before, [data-tooltip]:not([disabled]).has-tooltip-text-right-widescreen-only::before {
    text-align: right;
  }
}
@media screen and (max-width: 1407px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-until-fullhd::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-until-fullhd::before, [data-tooltip]:not([disabled]).has-tooltip-text-right-until-fullhd::before {
    text-align: right;
  }
}
@media screen and (min-width: 1408px) {
  [data-tooltip]:not(.is-loading).has-tooltip-text-right-fullhd::before, [data-tooltip]:not(.is-disabled).has-tooltip-text-right-fullhd::before, [data-tooltip]:not([disabled]).has-tooltip-text-right-fullhd::before {
    text-align: right;
  }
}

span[data-tooltip] {
  border-bottom: 1px dashed hsl(0, 0%, 86%);
}
span[data-tooltip].has-tooltip-white {
  border-bottom-color: white;
}
span[data-tooltip].has-tooltip-black {
  border-bottom-color: #171717;
}
span[data-tooltip].has-tooltip-light {
  border-bottom-color: #eaedee;
}
span[data-tooltip].has-tooltip-dark {
  border-bottom-color: #3d3d3d;
}
span[data-tooltip].has-tooltip-primary {
  border-bottom-color: #6c9cbb;
}
span[data-tooltip].has-tooltip-link {
  border-bottom-color: #49a3df;
}
span[data-tooltip].has-tooltip-info {
  border-bottom-color: #49a3df;
}
span[data-tooltip].has-tooltip-success {
  border-bottom-color: #5cbf81;
}
span[data-tooltip].has-tooltip-warning {
  border-bottom-color: #ead237;
}
span[data-tooltip].has-tooltip-danger {
  border-bottom-color: #e25a5a;
}
span[data-tooltip].has-tooltip-orange {
  border-bottom-color: #ef9b2b;
}
span[data-tooltip].has-tooltip-green {
  border-bottom-color: #8da865;
}

.control span[data-tooltip] {
  border-bottom: none;
}

.card-header-title {
  color: #fff;
  font-weight: bold;
  font-size: 1.25em;
}
.card-header-title i.fa {
  margin-right: 5px;
}

.card-header-icon a,
.card-header-icon a:hover {
  color: #fff;
}

.card-body {
  padding: 0 0.5rem;
}

.table td,
.table th {
  border: none;
}
.table thead {
  border-bottom: 1px dashed #bebebe;
}
.table tfoot tr:first-child td,
.table tfoot tr:first-child th,
.table tfoot tr:last-child td,
.table tfoot tr:last-child th {
  border-top: 1px dashed #bebebe !important;
}
.table .gift-card {
  border-bottom: 1px dashed #bebebe;
}
.table .gift-card td {
  color: #ed9013;
}

.subtotal-row {
  border-top: 1px dashed #bebebe;
  border-bottom: 1px dashed #bebebe;
}

.remove-test-tag {
  color: #f72668;
  font-size: 0.8rem;
  cursor: pointer;
  font-weight: 700;
}

.test-name-small {
  font-size: 0.8rem;
}

.is-loading {
  position: relative;
  pointer-events: none;
  opacity: 0.5;
}
.is-loading:after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid hsl(0, 0%, 86%);
  border-radius: 9999px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
  position: absolute;
  top: calc(50% - 2.5em);
  left: calc(50% - 2.5em);
  width: 5em;
  height: 5em;
  border-width: 0.25em;
}

.notification {
  background-color: #b4127f;
  color: #fff;
  font-size: 0.9rem;
  padding: 1rem 1.6rem;
  border-radius: 0.5rem;
}

.cart-products-container-table {
  width: 100%;
}

.cart-overflow {
  max-height: 250px;
  overflow-y: auto;
}

[data-tooltip] {
  border-bottom-width: 0 !important;
}

.tooltipContainer {
  margin-left: 0.5rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
