import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '@services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class BetterLabGuard {
  constructor(private router: Router, private storageService: StorageService) {}

  /**
   * Determines if the better lab page should be loaded.
   */
  canActivate(): boolean {
    if (!!this.storageService.transactionId && this.storageService.addressComplete && this.storageService.betterLab) {
      return true;
    }

    this.router.navigate(['order-address.php']);

    return false;
  }
}
