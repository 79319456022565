// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.ng-invalid.ng-touched, input.expired-card,
textarea.ng-invalid.ng-touched,
textarea.expired-card,
select.ng-invalid.ng-touched,
select.expired-card {
  background-color: #fceeee;
  outline: 1px solid #de4444;
}

.error {
  color: #de4444;
  font-weight: bold;
  font-size: 0.75rem;
  margin-top: 0.5em;
}
.error a {
  color: #de4444;
  text-decoration: underline;
}

.details-text-area {
  height: 75px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
