import { environment } from '@environments/environment';
import { healthlabsCommonEnvironment } from '@environments/healthlabs/environment.common';

/**
 * Environment variables for use on the HealthLabs site, in production environment.
 */
export const healthlabsEnvironment = {
  ...environment,
  ...healthlabsCommonEnvironment,
  googleMapApiKey: 'AIzaSyD_zZ4q14IkLoRIvw1S-QXpLuCO_g3QDpc',
  apiUrl: 'https://www.healthlabs.com/simpleCart',
  siteUrls: {
    home: 'https://www.healthlabs.com',
    termsOfService: 'https://www.healthlabs.com/terms-of-service',
    myAccount: 'https://my-account.healthlabs.com',
    tests: 'https://www.healthlabs.com',
    notFound: 'https://www.healthlabs.com/404',
  },
  siteId: '4',
};
