import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneFormat',
})
export class PhoneFormatPipe implements PipeTransform {
  transform(phone: string): string {
    return phone.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
  }
}
