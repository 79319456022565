import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { HttpClient } from '@angular/common/http';
import { Result } from '@Medology/ng-findalab';
import { Test } from '@models/test';
import { map } from 'rxjs/operators';
import { StorageService } from '@services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class PscService {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    protected http: HttpClient,
    protected storage: StorageService
  ) {}

  /**
   * Check if a lab supports the given tests.
   *
   * @param {number}   psc_id   The id of the PSC.
   * @param {number[]} test_ids The ids of the tests.
   */
  verifyTestsAreAvailableAtLab(psc_id: number, test_ids: number[]): Observable<Result[]> {
    return this.http.get<Result[]>(`${this.config.analyteCareApi}/api/v1/lab`, {
      params: { 'tests[]': test_ids, lab_id: psc_id },
    });
  }

  /**
   * Get the list of networks that support the given tests.
   */
  getTestNetworks() {
    if (!(this.storage.tests?.length > 0)) {
      return of([]);
    }

    return this.http
      .get<{ name: string }[]>(`${this.config.analyteCareApi}/api/v1/networks`, {
        params: this.getTestsAsParams(),
      })
      .pipe(map((networks) => networks.map((network) => network.name)));
  }

  /**
   * Get the list of labs that support the given tests.
   */
  protected getTestsAsParams(): { [key: string]: string } {
    const params = { 'tests[key]': 'id' };

    this.storage.tests.forEach((test: Test, index: number) => {
      params[`tests[values][${index}]`] = test.id;
    });

    return params;
  }
}
