import { CouponTypes } from '@enums/coupon-types';
import { CouponResponse } from '@models/coupon-response';

export class Coupon {
  coupon_name: string;
  coupon_code: string;
  coupon_amount: number;
  coupon_type: CouponTypes;
  deleted_at: Date;
  value: number;

  constructor(coupon: CouponResponse, subtotal: number) {
    this.coupon_name = coupon.coupon_name;
    this.coupon_code = coupon.coupon_code;
    this.coupon_type = coupon.coupon_type;
    this.coupon_amount = Number(coupon.coupon_value);
    this.value = this.getCouponValue(subtotal);
    this.deleted_at = null;
  }

  /**
   * Gets the coupon's dollar amount.
   *
   * @param {number} subtotal the subtotal for the order
   */
  private getCouponValue(subtotal: number): number {
    if (this.coupon_type === CouponTypes.Percentage) {
      return Math.floor(this.getDiscountAmountInCents(subtotal) / 100);
    }

    return this.coupon_amount;
  }

  /**
   * Gets the percentage discount total in cents.
   *
   * @param {number} orderSubtotal the subtotal for the order
   */
  private getDiscountAmountInCents(orderSubtotal: number): number {
    return (this.coupon_amount / 100) * orderSubtotal;
  }
}
