import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormHelper } from '@common/form-helper';
import { FormService } from '@services/form.service';
import { ConsultationTypes } from '@enums/consultation-types';

@Component({
  selector: 'app-consultation-request-consultation-type',
  templateUrl: './consultation-type.component.html',
  styleUrls: ['./consultation-type.component.scss'],
})
export class ConsultationTypeComponent extends FormHelper implements OnInit {
  consultationTypeForm: FormGroup;

  constructor(private formService: FormService) {
    super();
  }

  ngOnInit(): void {
    this.consultationTypeForm = this.formService.consultationRequest.get('type') as FormGroup;
    this.setDynamicPatientInfoFormValidators();
  }

  /**
   * Determines if a form control is invalid.
   *
   * @param {string} controlName The name of the form control to check for validity.
   */
  invalidControl(controlName: string): boolean {
    return this.isInvalid(this.consultationTypeForm.get(controlName));
  }

  /**
   * Sets up dynamic validators.
   */
  private setDynamicPatientInfoFormValidators(): void {
    const consultationTypeForm = this.consultationTypeForm;
  }

  /**
   * Get the list of consultation Types
   */
  get consultationTypes(): { value: string; key: string }[] {
    return Object.entries(ConsultationTypes).map(([key, value]) => ({ key, value }));
  }
}
