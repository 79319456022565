import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPhoneInput]',
})
export class PhoneInputDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (
      [
        'Backspace',
        'Shift',
        'Control',
        'Alt',
        'ArrowLeft',
        'ArrowUp',
        'ArrowRight',
        'ArrowDown',
        'Delete',
        'Meta',
      ].includes(event.key)
    )
      return;

    this.maskInput();
  }

  @HostListener('paste')
  onPaste() {
    this.maskInput();
  }

  @HostListener('cut')
  onCut() {
    this.maskInput();
  }

  @HostListener('blur')
  onBlur() {
    this.maskInput();
  }

  private maskInput() {
    let city, number;
    let value = this.el.nativeElement.value.replace(/[^0-9]/g, '');

    if (!value.length) {
      this.el.nativeElement.value = '';
      return;
    }

    switch (value.length) {
      case 1:
      case 2:
      case 3:
        city = value;
        break;

      default:
        city = value.slice(0, 3);
        number = value.slice(3);
    }

    this.el.nativeElement.value = number
      ? `(${city}) ${number.length > 3 ? number.slice(0, 3) + '-' + number.slice(3, 7) : number}`
      : `(${city})`;

    this.el.nativeElement.dispatchEvent(new Event('input'));
  }
}
