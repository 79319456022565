import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import * as Sentry from '@sentry/browser';
import { ErrorHandlerService } from '@services/error-handler.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class ExternalPaymentMethodService {
  protected paymentMethodInstance: any;

  protected constructor(protected errorHandlerService: ErrorHandlerService) {}

  /**
   * Checks if the external payment method is loaded and available.
   */
  abstract get isAvailable(): boolean;

  /**
   * Gets the global object for the external payment method when available.
   */
  protected get globalObject(): any {
    return null;
  }

  /**
   * Use the external payment method to charge the user by provided amount.
   *
   * @param {number} amount the amount to charge the user
   */
  abstract pay(amount: number): Promise<string>;

  /**
   * Render the external payment button.
   *
   * @param {HTMLElement} wrapper the optional element that will append the button.
   */
  abstract renderPaymentButton(wrapper?: HTMLElement): Observable<void>;

  /**
   * Validates if the external payment is set.
   *
   * @param {string} nonce the nonce value to validate
   */
  validateNonce(nonce: string): void {
    if (!nonce) {
      Sentry.captureException(new Error(`No nonce available to ${this.constructor.name}`));
      throw new Error(this.errorHandlerService.defaultErrorMessage);
    }
  }

  /**
   * Sets the nonce value for the external payment method to the form control.
   *
   * @param {AbstractControl} paymentMethodControl the form control for the payment method
   * @param {string} nonce the nonce value to set
   */
  setNonceToForm(paymentMethodControl: AbstractControl, nonce: string): void {
    this.validateNonce(nonce);
    paymentMethodControl.get('nonce').setValue(nonce);
  }
}
