import { ConsultationRequestError } from '@errors/consultation-request-error';

export class ConsultationRequestOrderCanceledError extends ConsultationRequestError {
  constructor(homeUrl: string, domain: string) {
    super(
      'This link is no longer valid. Your order has been canceled. ' +
        `If you need to request a new consultation, please place a new order at <a href="${homeUrl}">${domain}</a>.`
    );
  }
}
