import { Upsell } from '@models/upsell';
import { PaymentTypes } from '@enums/payment-types';
import { ChargeTypes } from '@enums/charge-types';

/**
 * Represents a request object used to store an upsell on an order.
 */
export class UpsellStoreRequest {
  upsell: Upsell | Upsell[];
  charge_type?: ChargeTypes;
  card_number?: string;
  card_exp?: Date;
  billing_zip_code?: string;
  card_holder?: string;
  cvv?: string;
  invoice?: string;
  charge_amount?: number;
  nonce?: string;

  constructor(
    upsell: Upsell | Upsell[],
    paymentType: PaymentTypes,
    paymentFormValues?: any,
    creditCardHolder?: string
  ) {
    this.upsell = upsell;
    if (paymentType) {
      this.charge_type = this.getChargeType(paymentType);
    }

    if (paymentFormValues) {
      this.parsePaymentInfo(paymentFormValues);
    }

    if (creditCardHolder && this.charge_type === ChargeTypes['Credit Card']) {
      this.card_holder = creditCardHolder;
    }
  }

  /**
   * Returns the charge type based on the payment type
   *
   * @param {PaymentTypes} paymentType The payment type
   */
  private getChargeType(paymentType: PaymentTypes): ChargeTypes {
    const chargeType = ChargeTypes[paymentType];
    if (!chargeType) {
      throw new Error(`Charge type not allowed for payment type: ${paymentType}`);
    }

    return chargeType;
  }

  /**
   * Parses the payment info based on the payment type, setting the correct properties to be sent to the backend.
   *
   * @param paymentFormValues The payment form values
   */
  private parsePaymentInfo(paymentFormValues: any): void {
    switch (paymentFormValues.method) {
      case PaymentTypes.CreditCard:
        this.setCreditCardInfo(paymentFormValues.creditCard);

        break;
      case PaymentTypes.ApplePay:
      case PaymentTypes.GooglePay:
      case PaymentTypes.Venmo:
      case PaymentTypes.Paypal:
        this.nonce = paymentFormValues.nonce;

        break;
      case PaymentTypes.BitPay:
        this.invoice = paymentFormValues.bitpay.invoice;
        this.charge_amount = paymentFormValues.bitpay.amount;

        break;
      case PaymentTypes.PayLater:
        this.charge_type = ChargeTypes.payLater;

        break;
    }
  }

  /**
   * Sets credit card information to the request.
   *
   * @param creditCardInfo The credit info from the payment form
   */
  private setCreditCardInfo(creditCardInfo: any): void {
    this.charge_type = ChargeTypes[PaymentTypes.CreditCard];
    this.card_number = creditCardInfo.cardNumber;
    this.card_exp = new Date(creditCardInfo.cardYear, +creditCardInfo.cardMonth - 1);
    this.billing_zip_code = creditCardInfo.billingZipCode;
    this.cvv = creditCardInfo.cvv;
  }
}
