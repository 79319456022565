// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.ng-invalid.ng-touched, input.expired-card,
textarea.ng-invalid.ng-touched,
textarea.expired-card,
select.ng-invalid.ng-touched,
select.expired-card {
  background-color: #fceeee;
  outline: 1px solid #de4444;
}

.error {
  color: #de4444;
  font-weight: bold;
  font-size: 0.75rem;
  margin-top: 0.5em;
}
.error a {
  color: #de4444;
  text-decoration: underline;
}

.consultation-request-container {
  padding: 0 0.625rem;
}
@media screen and (min-width: 769px) {
  .consultation-request-container {
    padding: 0 0.9375rem;
  }
}

.has-text-weight-semibold h1 {
  font-weight: 600;
}

app-stdcheck-layout .main-content {
  padding: 0;
}
app-stdcheck-layout .consultation-request div.primary.title {
  padding: 1.5rem 0 0.5rem;
  background: linear-gradient(rgba(161, 191, 211, 0.5), rgba(230, 238, 244, 0.5)), url('bg-chevron.f9a6cfe3aed8c769.png');
  background-color: #a1bfd3;
  border-bottom: 1px solid #c9cbcc;
  margin-bottom: -1px;
}
@media screen and (max-width: 768px) {
  app-stdcheck-layout .consultation-request div.primary.title {
    padding-bottom: 1rem;
  }
}
app-stdcheck-layout .consultation-request h1 {
  text-shadow: 1px 1px 3px #fff;
  line-height: 1;
  margin: 0;
  font-size: 1.5rem;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: bold;
  color: #15232d;
  padding: 0 0.625rem;
}
@media screen and (min-width: 769px), print {
  app-stdcheck-layout .consultation-request h1 {
    font-size: 2.25rem;
    padding: 0 0.9375rem;
  }
}
app-stdcheck-layout .terms-background {
  background-color: #f2dac3;
}

app-healthlabs-layout .consultation-request div.primary.title {
  padding: 1.5rem 0 0.5rem;
  color: #416e8b;
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  app-healthlabs-layout .consultation-request div.primary.title {
    padding-bottom: 1rem;
  }
}
app-healthlabs-layout .consultation-request div.primary.title h1 {
  font-weight: bolder;
  font-size: 1.5rem;
  padding: 0 0.9375rem;
}
@media screen and (min-width: 769px), print {
  app-healthlabs-layout .consultation-request div.primary.title h1 {
    font-size: 2.25rem;
  }
}
app-healthlabs-layout .steps-containers {
  max-width: 100% !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
