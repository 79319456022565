import { Injectable } from '@angular/core';
import { GoogleMapLoadService } from '@Medology/ng-findalab';

@Injectable({
  providedIn: 'root',
})
/**
 * A custom service for loading the the google map API to include specific libraries
 */
export class MapsLoadService extends GoogleMapLoadService {
  protected requiredMapLibraries: string[] = ['maps', 'places', 'drawing', 'marker'];
}
