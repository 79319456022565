// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-stdcheck-layout app-embedded-order-summary .card {
  box-shadow: none;
}
app-stdcheck-layout app-embedded-order-summary .card .card-header {
  padding: 1em;
}
app-stdcheck-layout app-embedded-order-summary .card .card-header .card-header-title {
  font-weight: bold;
  font-size: 1.25em;
}
app-stdcheck-layout app-embedded-order-summary .table td,
app-stdcheck-layout app-embedded-order-summary .table th {
  border: none;
  padding-left: 0;
}
app-stdcheck-layout app-embedded-order-summary .table thead {
  border-bottom: 1px dashed #bebebe;
}
app-stdcheck-layout app-embedded-order-summary .table tfoot {
  border-top: 1px dashed #bebebe;
}

app-healthlabs-layout app-embedded-order-summary .card {
  border: 1px solid #fcae84;
  overflow: hidden;
  border-radius: 6px;
}
app-healthlabs-layout app-embedded-order-summary .card .card-header {
  background: transparent;
  box-shadow: none;
  overflow: hidden;
}
app-healthlabs-layout app-embedded-order-summary .card .card-header .card-header-title {
  color: #f05906 !important;
  font-size: 1.1rem !important;
  text-transform: uppercase;
  font-weight: 800;
  padding: 0.3em 0.5em !important;
  background: linear-gradient(#fdcfb6, #f9f9f9);
}
app-healthlabs-layout app-embedded-order-summary .card .card-body {
  background: #f9f9f9 !important;
}
app-healthlabs-layout app-embedded-order-summary .card .card-body table {
  background: #fff;
  border: 1px solid #dddddd;
  border-collapse: separate;
  border-spacing: 2px;
}
app-healthlabs-layout app-embedded-order-summary .card .card-body table thead,
app-healthlabs-layout app-embedded-order-summary .card .card-body table tfoot {
  background: #f0f0f0;
}
app-healthlabs-layout app-embedded-order-summary .card .card-body table thead tr td,
app-healthlabs-layout app-embedded-order-summary .card .card-body table thead tr th,
app-healthlabs-layout app-embedded-order-summary .card .card-body table tfoot tr td,
app-healthlabs-layout app-embedded-order-summary .card .card-body table tfoot tr th {
  font-weight: 800;
}
app-healthlabs-layout app-embedded-order-summary .card .card-body table tr td,
app-healthlabs-layout app-embedded-order-summary .card .card-body table tr th {
  color: #222222;
  font-size: 0.875rem;
  padding: 0.5rem 0.625rem 0.625rem;
  border: 0;
}
app-healthlabs-layout app-embedded-order-summary .card .card-body table tr td:last-child,
app-healthlabs-layout app-embedded-order-summary .card .card-body table tr th:last-child {
  text-align: right;
  font-style: italic;
}
app-healthlabs-layout app-embedded-order-summary .card .card-body table tr:nth-child(2) td,
app-healthlabs-layout app-embedded-order-summary .card .card-body table tr:nth-child(2) th {
  background: #f9f9f9;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
