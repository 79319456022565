// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
@media screen and (max-width: 768px) {
  app-card .card-header.is-size-4-mobile {
    font-size: 1.25rem !important;
  }
}
app-card .card-header.numbered-header::before {
  counter-increment: section;
  content: counter(section) ". ";
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
