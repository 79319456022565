import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { BraintreeService } from '@services/external-payments/braintree.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private braintreeService: BraintreeService,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  ngOnInit(): void {
    this.setSiteFavicon();
    this.titleService.setTitle(this.config.titles.order);
    this.braintreeService.initializeDeviceData();
  }

  /**
   * Set site favicon dynamically for HealthLabs, STDCheck, TreatMyUTI.com or Starfish.
   */
  private setSiteFavicon(): void {
    this.document.getElementById('appFavicon').setAttribute('href', `assets/img/${this.config.faviconFile}`);
  }
}
