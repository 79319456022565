// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  padding: 0 !important;
}

@media screen and (max-width: 768px) {
  .is-fullwidth-mobile {
    width: 100% !important;
  }
}
.change-location-button-column {
  min-height: 76px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
