import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PartialResultsDeliveryResponse } from '@models/partial-results-delivery-response';
import { StorageService } from '@services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class PartialResultsDeliveryService {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private storageService: StorageService
  ) {}

  /**
   * Get the Partial Results Delivery content.
   *
   * @returns an Observable of the PartialResultsDeliveryResponse
   */
  getPartialResultsDeliveryContent(): Observable<PartialResultsDeliveryResponse> {
    return this.http
      .get(`${this.config.stdcheckPage}/json-partial-results-delivery`, { responseType: 'text' })
      .pipe(map((responseText) => JSON.parse(responseText)));
  }

  /**
   * Determines if the user should be redirected to the partial results delivery page.
   */
  shouldRedirectToPartialResultsDelivery(): boolean {
    const orderedTests = this.storageService.tests;

    if (orderedTests.length > 1) {
      return true;
    }

    return orderedTests.some((test) => test.type === 'Panel');
  }
}
