// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button.animated-left {
  overflow: hidden;
  border: none;
}
.button.animated-left:hover:after {
  left: -3rem;
  transform: rotate(0deg);
}
.button.animated-left:after {
  transition: all 500ms;
  content: " ";
  display: block;
  background-color: rgba(255, 255, 255, 0.3);
  width: 2.4rem;
  height: 200%;
  position: absolute;
  top: -10px;
  left: -5px;
  transform: rotate(-20deg);
}
.button.animated-right {
  overflow: hidden;
  border: none;
}
.button.animated-right:hover:after {
  right: -3.2rem;
  transform: rotate(0deg);
}
.button.animated-right:after {
  transition: all 500ms;
  content: " ";
  display: block;
  background-color: rgba(255, 255, 255, 0.3);
  width: 3.2rem;
  height: 200%;
  position: absolute;
  top: -10px;
  right: -10px;
  transform: rotate(20deg);
}
.button.medium-button, .button.large-button {
  height: 2.3rem;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0 1.2rem;
}
.button.large-button {
  font-size: 1rem;
  height: 2.9rem;
}

.right-align-text {
  padding-right: 1rem;
}

.left-align-text {
  padding-left: 1rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
