export class Phone {
  number: string;
  leaveMsg: boolean;
  sendSms: boolean;

  constructor(number: string, leaveMsg: boolean, sendSms: boolean) {
    this.number = number;
    this.leaveMsg = leaveMsg;
    this.sendSms = sendSms;
  }
}
