import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Month } from '@common/month';
import { Months } from '@common/months';
import { FormHelper } from '@common/form-helper';
import types from 'creditcards-types';
import Card from 'creditcards/card';
import { CreditCards } from '@common/credit-cards';
import { FormService } from '@services/form.service';
import { cardNumberValidator } from '@common/card-number-validator';
import { CreditCard } from '@common/credit-card';
import { OrderService } from '@services/order.service';
import { BillingCountry } from '@enums/billing-countries';
import { PaymentTypes } from '@enums/payment-types';
import { Recharge } from '@models/recharge';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { PayService } from '@services/external-payments/pay.service';
import { PaymentMethodOption } from '@models/payments/payment-method-option';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss'],
})
export class PaymentInfoComponent extends FormHelper implements OnInit, AfterViewInit, AfterContentChecked {
  months: Month[] = Months;
  years: number[];
  rechargeDisplayName: string;
  checkBalanceLoading: boolean = false;
  giftCardBalance: number;
  siteName: string = this.config.domain;

  @Input() paymentForm: FormGroup;
  @Input() disabledPaymentMethods: PaymentTypes[] = [PaymentTypes.Free, PaymentTypes.GiftCard];
  @Input() recharge: Recharge;
  @Input() isSecondary: boolean = false;
  @Output() giftCardApplied = new EventEmitter<void>();
  @Output() giftCardRemoved = new EventEmitter<void>();

  paymentMethodOptions: PaymentMethodOption[] = [];
  PaymentTypes: typeof PaymentTypes = PaymentTypes;

  /**
   * Lists of countries that we need to ask for zipcode or not
   */
  countryZipTypes = {
    US: { name: 'USA', value: BillingCountry.UnitedStates, label: 'Zip Code' },
    CA: { name: 'Canada', value: BillingCountry.Canada, label: 'Post Code' },
    UK: { name: 'UK', value: BillingCountry.UnitedKingdom, label: 'Postcode' },
    other: { name: 'Other', value: BillingCountry.Other },
  };

  /**
   * Check in the order service if any gift card has been applied.
   */
  get isGiftCardApplied(): boolean {
    return !!this.orderService.giftCardApplied;
  }

  /**
   * Get the payment method form control.
   */
  get method(): AbstractControl {
    return this.paymentForm.get('method');
  }

  /**
   * Get the credit card number form control.
   */
  get cardNumber(): AbstractControl {
    return this.paymentForm.get('creditCard.cardNumber');
  }

  /**
   * Get the billing country form control.
   */
  get billingCountry(): AbstractControl {
    return this.paymentForm.get('creditCard.billingCountry');
  }

  /**
   * Get the billing zip code form control.
   */
  get billingZipCode(): AbstractControl {
    return this.paymentForm.get('creditCard.billingZipCode');
  }

  /**
   * Get the credit card expiration month form control.
   */
  get cardMonth(): AbstractControl {
    return this.paymentForm.get('creditCard.cardMonth');
  }

  /**
   * Get the credit card expiration year form control.
   */
  get cardYear(): AbstractControl {
    return this.paymentForm.get('creditCard.cardYear');
  }

  /**
   * Gets the gift card code control.
   */
  get giftCardCode(): AbstractControl {
    return this.paymentForm.get('giftCardCode');
  }

  /**
   * Get the credit card icon for the current card.
   */
  get cardIcon(): string {
    const creditCard = this.creditCardEntity();

    return creditCard ? creditCard.icon : 'far fa-credit-card';
  }

  /**
   * Get the billing zip code form control.
   */
  get cvv(): AbstractControl {
    return this.paymentForm.get('creditCard.cvv');
  }

  constructor(
    private formService: FormService,
    private orderService: OrderService,
    private payService: PayService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    super();
  }

  /**
   * Initialize the component.
   */
  ngOnInit(): void {
    this.syncAvailablePaymentOptions();
    const currentYear = new Date().getUTCFullYear();
    this.years = this.getListOfNumbers(currentYear, currentYear + 20);

    this.method.valueChanges.subscribe((method) => {
      this.removeGiftCard();
      this.addDynamicValidators(method !== 'recharge');
      this.formService.$paymentMethodObservable.next(this.method.value);
      this.changeDetectorRef.detectChanges();
    });
    this.billingCountry.valueChanges.subscribe(() => this.updateCountryZip());
  }

  /**
   * Run after Angular initializes the component's
   */
  ngAfterViewInit() {
    this.setRechargeDisplayName();
  }

  /**
   * Run after changes have been made
   */
  ngAfterContentChecked() {
    this.syncAvailablePaymentOptions();
  }

  /**
   * Dynamically sets the validators for payment fields based on the payment type.
   */
  addDynamicValidators(enableCreditCardValidator: boolean): void {
    // Reset and set credit card form control
    this.updateCountryZip();
    const creditCardForm = this.paymentForm.get('creditCard');
    ['cardNumber', 'cardMonth', 'cardYear'].forEach((controlName: string) => {
      const control = creditCardForm.get(controlName);
      this.formService.resetControl(control);
      if (enableCreditCardValidator && this.method.value === PaymentTypes.CreditCard) {
        this.formService.setValidator(control, this.getCreditCardControlValidators(controlName));
      }

      control.updateValueAndValidity();
    });

    this.formService.resetControl(this.giftCardCode);
    if (this.method.value === PaymentTypes.GiftCard) {
      this.formService.setValidator(this.giftCardCode, [Validators.required]);
    }

    // Reset and set Braintree form control
    this.formService.resetControl(this.paymentForm.get('nonce'));
    if (
      [PaymentTypes.ApplePay, PaymentTypes.GooglePay, PaymentTypes.Paypal, PaymentTypes.Venmo].includes(
        this.method.value
      )
    ) {
      this.formService.setValidator(this.paymentForm.get('nonce'), [Validators.required]);
    }

    // Reset and set BitPay form control's
    ['invoice', 'amount'].forEach((controlName) => {
      this.formService.resetControl(this.paymentForm.get('bitpay').get(controlName));
      if (this.method.value === PaymentTypes.BitPay) {
        this.formService.setValidator(this.paymentForm.get('bitpay').get(controlName), [Validators.required]);
      }
    });
  }

  /**
   * Update zipcode validations when the country change.
   */
  updateCountryZip(): void {
    this.billingZipCode.reset();
    this.billingZipCode.clearValidators();

    if (this.method.value === 'Credit Card' && this.billingCountry.value !== this.countryZipTypes.other.value) {
      this.billingZipCode.setValidators([Validators.required]);
      if (this.billingCountry.value === this.countryZipTypes.US.value) {
        this.billingZipCode.addValidators(Validators.pattern(/^\d{5}$/));
      } else {
        this.billingZipCode.addValidators(Validators.pattern(/^[a-zA-Z0-9\s]*$/));
      }
    }

    this.billingZipCode.updateValueAndValidity();
  }

  /**
   * Sync available payment options
   */
  syncAvailablePaymentOptions(): void {
    const paymentOptions = this.payService.getPaymentMethodOptions(this.disabledPaymentMethods, this.recharge);

    if (JSON.stringify(this.paymentMethodOptions) !== JSON.stringify(paymentOptions)) {
      this.paymentMethodOptions = paymentOptions;
    }
  }

  /**
   * Gets the Validators for a credit card FormControl.
   *
   * @param {string} controlName the name of the credit card form control
   */
  getCreditCardControlValidators(controlName: string): ValidatorFn[] {
    let validators = [Validators.required];

    switch (controlName) {
      case 'cardNumber':
        validators.push(cardNumberValidator());
        break;
    }

    return validators;
  }

  /**
   * Check if the credit card is expired.
   */
  isCardExpired(): boolean {
    return this.paymentForm.get('creditCard').errors?.expired;
  }

  /**
   * Return the index of the credit card typed from the Credit cards array .
   */
  creditCardIndex(): number {
    const type = Card(types).type(this.cardNumber.value, true);
    return CreditCards.findIndex((card) => card.type === type);
  }

  /**
   * Return credit card cvv help the image that need's to be used.
   */
  creditCardCvvImage(): string {
    return this.creditCardEntity()?.tag === 'amex' ? 'amex' : 'mastercard';
  }

  /**
   * Return the Credit card object.
   */
  creditCardEntity(): CreditCard {
    return CreditCards[this.creditCardIndex()];
  }

  /**
   * Assign validators to the cvv form control depending on the selected credit card.
   */
  setCvvValidator(): void {
    this.cardNumber.markAsTouched();
    if (this.isInvalid(this.cardNumber)) {
      return;
    }

    const cvvFormControl = this.paymentForm.get('creditCard').get('cvv');
    const cvvLength = this.creditCardEntity()?.cvv_length;
    cvvFormControl.clearValidators();
    const validators = [Validators.required, Validators.pattern('^[0-9]*$')];
    if (cvvLength) {
      validators.push(Validators.minLength(cvvLength));
      validators.push(Validators.maxLength(cvvLength));
    }

    cvvFormControl.setValidators(validators);
    cvvFormControl.updateValueAndValidity();
  }

  /**
   * If the recharge information is available, set the recharge display name
   * and select recharge as the payment method.
   */
  setRechargeDisplayName(): void {
    if (!this.recharge) {
      return;
    }

    this.method.setValue('recharge');
  }

  /**
   * Gets a gift card's balance.
   */
  checkGiftCardBalance(): void {
    if (this.giftCardCode.invalid) {
      this.giftCardCode.markAsTouched();

      return;
    }

    this.checkBalanceLoading = true;
    this.orderService.checkGiftCardBalance(this.giftCardCode.value).subscribe(
      (response) => {
        this.checkBalanceLoading = false;
        if (response.balance <= 0) {
          this.giftCardCode.setErrors({ noBalance: true });

          return;
        }
        this.giftCardBalance = response.balance;
      },
      (err) => {
        switch (err.error.code) {
          case 404:
            this.giftCardCode.setErrors({ doesNotExist: true });

            break;
          default:
            this.giftCardCode.setErrors({ serverError: true });

            break;
        }
        this.checkBalanceLoading = false;
      }
    );
  }

  /**
   * Applies the gift card.
   */
  applyGiftCard(): void {
    this.orderService.applyGiftCard(this.giftCardBalance);
    this.giftCardApplied.emit();
  }

  /**
   * Clear gift card field and balance if user clicks 'Use a different gift card' button.
   */
  useDifferentGiftCard(): void {
    this.removeGiftCard();
    this.addDynamicValidators(false);
  }

  /**
   * Reset the gift card balance and its form.
   */
  removeGiftCard(): void {
    this.giftCardBalance = null;
    this.giftCardCode.reset();
    this.giftCardRemoved.emit();
  }

  /**
   * Get the message for the billing zip code pattern error depending on the country selected.
   */
  billingZipCodePatternErrorMessage(): string {
    const label: string = this.countryZipTypes[this.billingCountry.value].label.toLowerCase();

    return (
      `The billing ${label} ` +
      (this.billingCountry.value === this.countryZipTypes.US.value ? `should be 5 digits` : `is not valid.`)
    );
  }
}
