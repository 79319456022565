import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Question } from '@models/dynamic-forms/question';
import { DynamicFormsService } from '@services/dynamic-forms.service';
import { Subscription } from 'rxjs';
import { DynamicFormQuestionComponent } from '@components/dynamic-forms/dynamic-form-question/dynamic-form-question.component';
import { NgForOf } from '@angular/common';
import { ExternalData } from '@models/dynamic-forms/external-data';
import { SessionStorageService } from '@services/session-storage.service';
import { TextInputComponent } from '@components/dynamic-forms/text-input/text-input.component';

@Component({
  selector: 'app-dynamic-questionnaire-form',
  templateUrl: './dynamic-questionnaire-form.component.html',
  standalone: true,
  imports: [DynamicFormQuestionComponent, NgForOf, TextInputComponent],
})
export class DynamicQuestionnaireFormComponent implements OnInit, OnDestroy {
  @Input() sessionKey: string;
  @Input() questionIds: string[];
  @Input() externalData: ExternalData;

  form: FormGroup;
  questions: Question[] = [];

  private subscriptions: Subscription[] = [];

  constructor(private dynamicFormsService: DynamicFormsService, private sessionStorage: SessionStorageService) {}

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.dynamicFormsService.getQuestions(this.questionIds).then((questions) => {
      this.questions = questions;
      this.setAnswersFromStorage();
      this.form = this.dynamicFormsService.toFormGroup(this.questions);
      this.subscribeToFormChanges();
    });
  }

  /**
   * Unsubscribes from all current subscriptions.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Loads answers from storage and assigns them to questions.
   */
  private setAnswersFromStorage(): void {
    const answers = this.sessionStorage.getQuestionnaireAnswers(this.sessionKey);
    if (!answers) {
      return;
    }

    this.questions.forEach((question) => (question.value = answers[question.id]));
  }

  /**
   * Subscribes to form changes and updates stored answers.
   */
  private subscribeToFormChanges(): void {
    this.subscriptions.push(
      this.form.valueChanges.subscribe((value) => this.sessionStorage.setQuestionnaireAnswers(this.sessionKey, value))
    );
  }
}
