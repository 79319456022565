import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Scrolls smoothly to the bottom of the page.
   */
  scrollToBottom(): void {
    this.document.documentElement.scrollTo({
      top: this.document.body.scrollHeight,
      behavior: 'smooth',
    });
  }
}
