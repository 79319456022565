// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
  .radio + .radio {
    margin-left: unset !important;
  }
}
.payment-section.section {
  padding-top: 0;
  padding-bottom: 1rem;
}
.payment-section.section app-upsell-payment-info .primary-card-header {
  display: none;
}
.payment-section.section .container {
  max-width: 50rem !important;
}
.payment-section.section .card {
  padding: unset;
  border: none;
  box-shadow: none;
}
.payment-section.section .card .card-header {
  font-size: 1.25rem !important;
}
.payment-section.section app-embedded-order-summary .card {
  border: solid 1px rgba(19, 21, 22, 0.25);
}
.payment-section.section .button-place-your-order {
  font-size: 1rem;
}

app-starfish-layout .payment-section.section app-upsell-payment-info app-card .card {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}
app-starfish-layout .payment-section.section app-upsell-payment-info app-card .card app-embedded-order-summary .card-header {
  padding: 8px !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
