import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '@common/form-helper';
import { FormService } from '@services/form.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-height-weight-input',
  templateUrl: './height-weight-input.component.html',
  styleUrls: ['./height-weight-input.component.scss'],
})
export class HeightWeightInputComponent extends FormHelper implements OnInit {
  @Input() consultationForm: FormGroup;
  @Input() groupName: string = '';
  @Input()
  set dob(value: Date | null) {
    this._dob = value;
    this.adjustHeightAndWeightValidators();
  }

  formGroup: FormGroup;
  private _dob: Date | null;

  constructor(private formService: FormService, private cdRef: ChangeDetectorRef) {
    super();
  }

  /**
   * Initialize the component and subscribe to birthday changes.
   */
  ngOnInit(): void {
    this.formGroup = (this.consultationForm?.get(this.groupName) as FormGroup) ?? this.consultationForm;
    if (this.formGroup) {
      this.adjustHeightAndWeightValidators();
    }
  }

  /**
   * Determines if a form control is invalid.
   */
  isControlInvalid(controlName: string): boolean {
    return this.isInvalid(this.formGroup.get(controlName));
  }

  /**
   * Determines if the patient requires height and weight fields to be filled out.
   */
  get patientRequiresHeightAndWeight() {
    return this._dob ? this.calculatePatientAge(this._dob) <= 19 : false;
  }

  /**
   * Adjust the validators for the height and weight fields based on the patient's age.
   */
  private adjustHeightAndWeightValidators(): void {
    if (!this.formGroup) {
      return;
    }

    const controlsToCheck = ['height_feet', 'height_inches', 'weight'];

    const action = this.patientRequiresHeightAndWeight
      ? (control: string) => this.formGroup.get(control).addValidators(Validators.required)
      : (control: string) => this.resetControlAndValue(this.formGroup.get(control));

    controlsToCheck.forEach(action);
    this.cdRef.detectChanges();
  }

  /**
   * Reset the control validators and value.
   *
   * @param control The form control
   */
  private resetControlAndValue(control: AbstractControl): void {
    control.reset();
    this.formService.resetControl(control);
  }

  /**
   * Generates the error messages for a given form control.
   *
   * @param controlName The form control name
   * @param friendlyName The user-facing name of the form control
   */
  generateErrorMessages(controlName: string, friendlyName: string): string {
    const { required, max, min } = this.formGroup?.get(controlName).errors || {};

    if (required) {
      return `Your ${friendlyName} is required.`;
    }

    if (max) {
      return `The maximum allowed value for ${friendlyName} is ${max.max}.`;
    }

    if (min) {
      return `The minimum allowed value for ${friendlyName} is ${min.min}.`;
    }

    return '';
  }

  /**
   * Determines the qa attribute for a given qa descriptor.
   */
  getDynamicDataQaAttribute(qaDescriptor: string): string {
    return this.groupName ? `${this.groupName}-${qaDescriptor}` : `${qaDescriptor}`;
  }
}
