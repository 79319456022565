import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DisqualifiedFieldEvent } from '@models/disqualified-field-event';
import { SelectableOption } from '@models/selectable-option';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';

@Component({
  selector: 'app-yes-no-error-field',
  templateUrl: './yes-no-error-field.component.html',
  styleUrls: ['./yes-no-error-field.component.scss'],
})
export class YesNoErrorFieldComponent {
  readonly options: SelectableOption[] = [
    new SelectableOption(QuestionnaireAnswers.Yes),
    new SelectableOption(QuestionnaireAnswers.No),
  ];
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() label?: string;
  @Input() errorContent: string;
  @Input() index: number;
  @Input() disqualifiedAnswer: string;
  @Input() validatorsWhenVisible: ValidatorFn | ValidatorFn[];
  @Input() hintText: string;
  @Output() disqualifiedEvent: EventEmitter<DisqualifiedFieldEvent> = new EventEmitter<DisqualifiedFieldEvent>();

  /**
   * Forwards the answer event to the parent component.
   *
   * @param {DisqualifiedFieldEvent} disqualifiedAnswer the disqualified answer event
   */
  disqualifiedEventListener(disqualifiedAnswer: DisqualifiedFieldEvent): void {
    this.disqualifiedEvent.emit(disqualifiedAnswer);
  }
}
