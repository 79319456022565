// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card header {
  display: none;
}

/*
Most of these styles will be overridden by injecting TrustedSite.
We keep them here so that the div displays the badge locally.
The background position must be !important so that the image remains centered and that property is not overridden.
*/
.trustedsite-secure-checkout-badge {
  width: 120px;
  height: 50px;
  display: inline-block;
  background-image: url('trustedsite-secure-checkout-badge.7224f291c605bd62.svg');
  background-size: contain;
  background-position: center !important;
  background-repeat: no-repeat;
}

ul {
  list-style: disc;
  margin-left: 20px;
  line-height: 24px;
  font-size: 15px;
}

.mcafee-container {
  display: flex;
  justify-content: right;
}
.mcafee-container .mcafee-logo {
  width: 10rem;
}
@media screen and (min-width: 1216px) {
  .mcafee-container {
    display: block;
    position: relative;
  }
  .mcafee-container .mcafee-logo {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.place-order-button-container {
  flex-wrap: nowrap;
  gap: 8px;
}
@media screen and (max-width: 768px) {
  .place-order-button-container {
    flex-wrap: wrap-reverse;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
