import { Component, Input } from '@angular/core';
import { PaymentTypes } from '@enums/payment-types';
import { PaymentMethodOption } from '@models/payments/payment-method-option';

@Component({
  selector: 'app-accepted-payment-methods',
  templateUrl: './accepted-payment-methods.component.html',
  styleUrls: ['./accepted-payment-methods.component.scss'],
})
export class AcceptedPaymentMethodsComponent {
  @Input() private paymentMethodOptions: PaymentMethodOption[] = [];

  /**
   * Gets the accepted payment methods along with their details.
   *
   * @returns an array of objects representing accepted payment methods
   */
  get acceptedPaymentMethods(): { name: string; imgName: string; paymentType: PaymentTypes }[] {
    return [
      { name: 'Visa', imgName: 'cc-visa.svg', paymentType: PaymentTypes.CreditCard },
      { name: 'Mastercard', imgName: 'cc-mastercard.svg', paymentType: PaymentTypes.CreditCard },
      { name: 'Discover', imgName: 'cc-discover.svg', paymentType: PaymentTypes.CreditCard },
      { name: 'American Express', imgName: 'cc-amex.svg', paymentType: PaymentTypes.CreditCard },
      { name: 'Apple Pay', imgName: 'apple-pay.svg', paymentType: PaymentTypes.ApplePay },
      { name: 'Venmo', imgName: 'venmo.svg', paymentType: PaymentTypes.Venmo },
      { name: 'PayPal', imgName: 'paypal.png', paymentType: PaymentTypes.Paypal },
      { name: 'HSA', imgName: 'cc-hsa.svg', paymentType: null },
      { name: 'FSA', imgName: 'cc-fsa.svg', paymentType: null },
      { name: 'Bitcoin/Bitcoin Cash', imgName: 'bit-pay.png', paymentType: PaymentTypes.BitPay },
      { name: 'Google Pay', imgName: 'google-pay.png', paymentType: PaymentTypes.GooglePay },
    ].filter(
      (method) =>
        !method.paymentType ||
        this.paymentMethodOptions.some((option) => option.value === method.paymentType && option.visible)
    );
  }
}
