// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.venmo-button {
  width: 240px;
  height: 50.41px;
  background-color: #019cde;
  background-image: url('venmo-white-logo.6d8e3f18a1c74655.svg');
  background-size: 30% auto;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}
.venmo-button:hover {
  background-color: #51b0e5;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
