import { CreditCard } from '@common/credit-card';

export const CreditCards: CreditCard[] = [
  { type: 'Visa', icon: 'fab fa-cc-visa', tag: 'visa', cvv_length: 3 },
  { type: 'Mastercard', icon: 'fab fa-cc-mastercard', tag: 'mastercard', cvv_length: 3 },
  { type: 'Discover', icon: 'fab fa-cc-discover', tag: 'discover', cvv_length: 3 },
  { type: 'Diners Club', icon: 'fab fa-cc-diners-club', tag: 'diners-club', cvv_length: 3 },
  { type: 'American Express', icon: 'fab fa-cc-amex', tag: 'amex', cvv_length: 4 },
  { type: 'JCB', icon: 'fab fa-cc-jcb', tag: 'jcb', cvv_length: 3 },
];
