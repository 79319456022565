import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { FormService } from '@services/form.service';
import { SessionStorageService } from '@services/session-storage.service';
import * as Sentry from '@sentry/angular';
import { MimeTypes } from '@enums/mime-types';

@Component({
  selector: 'app-consult-attachment',
  templateUrl: './consult-attachment.component.html',
  styleUrls: ['./consult-attachment.component.scss'],
})
export class ConsultAttachmentComponent {
  isLoading: boolean = false;
  allowedFileExtensions = {
    [MimeTypes.JPEG]: '.jpg, .jpeg',
    [MimeTypes.PNG]: '.png',
    [MimeTypes.HEIC]: '.heic',
    [MimeTypes.PDF]: '.pdf',
  };

  constructor(private formService: FormService) {}

  /**
   * Gets the file from the form control.
   */
  get file(): File {
    return this.attachmentFormControl.value[0]?.file;
  }

  /**
   * Gets the attachment form control.
   */
  get attachmentFormControl(): FormArray {
    return this.formService.consultationAttachment.get('files') as FormArray;
  }
}
