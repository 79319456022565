import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-std-prevention-consultation-request-completed',
  templateUrl: './std-prevention-consultation-request-completed.component.html',
  styleUrls: ['../../consultation-request/consultation-request.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StdPreventionConsultationRequestCompletedComponent implements OnInit {
  patientName: string;
  transactionId: string;

  constructor(@Inject(APP_CONFIG) private config: AppConfig, private storageService: StorageService) {}

  ngOnInit(): void {
    this.patientName = this.storageService.patient?.name ?? '';
    this.transactionId = this.storageService.transactionId;
    this.storageService.removeConsultationRequestCompletedData();
  }

  /**
   * Gets the phone number from the config.
   *
   * @returns {string} the site phone number
   */
  get phone(): string {
    return this.config.phone;
  }
}
