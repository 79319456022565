import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from '@services/form.service';
import { OrderService } from '@services/order.service';
import { PaymentInfoComponent } from '@components/payment-info/payment-info.component';
import { DomainService } from '@services/domain.service';
import { PaymentTypes } from '@enums/payment-types';
import { Recharge } from '@models/recharge';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { StorageService } from '@services/storage.service';
import { InHomeCollectionService, ResultService } from '@Medology/ng-findalab';

@Component({
  selector: 'app-payment-step-tabs',
  templateUrl: './payment-step-tabs.component.html',
  styleUrls: ['./payment-step-tabs.component.scss'],
})
export class PaymentStepTabsComponent implements AfterViewInit, OnChanges, OnInit {
  @Input() hasPartner: boolean;
  @ViewChild('paymentInfo', { static: false })
  paymentInfoComponent: PaymentInfoComponent;
  @ViewChild('secondaryPaymentInfo', { static: false })
  secondaryPaymentInfoComponent: PaymentInfoComponent;

  PaymentTypes: typeof PaymentTypes = PaymentTypes;
  isPayNow: boolean = true;
  paymentForm: FormGroup;
  secondaryPaymentForm: FormGroup;
  isInHomeCollection: boolean = false;

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    public domainService: DomainService,
    public orderService: OrderService,
    private changeDetectorRef: ChangeDetectorRef,
    private formService: FormService,
    private storageService: StorageService,
    private inHomeService: InHomeCollectionService,
    private resultService: ResultService
  ) {}

  /**
   * Set payment form, which will be used in the PaymentInfoComponent child
   * component.
   */
  ngOnInit(): void {
    this.paymentForm = this.formService.checkout.get('payment') as FormGroup;
    this.secondaryPaymentForm = this.formService.checkout.get('secondaryPayment') as FormGroup;
    this.payNowValidationOnInHomeCollection();
  }

  /**
   * Add validators to child component form and force change detection.
   */
  ngAfterViewInit(): void {
    this.paymentInfoComponent.addDynamicValidators(this.isPayNow);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Handle component changes.
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.setToPayNowWhenHasPartner(changes.hasPartner.currentValue);
  }

  /**
   * Get the disabled payment methods.
   */
  get disabledPaymentMethods(): PaymentTypes[] {
    return [PaymentTypes.Free, ...(!this.config.enableGiftCard ? [PaymentTypes.GiftCard] : [])];
  }

  get isPayLaterEnabled(): boolean {
    return this.config.enablePayLater && !this.isInHomeCollection;
  }

  /**
   * Recharge information
   */
  get recharge(): Recharge {
    return this.orderService.reorderData
      ? this.orderService.reorderData?.recharge
      : this.orderService.rechargeData?.recharge;
  }

  /**
   * Disable/Enable payments.
   *
   * @param isPayNow Whatever the order will be paid now
   */
  setPayNow(isPayNow: boolean) {
    this.isPayNow = isPayNow;
    this.paymentForm.get('method').setValue(this.isPayNow ? PaymentTypes.CreditCard : PaymentTypes.PayLater);
    if (isPayNow) {
      this.changeDetectorRef.detectChanges();
    }

    this.paymentInfoComponent.addDynamicValidators(this.isPayNow);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Add the validations to the second payment information form.
   */
  onGiftCardApplied(): void {
    if (this.orderService.isSecondaryPaymentNeeded) {
      this.changeDetectorRef.detectChanges();
      this.secondaryPaymentForm.get('method').setValue(PaymentTypes.CreditCard);
      this.secondaryPaymentInfoComponent.addDynamicValidators(true);
    }
  }

  /**
   * Reset the applied gift card in the order service.
   */
  onGiftCardRemoved(): void {
    this.secondaryPaymentForm.get('method').setValue(PaymentTypes.PayLater);
    this.secondaryPaymentInfoComponent?.addDynamicValidators(false);
    this.orderService.applyGiftCard(null);
  }

  /**
   * Set pay now when the has partner checkbox is true.
   */
  private setToPayNowWhenHasPartner(hasPartner: boolean): void {
    if (
      hasPartner &&
      (!this.isPayNow ||
        [
          PaymentTypes.BitPay,
          PaymentTypes.Paypal,
          PaymentTypes.ApplePay,
          PaymentTypes.GooglePay,
          PaymentTypes.Venmo,
        ].includes(this.paymentForm.get('method').value))
    ) {
      this.setPayNow(true);
    }
  }

  /**
   * Pay now validations triggered on different in home collections statuses.
   */
  private payNowValidationOnInHomeCollection(): void {
    this.isInHomeCollection = this.storageService.hasInHomeCollection;

    this.resultService.results$.subscribe(() => {
      this.isInHomeCollection = false;
    });

    this.inHomeService.scheduling$.subscribe((status: boolean) => {
      this.isInHomeCollection = status;
      if (status) {
        this.setPayNow(true);
      }
    });
  }
}
