// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-health-labs-coupon app-card .card-content .healthlabs-coupon-container {
  background: url('healthlabs-bg.42103b219cb58386.jpg');
  background-position: top right !important;
  background-size: cover !important;
  border-radius: 8px !important;
  border: solid 1px rgba(19, 21, 22, 0.25);
}
app-health-labs-coupon app-card .card-content .healthlabs-coupon-container img {
  max-width: 300px;
}
app-health-labs-coupon app-card .card-content .healthlabs-coupon-container #healthlabs-coupon-content {
  margin-top: 2rem;
  max-width: 50%;
}
app-health-labs-coupon app-card .card-content .healthlabs-coupon-container #healthlabs-coupon-content h1,
app-health-labs-coupon app-card .card-content .healthlabs-coupon-container #healthlabs-coupon-content p {
  color: #fff !important;
  text-shadow: 2px 2px 2px rgba(19, 21, 22, 0.5) !important;
}
app-health-labs-coupon app-card .card-content .healthlabs-coupon-container #healthlabs-coupon-content h1 {
  border-bottom: solid 5px #256ca2;
  border-top: solid 5px #256ca2;
  padding: 10px 0px;
  line-height: 1.2;
  font-size: 2.25rem;
}
app-health-labs-coupon app-card .card-content .healthlabs-coupon-container #healthlabs-coupon-content p {
  font-size: 1.5rem;
  line-height: 1.6;
}
app-health-labs-coupon app-card .card-content .healthlabs-coupon-container #healthlabs-coupon-content app-animated-button button {
  margin: 1rem 0 !important;
  margin-top: 2rem !important;
  border-radius: 0.5rem !important;
}
@media only screen and (max-width: 769px) {
  app-health-labs-coupon app-card .card-content .healthlabs-coupon-container {
    padding: 1.5rem !important;
    background-position: 0px 0px !important;
  }
  app-health-labs-coupon app-card .card-content .healthlabs-coupon-container #healthlabs-coupon-content {
    max-width: 100%;
    margin: 0 !important;
  }
  app-health-labs-coupon app-card .card-content .healthlabs-coupon-container #healthlabs-coupon-content h1 {
    font-size: 1.5rem;
    line-height: 1.5;
    margin-top: 1rem !important;
    margin-bottom: 0.5rem !important;
  }
  app-health-labs-coupon app-card .card-content .healthlabs-coupon-container #healthlabs-coupon-content p {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 768px) {
  app-health-labs-coupon .healthlabs-coupon-container img {
    max-width: 200px !important;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
