import { LoadCartOptions } from '@models/load-cart-options';

export class HealthLabsLoadCartOptions extends LoadCartOptions {
  labId: number = null;
  hasInHomeCollection: boolean = false;

  constructor(options: Partial<HealthLabsLoadCartOptions> = {}) {
    super(options);
    Object.assign(this, options);
  }
}
