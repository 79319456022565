import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { dateValidator } from '../../validators/date-validator';
import { ageLimitValidator } from '../../validators/age-limit-validator';
import { DateSelection } from '@models/date-selection';
import { Month } from '@common/month';
import { Months } from '@common/months';
import { FormHelper } from '@common/form-helper';
import { FormService } from '@services/form.service';
import { OrderService } from '@services/order.service';
import { StorageService } from '@services/storage.service';
import { DomainService } from '@services/domain.service';
import { RechargeRequest } from '@models/recharge-request';
import { AppConfig, APP_CONFIG } from '@modules/config/types/config';

@Component({
  selector: 'app-patient-info',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.scss'],
})
export class PatientInfoComponent extends FormHelper implements OnInit {
  @Input() enableGuardianForm: boolean;

  months: Month[];
  days: number[];
  years: number[];
  minAgeAllowed: number;
  consentDomain: string;

  patientInfoForm: FormGroup;

  constructor(
    private domainService: DomainService,
    private storageService: StorageService,
    private formService: FormService,
    private orderService: OrderService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {
    super();
  }

  /**
   * Initialize this component.
   */
  ngOnInit(): void {
    this.consentDomain = this.config.domain;
    this.setBirthdayFormOptions();
    this.patientInfoForm = this.formService.checkout.get('patient') as FormGroup;
    this.setDynamicPatientInfoFormValidators();

    if (this.isReordering) {
      this.reOrderPatientForm(this.orderService.reorderData);
    } else if (this.isRechargePayment) {
      this.loggedInPatientForm(this.orderService.rechargeData);
    }

    this.storageService.hasPartner = false;
    this.autofillEmail();

    if (this.forceEmailSelection) {
      this.patientInfoForm.patchValue({ contactVia: 'email' });
      this.patientInfoForm.get('contactVia').disable();
    }
  }

  /**
   * Get maximum age allowed
   */
  get maxAgeAllowed(): number {
    return this.config.maxAgeAllowed;
  }

  /**
   * Whether the results should always be sent by email.
   */
  get forceEmailSelection(): boolean {
    return this.config.forceEmailResults;
  }

  /**
   * If reorder data exists, we assume we are reordering, returning true. False otherwise.
   */
  get isReordering(): boolean {
    return !!this.orderService.reorderData;
  }

  /**
   * If reorder data exists, we assume we are reordering, returning true. False otherwise.
   */
  get isRechargePayment(): boolean {
    return !!this.orderService.rechargeData;
  }

  /**
   * Determines if this section of the form is valid.
   */
  get sectionIsValid(): boolean {
    return this.formService.checkout.get('patient').valid;
  }

  /**
   * Gets the error to display if sex pattern validation fails.
   */
  get sexInvalidError(): string {
    return this.config.order.sexInvalidError;
  }

  /**
   * Sets the birthday select options.
   */
  private setBirthdayFormOptions(): void {
    this.minAgeAllowed = this.config.order.minAgeAllowed;
    this.months = Months;
    this.days = this.getDays();
    this.years = this.getYears();
  }

  /**
   * Gets the patient contact method.
   */
  get patientContactVia(): 'email' | 'phone' | 'none' {
    return this.patientInfoForm.get('contactVia').value;
  }

  /**
   * Determines whether or not the guardian form should be shown.
   */
  get showGuardianForm(): boolean {
    return this.enableGuardianForm && this.patientInfoForm.get('birthday').valid && this.isUnderAge();
  }

  /**
   * Determines whether the partner email input should be shown.
   */
  get showPartnerEmailInput(): boolean {
    return this.patientInfoForm.get('hasPartner').value;
  }

  /**
   * Determines whether if it's STDCheck domain
   */
  get isSTDCheckDomain(): boolean {
    return this.domainService.isSTDCheckDomain();
  }

  /**
   * Determines whether if it's HealthLabs domain
   */
  get isHealthlabsDomain(): boolean {
    return this.domainService.isHealthlabsDomain();
  }

  /**
   * Determines whether if it's TreatMyUti domain
   */
  get isTmuDomain(): boolean {
    return this.domainService.isTreatMyUtiDomain();
  }

  /**
   * Determines whether if it's Starfish domain
   */
  get isStarfishDomain(): boolean {
    return this.domainService.isStarfishDomain();
  }

  /**
   * Determine if Partner Testing is allowed for the current domain.
   */
  get isPartnerTestingAllowed(): boolean {
    return this.config.isPartnerTestingAllowed;
  }

  /**
   * Return ' (or anybody else)' if it's HealthLabs domain
   */
  get orAnyoneElseWithHl(): string {
    return this.domainService.isHealthlabsDomain() ? '(or anybody else) ' : '';
  }

  /**
   * Returns true when we want to display the phone field
   */
  get showPhoneField(): boolean {
    return this.patientContactVia === 'phone' || this.isTmuDomain || this.isStarfishDomain;
  }

  /**
   * Determines if the patient is underage.
   */
  private isUnderAge(): boolean {
    return !this.patientBirthDate?.isAgeValid();
  }

  /**
   * Gets the birthdate of the patient.
   */
  private get patientBirthDate(): DateSelection | null {
    if (!this.birthdateFilled()) {
      return null;
    }

    const birthDayFormGroup = this.patientInfoForm.get('birthday');

    return new DateSelection(
      Number(birthDayFormGroup.get('month').value),
      Number(birthDayFormGroup.get('day').value),
      Number(birthDayFormGroup.get('year').value)
    );
  }

  /**
   * Gets a list of all the days
   */
  getDays(): number[] {
    return this.getListOfNumbers(1, 31);
  }

  /**
   * Gets a list of the last 90 years.
   *
   * The latest years are removed based minimum age settings.
   */
  getYears(): number[] {
    const currentYear = new Date().getFullYear();
    const from = currentYear - this.minAgeAllowed;

    return this.getListOfNumbers(from, currentYear - this.config.maxAgeAllowed);
  }

  /**
   * Sets up dynamic validators.
   */
  private setDynamicPatientInfoFormValidators(): void {
    const patientInfoForm = this.patientInfoForm;
    const sexControl = patientInfoForm.get('sex');
    sexControl.setValidators([Validators.required, this.genderValidator(this.config.order.allowedGenders)]);
    sexControl.updateValueAndValidity();
    const birthDayControl = patientInfoForm.get('birthday');
    birthDayControl.setValidators(this.birthdayValidators);
    birthDayControl.valueChanges.subscribe(() => this.setGuardianFormValidator());
    patientInfoForm.get('contactVia').valueChanges.subscribe(() => this.setContactFormValidator());
    patientInfoForm
      .get('hasPartner')
      .valueChanges.subscribe((hasPartner) => this.setPartnerEmailFormValidator(hasPartner));

    this.setGuardianFormValidator();
    this.setContactFormValidator();
  }

  /**
   * Sets th contact form validator based on the contact type.
   */
  private setContactFormValidator(): void {
    const patientInfoForm = this.patientInfoForm;
    const emailControl = patientInfoForm.get('email');
    const phoneControl = patientInfoForm.get('phone');
    const allowVmControl = patientInfoForm.get('voicemail_allowed');

    emailControl.clearValidators();
    phoneControl.clearValidators();
    allowVmControl.clearValidators();

    switch (patientInfoForm.get('contactVia').value) {
      case 'email':
        emailControl.setValidators([Validators.required, Validators.email]);
        break;
      case 'phone':
        phoneControl.setValidators([Validators.required, Validators.pattern('[0-9]{10}')]);
        allowVmControl.setValidators([Validators.required]);
        break;
    }
    if (this.isTmuDomain || this.isStarfishDomain) {
      phoneControl.setValidators([Validators.required, Validators.pattern('[0-9]{10}')]);
    }

    emailControl.updateValueAndValidity();
    phoneControl.updateValueAndValidity();
    allowVmControl.updateValueAndValidity();
  }

  /**
   * Sets the partner email form validator based on the has partner checkbox
   */
  private setPartnerEmailFormValidator(hasPartner: boolean): void {
    const partnerEmailField = this.patientInfoForm.get('partnerEmail');

    if (hasPartner) {
      partnerEmailField.addValidators(Validators.required);

      return;
    }

    partnerEmailField.removeValidators(Validators.required);
    partnerEmailField.updateValueAndValidity();
  }

  /**
   * Sets the guardian form validators based on the age of the patient.
   */
  private setGuardianFormValidator(): void {
    if (!this.enableGuardianForm) {
      return;
    }

    const guardianForm = this.patientInfoForm.get('parentGuardian');
    ['firstName', 'lastName', 'relationToChild', 'attestation'].forEach((controlName: string) => {
      const control = guardianForm.get(controlName);
      control.clearValidators();
      if (this.isUnderAge()) {
        control.setValidators(
          [Validators.required].concat(controlName === 'attestation' ? [Validators.requiredTrue] : [])
        );
      }

      control.updateValueAndValidity();
    });
    guardianForm.updateValueAndValidity();
  }

  /**
   * Autofill e-mail when set in localStorage
   */
  private autofillEmail(): void {
    if (this.storageService.email) {
      this.patientInfoForm.get('contactVia').setValue('email');
      this.patientInfoForm.get('email').setValue(this.storageService.email);
    }
  }

  /**
   * Gets the birthdate validators based on the age restriction settings.
   */
  private get birthdayValidators(): ValidatorFn[] {
    return [dateValidator(), ageLimitValidator(this.minAgeAllowed, this.config.maxAgeAllowed)];
  }

  /**
   * Determines if a form control is invalid.
   *
   * @param {string} controlName The name of the form control to check for validity.
   */
  invalidControl(controlName: string): boolean {
    return this.isInvalid(this.patientInfoForm.get(controlName));
  }

  /**
   * Determines if the birthdate is filled out.
   */
  private birthdateFilled(): boolean {
    return ['month', 'day', 'year'].every((controlName: string) => {
      return !this.invalidControl(`birthday.${controlName}`);
    });
  }

  /**
   * Render reorder patient data
   *
   * @param {} reorderData reorder data
   */
  reOrderPatientForm(reorderData: any): void {
    let dob = new Date(reorderData.dob.date);
    const accountType = reorderData.email ? 'email' : reorderData.phone ? 'phone' : 'none';
    this.patientInfoForm.patchValue({
      firstName: reorderData.first_name,
      lastName: reorderData.last_name,
      sex: 'M',
      birthday: {
        month: dob.getMonth() + 1,
        day: dob.getDate(),
        year: dob.getFullYear(),
      },
      contactVia: accountType,
      phone: accountType === 'phone' ? reorderData.phone : null,
      voicemail_allowed: reorderData.leave_msg === '1' && accountType === 'phone',
      email: accountType === 'email' ? reorderData.email : null,
    });
  }

  /**
   * Render reorder patient data
   *
   * @param {RechargeRequest} rechargeData Patient information from previous payment
   */
  loggedInPatientForm(rechargeData: RechargeRequest): void {
    this.patientInfoForm.patchValue({
      contactVia: rechargeData.accountType,
      phone: rechargeData.phone,
      email: rechargeData.email,
    });
  }

  /**
   * Save the has partner attribute into the storage
   */
  handleHasPartnerChange(): void {
    this.storageService.hasPartner = this.patientInfoForm.get('hasPartner').value;
  }

  /**
   * Returns true when free order is set in localStorage
   */
  get isFree(): boolean {
    return this.storageService.free;
  }

  /**
   * Returns true when isBaOrder is set in localStorage
   */
  get isBaOrder(): boolean {
    return this.storageService.isBaOrder;
  }
}
