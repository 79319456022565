import { Injectable } from '@angular/core';
import { StorageService } from '@services/storage.service';
import { Address } from '@models/address';
import { ConsultationStatuses } from '@enums/consultation-statuses';

@Injectable({
  providedIn: 'root',
})

/**
 * This service class provides the session storage management for the application.
 */
export class SessionStorageService extends StorageService {
  protected storage = sessionStorage;

  /**
   * Set the patient on sessionStorage.
   *
   * @param {string} preferredMedication The preferred medication to be set.
   */
  set preferredMedication(preferredMedication: string) {
    this.set('preferredMedication', preferredMedication);
  }

  /**
   * Get the preferred medication from sessionStorage.
   */
  get preferredMedication(): string {
    return this.get('preferredMedication');
  }

  /**
   * Set the hash on sessionStorage.
   *
   * @param {string} hash The hash to be set.
   */
  set hash(hash: string) {
    this.set('hash', hash);
  }

  /**
   * Get the consultation id.
   */
  get consultationId(): string {
    return this.get('consultation_id');
  }

  /**
   * Set the consultation id on sessionStorage.
   *
   * @param {string} consultationId The consultation id to be set.
   */
  set consultationId(consultationId: string) {
    this.set('consultation_id', consultationId);
  }

  /**
   * Get the hash from sessionStorage.
   */
  get hash(): string {
    return this.get('hash');
  }

  /**
   * Set the questionnaire on sessionStorage.
   *
   * @param {any} questionnaire The questionnaire to be set.
   */
  set questionnaire(questionnaire: any) {
    this.set('questionnaire', questionnaire);
  }

  /**
   * Get the questionnaire from sessionStorage.
   */
  get questionnaire(): any {
    return this.get('questionnaire');
  }

  /**
   * Set the pharmacy on sessionStorage.
   *
   * @param {any} pharmacy The pharmacy to be set.
   */
  set pharmacy(pharmacy: any) {
    this.set('pharmacy', pharmacy);
  }

  /**
   * Get the pharmacy from sessionStorage.
   */
  get pharmacy(): any {
    return this.get('pharmacy');
  }

  /**
   * Set the address on sessionStorage.
   *
   * @param {Address} address The address to be set.
   */
  set address(address: Address) {
    this.set('address', address);
  }

  /**
   * Get the address from sessionStorage.
   */
  get address(): Address {
    return this.get('address');
  }

  /**
   * Set the shippingAddress on sessionStorage.
   *
   * @param {Address} address The address to be set.
   */
  set shippingAddress(address: Address) {
    this.set('shippingAddress', address);
  }

  /**
   * Get the shippingAddress from sessionStorage.
   */
  get shippingAddress(): Address {
    return this.get('shippingAddress');
  }

  /**
   * Saves the upsells on sessionStorage.
   *
   * @param {any} upsells The upsells to be set.
   */
  set upsells(upsells: any) {
    this.set('upsells', upsells);
  }

  /**
   * Retrieve upsell transaction id
   */
  get upsells(): string[] {
    return this.get('upsells') ?? [];
  }

  /**
   * Saves the ordered upsells on sessionStorage.
   *
   * @param {any} upsells The upsells to be set.
   */
  set orderedUpsells(upsells: string[]) {
    this.set('ordered_upsells', upsells);
  }

  /**
   * Retrieve ordered upsells
   */
  get orderedUpsells(): string[] {
    return this.get('ordered_upsells') ?? [];
  }

  /**
   * Saves the upsells on sessionStorage.
   *
   * @param {ConsultationStatuses} consultationStatus The consultation status.
   */
  set consultationStatus(consultationStatus: ConsultationStatuses) {
    this.set('consultation_status', consultationStatus);
  }

  /**
   * Retrieve current consultation request status.
   */
  get consultationStatus(): ConsultationStatuses {
    return this.get('consultation_status');
  }

  /**
   * Clears from storage all the keys related to the consultation request completed page.
   */
  override removeConsultationRequestCompletedData(): void {
    this.patient = null;
    this.transactionId = null;
    this.hash = null;
    this.questionnaire = null;
    this.address = null;
    this.shippingAddress = null;
    this.upsells = null;
    this.consultationStatus = null;
    this.consultationId = null;
    this.pharmacy = null;
    this.upsell = null;
    this.setQuestionnaireAnswers(this.treatmentType, null);
    this.setQuestionnaireStructure(this.treatmentType, null);
    this.setQuestionnaireAnswers('upsells', null);
    this.preferredMedication = null;
    this.treatmentType = null;
    this.orderedUpsells = null;
    this.medicalHistoryQuestionnaireAnswers = null;
  }
}
