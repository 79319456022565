import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RestrictedNavigationService } from '@services/restricted-navigation.service';

@Component({
  selector: 'app-stdcheck-layout',
  templateUrl: './stdcheck-layout.component.html',
  styleUrls: ['./stdcheck-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StdcheckLayoutComponent implements OnInit {
  today: Date = new Date();
  restrictedNavigation: boolean = false;

  constructor(protected restrictedNavigationService: RestrictedNavigationService) {}

  ngOnInit(): void {
    this.restrictedNavigationService.restrictedNavigationObserver.subscribe((r) => (this.restrictedNavigation = r));
  }
}
