import { ConsultationRequestService } from '@services/consultation-request.service';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ConsultationRequestOrderDetail } from '@models/consultation-request/consultation-request-order-detail';
import { ConsultationStatuses } from '@enums/consultation-statuses';
import { ConsultationRequestFormAlreadySubmittedError } from '@errors/consultation-request-form-already-submitted-error';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';

export class TreatmentConsultationRequestService extends ConsultationRequestService {
  /**
   * Retrieves the consultation order details, fetches additional consultations based on specific filters,
   * and updates the order details with the first consultation from the response.
   *
   * @param {string}                     orderId       the ID of the order to retrieve details for
   * @param {string}                     hash          the hash value associated with the order
   * @param {ConsultationTreatmentTypes} treatmentType the treatment type
   *
   * @returns {Observable<ConsultationRequestOrderDetail>} an Observable that emits the updated order details
   *
   * @throws ConsultationRequestFormAlreadySubmittedError if no consultations are found in the response
   */
  public override getConsultationOrderDetails(
    orderId: string,
    hash: string,
    treatmentType: ConsultationTreatmentTypes
  ): Observable<ConsultationRequestOrderDetail> {
    return super.getConsultationOrderDetails(orderId, hash, treatmentType).pipe(
      switchMap((orderDetails) => {
        const filters = {
          consultations_status: ConsultationStatuses.Incomplete,
          treatment_type: treatmentType,
        };

        return this.getConsultations(orderId, hash, JSON.stringify(filters)).pipe(
          map((response) => {
            if (!response.data?.length) {
              throw new ConsultationRequestFormAlreadySubmittedError(this.config.email);
            }

            orderDetails.consultationRequest = response.data[0];
            orderDetails.last_prescribed_at = response['meta']?.last_prescribed_at;
            this.consultationRequestOrderDetail = orderDetails;

            return orderDetails;
          })
        );
      })
    );
  }
}
