// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.steps-containers {
  background-color: #f6e896;
  border-bottom: solid 1px #cccccc;
  box-shadow: inset 0 0 0 5px #eddf93;
  max-width: 75rem;
  margin: auto;
}
@media screen and (min-width: 769px), print {
  .steps-containers {
    margin-top: 4.7rem;
    padding: 0 10px;
  }
}
@media only screen and (max-width: 769px) {
  .steps-containers {
    padding: 0.75rem 0 !important;
  }
  .steps-containers .column {
    justify-content: center !important;
    margin-left: 4rem;
  }
}
.steps-containers .column:first-child,
.steps-containers .column:nth-child(2) {
  background: URL("assets/img/hl-steps/legacy-hl-steps-arrow.png");
  background-repeat: no-repeat;
  background-position: right;
}
@media only screen and (max-width: 769px) {
  .steps-containers .column:first-child,
  .steps-containers .column:nth-child(2) {
    background: none;
  }
}
.steps-containers .step-container {
  padding: 0.75rem 0 !important;
  margin-left: -2rem;
}
@media only screen and (max-width: 769px) {
  .steps-containers .step-container {
    flex-direction: row !important;
    align-items: center !important;
    justify-content: start !important;
    width: 100%;
    margin-left: 0.3rem;
    padding: 0.5rem 0 !important;
  }
}
.steps-containers .step-container h1.step-number {
  color: #fd9741;
  font-family: "Open Sans", Arial, sans-serif !important;
  font-size: 4.69rem;
  font-weight: 500;
  height: 4.6875rem;
  line-height: 4.6875rem;
  text-align: center;
  margin: 0;
  margin-right: 0.5rem;
  padding: 0;
}
@media only screen and (max-width: 769px) {
  .steps-containers .step-container h1.step-number {
    font-size: 2.5rem;
    width: 40px;
    height: 100%;
    line-height: normal;
    margin-right: 0;
  }
}
.steps-containers .step-container p {
  font-family: "Open Sans", Arial, sans-serif !important;
}
.steps-containers .step-container p.title {
  color: #256ca2 !important;
  font-size: 1.25rem !important;
  font-weight: 900;
  text-shadow: 1px 1px 3px #fff;
  margin-bottom: 0.3rem !important;
}
@media only screen and (max-width: 769px) {
  .steps-containers .step-container p.title {
    margin-bottom: 0 !important;
  }
}
.steps-containers .step-container p.subtitle {
  color: #1d5681 !important;
  font-size: 14px !important;
  line-height: 1.2;
  font-weight: 400 !important;
  text-shadow: 1px 1px 3px #fff;
}
.steps-containers .step-container .steps__icon {
  color: #1d5681;
  font-size: 1.875rem;
  padding-right: 10px;
}
.steps-containers .step-container div.is-hidden-tablet.has-text-centered {
  width: 250px;
  font-weight: bold;
}
.steps-containers .step-container div.is-hidden-tablet.has-text-centered i {
  width: 40px;
  margin-right: 0.3rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
