import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ConsultationStdPreventiveQuestionnaireService } from '@services/consultation-std-preventive-questionnaire.service';
import { DisqualifiedFieldEvent } from '@models/disqualified-field-event';
import { ConsultationStatuses } from '@enums/consultation-statuses';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { SelectableOption } from '@models/selectable-option';

@Component({
  selector: 'app-consultation-std-preventive-questionnaire',
  templateUrl: './consultation-std-preventive-questionnaire.component.html',
  styleUrls: ['./consultation-std-preventive-questionnaire.component.scss'],
})
export class ConsultationStdPreventiveQuestionnaireComponent implements OnInit, OnDestroy {
  readonly unprotectedSexDaysAgoOptions: SelectableOption[] = [
    new SelectableOption('0-3 days ago'),
    new SelectableOption('4+ days ago'),
  ];

  readonly pregnantOptions: SelectableOption[] = Object.values(QuestionnaireAnswers).map(
    (value) => new SelectableOption(value)
  );

  private subscriptions: Subscription[] = [];
  private questionDisqualified: Set<string> = new Set();

  readonly questionnaireAnswers = QuestionnaireAnswers;

  @Input() consultationForm: FormGroup;
  @Input() questionnaireForm: FormGroup;
  @Input() isFemale: boolean;

  constructor(public consultationStdPreventiveQuestionnaireService: ConsultationStdPreventiveQuestionnaireService) {}

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.initializeForm();
  }

  /**
   * Unsubscribes from all subscriptions.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Listener for disqualified event, manage the disqualified answer to the list of disqualified answers.
   *
   * @param {DisqualifiedFieldEvent} disqualifiedAnswer the disqualified answer event
   */
  disqualifiedEventListener(disqualifiedAnswer: DisqualifiedFieldEvent): void {
    disqualifiedAnswer.isDisqualified
      ? this.questionDisqualified.add(disqualifiedAnswer.controlName)
      : this.questionDisqualified.delete(disqualifiedAnswer.controlName);

    this.consultationForm.get('consultation_status').setValue(this.getConsultationStatus());
  }

  /**
   * Get the question label from the question control name
   *
   * @param {string} controlName the question control name
   */
  getQuestionLabel(controlName: string): string {
    return this.consultationStdPreventiveQuestionnaireService.getQuestionLabel(controlName);
  }

  /**
   * If the "hasUnprotectedSex" question is answered as "Yes"
   */
  get hasUnprotectedSex(): boolean {
    return this.questionnaireForm.get('hasUnprotectedSex').value === QuestionnaireAnswers.Yes;
  }

  /**
   * Get disqualification or pending consultation status for the consultation because of disqualified questions
   */
  private getConsultationStatus(): ConsultationStatuses.Pending | ConsultationStatuses.Disqualified {
    return this.questionDisqualified.size > 0 ? ConsultationStatuses.Disqualified : ConsultationStatuses.Pending;
  }

  /**
   * Initializes the medical history form, by setting the form group and adding the required validators.
   */
  private initializeForm(): void {
    this.addRequiredValidators();
    this.subscriptions.push(
      this.addConditionalValidators('onMedication', ['medicationDetails']),
      this.addConditionalValidators('hasAllergies', ['allergiesDetails']),
      this.addConditionalValidators('hasOtherConditions', ['otherConditionsDetails']),
      this.addConditionalValidators('hasSexualQuestions', ['sexualQuestions'])
    );
  }

  /**
   * Adds the required validators to the form controls.
   */
  private addRequiredValidators(): void {
    [
      'onMedication',
      'hasAllergies',
      'hasOtherConditions',
      'hasUnprotectedSex',
      'hasSymptoms',
      'hasSexualQuestions',
      'hasHypersensitivity',
      'alreadyOnMedication',
      'hasMyastheniaGravis',
      'hasOesophagitis',
    ]
      .concat(this.isFemale ? ['pregnant', 'breastfeeding'] : [])
      .forEach((controlName) => {
        const control = this.questionnaireForm.get(controlName);
        control.addValidators([Validators.required]);
        control.updateValueAndValidity();
      });
  }

  /**
   * Adds conditional validators to the form controls, making the details form control required if the yes/no form
   * control is true (yes).
   *
   * @param {string} controlName the name of the yes/no form control
   * @param {string[]} requiredControlNames the names of the required form control
   *
   * @returns {Subscription} a subscription to the value changes of the yes/no form control
   */
  private addConditionalValidators(controlName: string, requiredControlNames: string[]): Subscription {
    return this.questionnaireForm.get(controlName).valueChanges.subscribe((value) => {
      requiredControlNames.forEach((controlName) => {
        const control = this.questionnaireForm.get(controlName);
        if (value === QuestionnaireAnswers.Yes) {
          control.setValidators([Validators.required]);
        } else {
          control.clearValidators();
        }

        control.updateValueAndValidity();
      });
    });
  }
}
