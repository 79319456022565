import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OrderSummaryRow } from '@common/order-summary-row';
import { PaymentInfoComponent } from '@components/payment-info/payment-info.component';
import { PaymentTypes } from '@enums/payment-types';
import { OrderUpsell } from '@models/order-upsell';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { DocumentService } from '@services/document.service';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-upsell-payment-info',
  templateUrl: './upsell-payment-info.component.html',
  styleUrls: ['./upsell-payment-info.component.scss'],
})
export class UpsellPaymentInfoComponent implements AfterViewInit {
  @Input() set selectedUpsell(upsell: OrderUpsell[]) {
    this._selectedUpsell = upsell;
    this.setOrderSummaryRows();
  }
  @Input() upsellTestsForm: FormGroup;
  @Input() enablePayLater: boolean = true;
  @ViewChild('upsellPaymentInfo', { static: false }) paymentInfoComponent: PaymentInfoComponent;

  PaymentTypes: typeof PaymentTypes = PaymentTypes;
  isPayNow: boolean = true;
  orderSummaryRows: OrderSummaryRow[] = [];

  private _selectedUpsell: OrderUpsell[] = [];

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    private changeDetectorRef: ChangeDetectorRef,
    private documentService: DocumentService,
    private storageService: StorageService
  ) {}

  /**
   * Gets the selected upsell.
   */
  get selectedUpsell(): OrderUpsell[] {
    return this._selectedUpsell;
  }

  /**
   * Gets the payment form group from the upsell tests form.
   */
  get paymentForm(): FormGroup {
    return this.upsellTestsForm?.get('payment') as FormGroup;
  }

  /**
   * Gets whether pay later is enabled.
   */
  get isPayLaterEnabled(): boolean {
    return this.config.enablePayLater && !this.storageService.hasInHomeCollection && this.enablePayLater;
  }

  /**
   * Gets the total price of the selected upsell.
   */
  get total(): number {
    return this.selectedUpsell.reduce((acc, upsell) => acc + (upsell.upsell_price || upsell.price), 0);
  }

  /**
   * Adds validators to payment form.
   */
  ngAfterViewInit(): void {
    this.paymentInfoComponent?.addDynamicValidators(true);
    this.changeDetectorRef.detectChanges();
    this.documentService.scrollToBottom();
  }

  /**
   * Sets the payment mode and performs necessary actions based on the payment choice.
   *
   * @param isPayNow Whether or not the user is paying now
   */
  setPayNow(isPayNow: boolean) {
    this.isPayNow = isPayNow;
    this.paymentForm.get('method').setValue(this.isPayNow ? PaymentTypes.CreditCard : PaymentTypes.PayLater);
    if (isPayNow) {
      this.changeDetectorRef.detectChanges();
    }

    this.paymentInfoComponent.addDynamicValidators(this.isPayNow);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Adds the upsell to the order summary component.
   */
  private setOrderSummaryRows(): void {
    const upsell = this.selectedUpsell;
    if (!upsell.length) {
      this.orderSummaryRows = [];

      return;
    }

    this.orderSummaryRows = upsell.map((upsell) => ({
      item: `${upsell.name} ${upsell.name?.toLowerCase() === 'trichomoniasis' ? 'Test' : ''}`,
      amountInCents: upsell.upsell_price || upsell.price,
      shouldDisplayRow: true,
    }));
  }
}
