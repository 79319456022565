import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '@services/storage.service';
import { Observable, Subject } from 'rxjs';
import { DomainService } from '@services/domain.service';
import { AuthorizationService } from '@services/authorization.service';
import { OrderService } from '@services/order.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  businessAccountValidation$ = new Subject<boolean>();

  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected http: HttpClient,
    protected storage: StorageService,
    protected domainService: DomainService,
    protected authService: AuthorizationService,
    protected orderService: OrderService
  ) {}

  /**
   * Get the business account id from STDcheck
   *
   * @param {string} token The JWT token to validate the business account
   *
   * @returns the request body to make a get request to /api/v1/account
   */
  getBusinessAccountId(token: string): Observable<{ business_account_id: string }> {
    return this.http.get<{ business_account_id: string }>(
      `${this.config.analyteCareApi}/api/v1/account`,
      this.authService.getOptionsWithAuthHeader(token)
    );
  }

  /**
   * Validate if the current account token has a business account associated and set the isBaOrder
   *
   * @param {string} token The token to validate the business account
   */
  validateBaAccount(token: string): void {
    if (!this.shouldValidateBusinessAccount(token)) {
      this.businessAccountValidation$.next(false);

      return;
    }

    this.getBusinessAccountId(token).subscribe({
      next: (response) => {
        this.storage.isBaOrder = !!response.business_account_id;
        // If the user is a business account, clear the recharge data - We dont display the payment UI
        this.orderService.rechargeData = null;
        this.businessAccountValidation$.next(true);
      },
      error: () => {
        this.storage.isBaOrder = false;
        this.businessAccountValidation$.next(false);
      },
    });
  }

  /**
   * Check if the current domain is STDcheck and if the current order is a business account order.
   *
   * @param {string} token The token to validate the business account
   */
  shouldValidateBusinessAccount(token: string): boolean {
    if (!token || this.domainService.isSTDCheckDomain()) {
      if (this.storage.isBaOrder) {
        this.orderService.clearLastOrder();
      }

      this.storage.isBaOrder = false;

      return false;
    }

    return true;
  }
}
