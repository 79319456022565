import { InjectionToken } from '@angular/core';
import { healthlabsEnvironment } from '@environments/healthlabs/environment';
import { starfishEnvironment } from '@environments/starfish/environment';
import { stdcheckEnvironment } from '@environments/stdcheck/environment';
import { treatmyutiEnvironment } from '@environments/treatmyuti/environment';

export type AppConfig =
  | typeof stdcheckEnvironment
  | typeof healthlabsEnvironment
  | typeof treatmyutiEnvironment
  | typeof starfishEnvironment;

export const APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG');
