import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '@common/form-helper';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { DisqualifiedFieldEvent } from '@models/disqualified-field-event';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-yes-no-details-field',
  templateUrl: './yes-no-details-field.component.html',
  styleUrls: ['./yes-no-details-field.component.scss'],
})
export class YesNoDetailsFieldComponent extends FormHelper implements OnInit, AfterViewInit, OnDestroy {
  @Input() detailsControlName: string;
  @Input() form: FormGroup;
  @Input() yesNoControlName: string;
  @Input() yesNoLabel: string;
  @Input() index: number;
  @Input() placeholder: string = 'Type here';
  @Input() hintText: string;
  @Input() warningInducingOption: QuestionnaireAnswers;
  @Input() warningMessage: string;
  @Output() disqualifiedEvent: EventEmitter<DisqualifiedFieldEvent> = new EventEmitter<DisqualifiedFieldEvent>();

  private valueChangesSubscription: Subscription;

  readonly questionnaireAnswers = QuestionnaireAnswers;

  /**
   * Gets the form control.
   *
   * @returns {AbstractControl} the form control with the specified name from the form group
   */
  get control(): AbstractControl {
    return this.form?.get(this.yesNoControlName);
  }

  /**
   * Checks if the warning-inducing option is selected.
   *
   * @returns {boolean} true if the warning-inducing option is selected; otherwise, false
   */
  get isWarningInducingOptionSelected(): boolean {
    return this.control?.value === this.warningInducingOption;
  }

  /**
   * Executes any necessary initialization logic after the component has been initialized.
   */
  ngOnInit() {
    this.valueChangesSubscription = this.control?.valueChanges.subscribe((value) =>
      this.handleYesNoAnswerChange(value)
    );
  }

  /**
   * Executes any necessary initialization logic after the view has been initialized.
   */
  ngAfterViewInit() {
    this.form?.get(this.yesNoControlName).addValidators(Validators.required);
  }

  /**
   * Destroys the component.
   */
  ngOnDestroy() {
    this.form.get(this.yesNoControlName).removeValidators(Validators.required);
    this.form.get(this.detailsControlName).removeValidators(Validators.required);
    this.form.get(this.yesNoControlName).reset();
    this.form.get(this.detailsControlName).reset();
    this.valueChangesSubscription?.unsubscribe();
    this.valueChangesSubscription = undefined;
  }

  /**
   * Checks if the form control is invalid.
   *
   * @param {string} controlName the name of the form control to check for validity
   */
  isFormControlInvalid(controlName: string): boolean {
    return this.isInvalid(this.form?.get(controlName));
  }

  /**
   * Handles the change event of the yes-no answer.
   *
   * @param {string} value the value of the yes-no answer
   */
  handleYesNoAnswerChange(value: string): void {
    this.disqualifiedEvent.emit({
      controlName: this.yesNoControlName,
      isDisqualified: this.isWarningInducingOptionSelected,
    });

    if (value === QuestionnaireAnswers.No) {
      this.form.get(this.detailsControlName).setValue(QuestionnaireAnswers.No);

      return;
    }

    this.form.get(this.detailsControlName).reset();
  }
}
