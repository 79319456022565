import { Component, Inject, Input } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';

@Component({
  selector: 'app-order-complete-summary',
  templateUrl: './order-complete-summary.component.html',
  styleUrls: ['./order-complete-summary.component.scss'],
})
export class OrderCompleteSummaryComponent {
  @Input() orderId: string = '';
  @Input() orderTotal: number = 0;
  @Input() paymentIds: string[] = [];
  @Input() testsOrdered: string[] = [];

  constructor(@Inject(APP_CONFIG) public config: AppConfig) {}
  orderedTestWording: { single: string; plural: string } = this.config.orderCompleteSummary.testOrderedWording;
}
