import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';

@Component({
  selector: 'app-health-labs-coupon',
  templateUrl: './health-labs-coupon.component.html',
  styleUrls: ['./health-labs-coupon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HealthLabsCouponComponent {
  constructor(@Inject(APP_CONFIG) public config: AppConfig) {}

  /**
   * It HL with the STDcheck order coupon
   */
  redirectToHL() {
    window.open(
      'https://www.healthlabs.com/?coupon=15offstdcheckcustomer&utm_source=STDcheck_referral&utm_medium=link&utm_campaign=STDcheck_link',
      '_blank'
    );
  }
}
