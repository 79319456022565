import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConsultationRequestOrderDetail } from '@models/consultation-request/consultation-request-order-detail';
import { ConsultationRequest } from '@models/consultation-request/consultation-request';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { ConsultationRequestService } from '@services/consultation-request.service';

@Injectable({
  providedIn: 'root',
})
export class StdConsultationRequestService extends ConsultationRequestService {
  /**
   * Get the details of the requested order.
   *
   * @param {string}                     orderId       Order Id
   * @param {string}                     hash          The hash of the order
   * @param {ConsultationTreatmentTypes} treatmentType The treatment type
   */
  getConsultationOrderDetails(
    orderId: string,
    hash: string,
    treatmentType: ConsultationTreatmentTypes
  ): Observable<ConsultationRequestOrderDetail> {
    return super.getConsultationOrderDetails(orderId, hash, treatmentType).pipe(
      map((orderDetails) => {
        const consultationRequest = new ConsultationRequest();
        consultationRequest.treatment_type = ConsultationTreatmentTypes.Std;

        this.consultationRequestOrderDetail = orderDetails;
        this.consultationRequestOrderDetail.consultationRequest = consultationRequest;

        return orderDetails;
      })
    );
  }
}
