import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormService } from '@services/form.service';
import { FormHelper } from '@common/form-helper';
import { ConsultationRequestService } from '@services/consultation-request.service';

@Component({
  selector: 'app-consultation-request-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss'],
})
export class ContactInformationComponent extends FormHelper implements OnInit {
  contactInfoForm: FormGroup;

  constructor(private formService: FormService, private consultationRequestService: ConsultationRequestService) {
    super();
  }

  ngOnInit(): void {
    this.contactInfoForm = this.formService.consultationRequest.get('contact') as FormGroup;
    if (this.consultationRequestService.consultationOrderDetail) {
      this.prefillContactInformation();
    }
  }

  /**
   * Determines if a form control is invalid.
   *
   * @param {string} controlName The name of the form control to check for validity.
   */
  invalidControl(controlName: string): boolean {
    return this.isInvalid(this.contactInfoForm.get(controlName));
  }

  /**
   * Determines if email field is shown
   */
  get shouldShowEmailField(): boolean {
    return this.consultationRequestService.isScheduled();
  }

  /**
   * Determines if contacted by phone message is shown
   */
  showContactedByPhoneMessage(): boolean {
    return this.consultationRequestService.consultationForm
      .get('contact')
      .get('phone')
      .hasValidator(Validators.required);
  }

  /**
   * Fill form personal information form fields
   */
  private prefillContactInformation() {
    this.contactInfoForm.patchValue({
      email: this.consultationRequestService.consultationOrderDetail.email,
      phone: this.consultationRequestService.consultationOrderDetail.phone,
    });
  }
}
