import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '@services/storage.service';
import { PartialResultsDeliveryService } from '@services/partial-results-delivery.service';
import { MycoplasmaGenitaliumService } from '@services/mycoplasma-genitalium.service';
import { DynamicFormsService } from '@services/dynamic-forms.service';
import { Questionnaire } from '@models/dynamic-forms/questionnaire';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private router: Router,
    private storage: StorageService,
    private injector: Injector,
    private dynamicFormsService: DynamicFormsService
  ) {}

  /**
   * Gets the next navigation URL based on the current URL.
   */
  getNextNavigationUrl(): string {
    const currentUrl = this.router.url;

    switch (currentUrl) {
      case '/order-address.php':
        return this.getOrderAddressUrl();
      case '/mycoplasma-genitalium':
        return this.getBetterLabOrUpsellUrl();
      default:
        return '';
    }
  }

  /**
   * Gets the URL for the order address.
   */
  getOrderAddressUrl(): string {
    const mycoplasmaGenitaliumService = this.injector.get<MycoplasmaGenitaliumService>(MycoplasmaGenitaliumService);

    if (!mycoplasmaGenitaliumService.isMycoplasmaGenitaliumTestOrdered()) {
      return 'mycoplasma-genitalium';
    }

    return this.getBetterLabOrUpsellUrl();
  }

  /**
   * Gets the URL for the better lab or upsell page.
   */
  getBetterLabOrUpsellUrl(): string {
    const partialResultsDeliveryService =
      this.injector.get<PartialResultsDeliveryService>(PartialResultsDeliveryService);

    if (this.storage.betterLab && !this.storage.free) {
      return 'betterlab.php';
    }

    if (partialResultsDeliveryService.shouldRedirectToPartialResultsDelivery()) {
      return 'partial-results-delivery';
    }

    return 'doxy-pep-treatment';
  }

  /**
   * Navigates to the next consultation request page based on the treatment type.
   *
   * @param {ConsultationTreatmentTypes} treatmentType the type of treatment for which to navigate to the next
   * consultation request page
   *
   * @returns {Promise<void>} a promise that resolves when the navigation is complete
   */
  async navigateToNextConsultationRequestPage(treatmentType: ConsultationTreatmentTypes): Promise<boolean> {
    return this.router.navigateByUrl(await this.getConsultationRequestNextPageUrl(treatmentType));
  }

  /**
   * Retrieves the next page URL for a consultation request based on the treatment type.
   *
   * @param {ConsultationTreatmentTypes} treatmentType the type of treatment for which to retrieve the next page URL
   * @param {string} currentUrl the current URL from which to determine the next page URL
   *
   * @returns {Promise<string | undefined>} a promise that resolves to the next page URL or `undefined` if no next page
   * is found
   *
   * @throws {Error} if the current URL is not found in the navigation URLs of the questionnaire
   */
  getConsultationRequestNextPageUrl(
    treatmentType: ConsultationTreatmentTypes,
    currentUrl?: string
  ): Promise<string | undefined> {
    return this.dynamicFormsService
      .getQuestionnaire(treatmentType)
      .then((questionnaire) => this.getNextUrl(questionnaire.navigationUrls, currentUrl));
  }

  /**
   * Determines the next URL in the provided list of URLs.
   *
   * @param {string[]} urls the list of URLs
   * @param {string} currentUrl the current URL from which to determine the next URL
   *
   * @returns {string | undefined} the next URL in the list, or `undefined` if no next URL exists
   *
   * @throws {Error} if the current URL is not found in the list of URLs
   */
  private getNextUrl(urls: string[], currentUrl?: string): string | undefined {
    const currentUrlIndex = urls.indexOf(currentUrl || this.router.url.split('?')[0]);
    if (currentUrlIndex === -1) {
      throw new Error('Current URL not found in the provided URLs');
    }

    return urls[currentUrlIndex + 1];
  }
}
