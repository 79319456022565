import { HttpParams } from '@angular/common/http';
import { CustomURLEncoder } from '@models/custom-url-encoder';
import { LoadCartRequest } from '@models/load-cart-request';
import { Test } from '@models/test';

export class STDcheckLoadCartRequest extends LoadCartRequest {
  tests?: Test[];
  slug?: string | string[];
  coupon: string;
  center_id?: number;
  state_short_name?: string;

  /**
   * Build query params object for load cart url for STDcheck.
   *
   * @returns { HttpParams } the query params object for load cart url.
   **/
  override getHttpParams(): HttpParams {
    let params = new HttpParams({ encoder: new CustomURLEncoder() });

    if (this.center_id) {
      params = params.append('center_id', this.center_id);
    }

    if (this.coupon) {
      params = params.append('coupon', this.coupon);
    }

    if (this.tests) {
      this.tests.forEach((test) => (params = params.append('tests[][name]', test.name)));
    }

    if (this.slug) {
      if (Array.isArray(this.slug)) {
        this.slug.forEach((slug) => (params = params.append('slug[]', slug)));
      } else {
        params = params.append('slug', this.slug);
      }
    }

    if (this.state_short_name) {
      params = params.append('state_short_name', this.state_short_name);
    }

    return params;
  }
}
