import { HttpClient } from '@angular/common/http';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export class PhoneValidator {
  static createValidator(http: HttpClient): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const url = `${environment.analyteCareApi}/phone-validate/${control.value}`;
      return http.get<any>(url).pipe(
        map(() => null),
        catchError(() => of({ inactive: true }))
      );
    };
  }
}
