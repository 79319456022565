import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Network } from '@models/network';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InHomeCollectionService {
  constructor(@Inject(APP_CONFIG) private config: AppConfig, private httpClient: HttpClient) {}

  /**
   * Retrieves the associated networks for a given In-Home Collection (IHC) provider.
   *
   * @param {number} providerId the ID of the provider whose associated networks are to be fetched
   *
   * @returns {Observable<Network[]>} an Observable that resolves to an array of Network objects
   */
  getIHCProviderAssociatedNetworks(providerId: number): Observable<Network[]> {
    return this.httpClient.get<Network[]>(
      `${this.config.analyteCareApi}/api/v1/in-home/providers/${providerId}/networks`
    );
  }
}
