import { HttpParameterCodec } from '@angular/common/http';

export class CustomURLEncoder implements HttpParameterCodec {
  /**
   * Encodes a key name for a URL parameter or query-string.
   *
   * @param {string} key the key name
   *
   * @returns {string} the encoded key name
   */
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  /**
   * Encodes the value of a URL parameter or query-string.
   *
   * @param {string} value the value
   *
   * @returns {string} the encoded value
   */
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  /**
   * Decodes an encoded URL parameter or query-string key.
   *
   * @param {string} key the encoded key name
   *
   * @returns {string} the decoded key name
   */
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  /**
   * Decodes an encoded URL parameter or query-string value.
   *
   * @param {string} value the encoded value
   *
   * @returns {string} the decoded value.
   */
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
