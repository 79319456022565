// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upsell-label {
  cursor: pointer;
  position: relative;
}
.upsell-label:not(:last-child) {
  border-bottom: 1px solid rgba(19, 21, 22, 0.25);
}

@media (hover: hover) {
  .upsell-label-hover:hover {
    background-color: #f7f6fa;
  }
}
.upsell-label-selected {
  background-color: #e2e9d7;
}

.upsell-label-faded:after {
  background: #fcfcf5;
  opacity: 0.8;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.upsell-price {
  background-color: #f7f6fa;
  border-radius: 0.5rem;
  color: #355a73;
  float: right;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
}
.upsell-price .old-price {
  color: #a2a5a7;
  font-size: 1.5rem;
  text-decoration: line-through;
}

.upsell-test-name {
  color: #355a73;
  font-size: 22px;
  font-weight: 700;
}

.upsell-test-description {
  line-height: 1.6;
}
.upsell-test-description p {
  margin-bottom: 1em;
}
.upsell-test-description ul {
  list-style-type: disc;
  margin-left: 1.25rem;
}
@media screen and (max-width: 768px) {
  .upsell-test-description {
    max-height: 24em;
  }
  .upsell-test-description.expanded {
    max-height: 100%;
  }
}

.upsell-read-more {
  color: #355a73;
  display: block;
  text-align: center;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  z-index: 10;
}
@media screen and (min-width: 769px), print {
  .upsell-read-more {
    display: none;
  }
}

.recommended-upsell-test {
  color: #9d120f;
  font-weight: 700;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
