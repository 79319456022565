import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '@services/storage.service';
import { DomainService } from '@services/domain.service';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class OrderCompleteGuard {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private storageService: StorageService,
    private domainService: DomainService
  ) {}

  /**
   * Determines if the order complete page should be loaded.
   */
  canActivate(): boolean {
    if (!!this.storageService.transactionId && this.storageService.addressComplete) {
      if (this.storageService.betterLab && !this.storageService.free) {
        this.router.navigate(['betterlab.php']);

        return false;
      }

      return true;
    }

    if (this.domainService.isTreatMyUtiDomain() || this.domainService.isStarfishDomain()) {
      this.document.location.href = this.config.siteUrls.tests;

      return false;
    }

    this.router.navigate(['order-address.php']);

    return false;
  }
}
