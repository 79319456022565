import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GooglePayService } from '@services/external-payments/google-pay.service';

@Component({
  selector: 'app-google-pay-button',
  templateUrl: './google-pay-button.component.html',
  styleUrls: ['./google-pay-button.component.scss'],
})
export class GooglePayButtonComponent implements OnInit {
  @Input() processing: boolean = false;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();
  googlePayFailedToInitialize: boolean = false;
  buttonReady: boolean = false;

  constructor(private elementRef: ElementRef, private googlePayService: GooglePayService) {}

  ngOnInit(): void {
    this.initializeGooglePay();
  }

  /**
   * Emits the buttonClick event.
   */
  onButtonClicked(): void {
    this.buttonClick.emit();
  }

  /**
   * Ensures there is a valid Google Pay instance and then displays the Google Pay button if the initialization was
   * successful, or shows an error message if the initialization failed.
   */
  private initializeGooglePay(): void {
    const wrapper = this.elementRef.nativeElement.querySelector('#google-pay-button-container');

    this.googlePayService.renderPaymentButton(wrapper).subscribe({
      next: () => (this.buttonReady = true),
      error: () => (this.googlePayFailedToInitialize = true),
    });
  }
}
