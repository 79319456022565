import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { NavigationService } from '@services/navigation.service';
import { SessionStorageService } from '@services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class RecentMenstruationRequiredGuard implements CanActivate {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private navigationService: NavigationService,
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) {}

  /**
   * Determines whether the current route can be activated.
   *
   * @param {ActivatedRouteSnapshot} route the route to be activated
   * @param {RouterStateSnapshot} state the current router state
   *
   * @returns {Promise<boolean | UrlTree>} a promise that resolves to true if the route can be activated, or a UrlTree
   * to redirect otherwise
   */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (this.hasNoMenstrualPeriodInLastYear()) {
      const nextPageUrl = await this.navigationService.getConsultationRequestNextPageUrl(
        this.sessionStorageService.treatmentType,
        state.url
      );

      return this.router.createUrlTree([nextPageUrl]);
    }

    return true;
  }

  /**
   * Checks if the patient has reported no menstrual period in the last year.
   *
   * @returns {boolean} true if the user has reported no menstrual period in the last year, false otherwise
   */
  private hasNoMenstrualPeriodInLastYear(): boolean {
    const legacyQuestionnaire = this.sessionStorageService.questionnaire;
    const dynamicFormAnswers = this.sessionStorageService.getQuestionnaireAnswers(
      this.sessionStorageService.treatmentType
    );

    // TODO: Remove backwards compatibility when only using dynamic forms.
    // Issue #2527: https://github.com/Medology/ng-checkout/issues/2527
    return (
      legacyQuestionnaire?.noMenstrualPeriodInLastYear === QuestionnaireAnswers.Yes ||
      dynamicFormAnswers?.[this.config.noPeriodLastYearQuestionId] === QuestionnaireAnswers.Yes
    );
  }
}
