import { DateData } from '@models/date-data';
import { ConsultationRequestHelper } from './consultation-request-helper';
import { Gender } from '@enums/gender';
import { ConsultationRequestRequest } from '@models/consultation-request/consultation-request-request';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';

export class ConsultationRequestHealthData extends ConsultationRequestRequest {
  dob: DateData;
  gender: string;
  pregnant: QuestionnaireAnswers;
  symptoms: string[];

  constructor(
    allergies: string,
    dob: DateData,
    gender: string,
    pregnant: QuestionnaireAnswers,
    prescriptions: string,
    symptoms: string[]
  ) {
    super();
    this.allergies = allergies;
    this.dob = ConsultationRequestHelper.convertObjectNumbersToStrings(dob) as DateData;
    this.gender = gender;
    if (this.gender === Gender.Female) {
      this.pregnant = pregnant;
    }

    this.prescriptions = prescriptions;
    this.symptoms = symptoms;
  }
}
