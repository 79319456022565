import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Map, ResultService } from '@Medology/ng-findalab';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { MapsLoadService } from '@services/maps-load.service';

@Component({
  selector: 'app-lab-finder-modal',
  templateUrl: './lab-finder-modal.component.html',
  styleUrls: ['./lab-finder-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LabFinderModalComponent implements OnInit {
  @Input() active: boolean = false;
  @Input() searchDescription: string = '';
  @Input() testIds: string[] = [];

  @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  map: Map = null;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private mapsLoadService: MapsLoadService,
    private resultService: ResultService
  ) {}

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.loadMap();
  }

  /**
   * Closes the modal.
   */
  closeModal(): void {
    this.active = false;
    this.activeChange.emit(this.active);
  }

  /**
   * Gets the map configuration based on the provided postal code.
   *
   * @param {string} zipcode the postal code to search for labs
   *
   * @returns {Map} the configured map
   */
  private getMapConfig(zipcode: string): Map {
    let map = {
      searchUrl: {
        hostname: `${this.config.analyteCareApi}/api/v1`,
        path: '/labs/nearPostalCode',
      },
      apiKey: this.config.googleMapApiKey,
      testing: !this.config.production,
      searchFunction: {
        excludeStates: this.config.ngFindalab.excludeStates,
        filterByTests: { key: 'id', values: this.testIds },
      },
      lab: {
        phone: {
          show: false,
        },
        button: {
          show: false,
        },
      },
      search: {
        title: 'Enter Your Location',
        description: this.searchDescription,
        button: {
          class: `button ${this.config.styles.testCenter.searchButtonClass}`,
          loadingText: '...',
          text: this.config.ngFindalab.searchButtonText,
          icon: {
            show: this.config.ngFindalab.showSearchIcon,
            class: 'fa fa-search',
            loadingClass: 'fa fa-spinner fa-spin',
          },
        },
        placeholder: 'Enter your zip',
        inputType: 'tel',
        showDescription: true,
        messageNoResults:
          'Oops! Sorry, we could not find any testing centers near that location. Please try your search again with a different or less specific address.',
      },
      userLocation: {
        showOption: true,
        icon: 'fa fa-map-marker',
        msg: this.config.ngFindalab.locateMsg,
        loading: {
          icon: 'fa fa-spin fa-spinner',
          msg: 'Searching current location...',
        },
      },
      dayOfWeekFilter: {
        showOption: true,
        radioAllText: 'All',
        radioDaysText: {
          0: null,
          1: null,
          2: null,
          3: null,
          4: null,
          5: null,
          6: 'Open Saturdays',
        },
      },
      postalCode: zipcode ?? null,
      mobileBreakpoint: 848,
      googleMaps: {
        defaultLat: 39.97712,
        defaultLong: -99.587403,
        geoCoder: null,
        infoWindow: null,
        initialZoom: 4,
        map: null,
        markers: [],
        resultsZoom: this.config.ngFindalab.resultsZoom,
        labMarkerFillColor: this.config.styles.testCenter.googleMapsLabMarkerFillColor,
        recommendedMarkerFillColor: this.config.styles.testCenter.googleMapsRecommendedMarkerFillColor,
        markerHoverFillColor: this.config.styles.testCenter.googleMapsMarkerHoverFillColor,
      },
      mapId: this.config.findalabMapId,
    };

    return map as Map;
  }

  /**
   * Loads the map configuration.
   */
  private loadMap(): void {
    this.mapsLoadService
      .getGoogleMapApi(this.config.googleMapApiKey)
      .subscribe(() => (this.map = this.getMapConfig(this.resultService.getPostalCode())));
  }
}
