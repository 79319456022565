import { commonEnvironment } from './environment.common';

/**
 * Environment variables for use in all sites, in production environment.
 */
export const environment = {
  ...commonEnvironment,
  production: true,
  analyteCareApi: 'https://api.analytecare.com',
  braintreeToken: 'production_csgfcd5t_53b8hvj83v98kzmr',
  sentry: {
    release: '1.58.2',
    traceSampleRate: '0.05',
    sampleRate: '1',
  },
  stdcheckPage: 'https://www.stdcheck.com',
  googlePay: {
    merchantId: 'BCR2DN4TQ3K3DXR3',
    apiVersion: 2,
    apiVersionMinor: 0,
  },
  defaultOrderLabId: '27349',
};
