import { BillingCountry } from '@enums/billing-countries';
import { BitPayPayment } from '@models/payments/bitpay-payment';
import { PaymentTypes } from '@enums/payment-types';
import { AnalyteCareCouponDto } from './analyte-care-coupon-dto';
import { Sites } from '@enums/sites';
import { ExtraCharge } from './extra-charge';

export class PlaceOrderRequest {
  first_name: string;
  last_name: string;
  gender: string;
  dob: string;
  tests: number[] | { test_id: string; reference_id: string }[];
  lab_id: number;
  state: string;
  leave_message: 'YES' | 'NO';
  accountType: 'email' | 'phone' | 'none';
  coupon: string | AnalyteCareCouponDto[];
  phone: string;
  email: string;
  partner_email: string;
  note: string;
  card_number: string;
  card_expiration: string;
  billing_country:
    | BillingCountry.UnitedStates
    | BillingCountry.Canada
    | BillingCountry.UnitedKingdom
    | BillingCountry.Other;
  billing_zip_code: string;
  cvv: string;
  nonce: string;
  bitpay: BitPayPayment;
  payment_type: 'credit-card' | 'cryptocurrencies' | 'braintree' | 'payLater' | 'recharge' | 'giftCard' | 'free';
  site_name: Sites;
  gift_card_code: string;
  charge_amount: number;
  deviceData?: string;
  cjevent: string;
  guardian_first_name: string;
  guardian_last_name: string;
  guardian_relationship: string;
  in_home_collection?: {
    appointment_key: string;
    street: string;
    city: string;
    state: string;
    zip_code: string;
    provider_id: number;
  };
  extra_charge?: ExtraCharge;

  constructor(checkout: any) {
    Object.assign(this, checkout);
  }

  /**
   * Save guardian information to be sent as request parameters, also rendering a note.
   */
  parseGuardianInfo(patient: any): void {
    if (patient.parentGuardian.relationToChild && patient.parentGuardian.firstName) {
      this.guardian_first_name = patient.parentGuardian.firstName;
      this.guardian_last_name = patient.parentGuardian.lastName;
      this.guardian_relationship = patient.parentGuardian.relationToChild;
      this.note =
        `This patient is minor. The patient's ${patient.parentGuardian.relationToChild} ` +
        `(${patient.parentGuardian.firstName} ${patient.parentGuardian.lastName}) ` +
        'approved and paid for the testing.';
    }
  }

  /**
   * Extracts the payment information.
   */
  parsePaymentMethod(payment: any): void {
    switch (payment.method) {
      case PaymentTypes.CreditCard:
        let month = payment.creditCard.cardMonth.padStart(2, '0');
        let year = String(payment.creditCard.cardYear).substr(-2);
        this.card_number = payment.creditCard.cardNumber;
        this.card_expiration = month + year;
        this.payment_type = 'credit-card';
        this.billing_country = payment.creditCard.billingCountry;
        this.billing_zip_code = payment.creditCard.billingZipCode;
        this.cvv = payment.creditCard.cvv;
        break;
      case PaymentTypes.BitPay:
        this.payment_type = 'cryptocurrencies';
        this.bitpay = new BitPayPayment(payment.bitpay);
        break;
      case PaymentTypes.ApplePay:
      case PaymentTypes.GooglePay:
      case PaymentTypes.Venmo:
      case PaymentTypes.Paypal:
        this.payment_type = 'braintree';
        this.nonce = payment.nonce;
        break;
      case PaymentTypes.Recharge:
        this.payment_type = 'recharge';
        break;
      case PaymentTypes.GiftCard:
        this.payment_type = 'giftCard';
        this.gift_card_code = payment.giftCardCode;
        break;
      case PaymentTypes.Free:
        this.payment_type = 'free';
        break;
      default:
        this.payment_type = 'payLater';
        break;
    }
  }

  /**
   * Set the value of charge_amount if a positive value is passed as a parameter.
   */
  setChargeAmount(chargeAmount: number): void {
    if (chargeAmount) {
      this.charge_amount = chargeAmount;
    }
  }
}
