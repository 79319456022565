import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '@common/form-helper';
import { Month } from '@common/month';
import { Months } from '@common/months';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { dateValidator } from 'src/app/validators/date-validator';
import { DateSelection } from '@models/date-selection';

@Component({
  selector: 'app-consultation-request-patient-info',
  templateUrl: './consultation-request-patient-info.component.html',
  styleUrls: ['./consultation-request-patient-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConsultationRequestPatientInfoComponent extends FormHelper implements OnInit {
  @Input() patientInfoForm: FormGroup;
  @Input() minAge: number;
  @Input() maxAge: number;

  calendarOptions: { months: Month[]; days: number[]; years: number[] };

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private consultationRequestService: ConsultationRequestService
  ) {
    super();
  }

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.setCalendarOptions();
    this.initializeForm();
  }

  /**
   * Gets the error to display if gender pattern validation fails.
   */
  get genderInvalidError(): string {
    return this.config.order.sexInvalidError;
  }

  /**
   * Returns the birth of date control.
   */
  get birthOfDateControl(): FormGroup {
    return this.patientInfoForm?.get('birthday') as FormGroup;
  }

  /**
   * Returns the gender control.
   */
  get genderControl(): FormControl {
    return this.patientInfoForm?.get('gender') as FormControl;
  }

  /**
   * Checks if the age is valid using the birth of date control.
   */
  get isAgeValid(): boolean {
    const dateSelection = new DateSelection(
      Number(this.birthOfDateControl?.get('month').value),
      Number(this.birthOfDateControl?.get('day').value),
      Number(this.birthOfDateControl?.get('year').value)
    );

    return dateSelection.isAgeValid(this.minAge, this.maxAge);
  }

  /**
   * Checks if the gender is valid.
   */
  get isGenderValid(): boolean {
    return this.config.order.allowedGenders.includes(this.genderControl?.value);
  }

  /**
   * Gets the date of birth from the form.
   */
  get dob(): Date | null {
    return this.getDob(this.patientInfoForm);
  }

  /**
   * Gets the minimum name length.
   */
  get minimumNameLength(): number {
    return this.config.minimumNameLength;
  }

  /**
   * Checks if a form control is invalid.
   *
   * @param {string} controlName the name of the form control to check for validity
   *
   * @returns {boolean} true if the control is invalid, false otherwise
   */
  isControlInvalid(controlName: string): boolean {
    return this.isInvalid(this.patientInfoForm?.get(controlName));
  }

  /**
   * Checks if the birthday has an error after filled.
   *
   * @returns {boolean} true if the birthday has an error, false otherwise
   */
  hasBirthdayError(): boolean {
    return this.isBirthdayFilledOut() && (this.isControlInvalid('birthday') || !this.isAgeValid);
  }

  /**
   * Checks whether the birthday is filled out.
   *
   * @returns {boolean} true if the birthday is filled out, false otherwise
   */
  private isBirthdayFilledOut(): boolean {
    return ['month', 'day', 'year'].every((controlName) => !this.isControlInvalid(`birthday.${controlName}`));
  }

  /**
   * Initializes the patient information form.
   */
  private initializeForm(): void {
    this.prefillPatientInformation();
    this.setFormValidators();
  }

  /**
   * Adds validators to the patient information form.
   */
  private setFormValidators(): void {
    this.birthOfDateControl?.setValidators([dateValidator()]);
    this.birthOfDateControl?.updateValueAndValidity();
    this.genderControl?.setValidators([Validators.required]);
    this.genderControl?.updateValueAndValidity();
  }

  /**
   * Prefills the patient information form with the order details.
   */
  private prefillPatientInformation(): void {
    const dateOfBirth = new Date(this.consultationRequestService.consultationOrderDetail?.date_of_birth);
    this.patientInfoForm?.patchValue({
      ...this.consultationRequestService.consultationOrderDetail,
      first_name: this.consultationRequestService.consultationOrderDetail?.consultationRequest.first_name,
      last_name: this.consultationRequestService.consultationOrderDetail?.consultationRequest.last_name,
      birthday: {
        month: dateOfBirth.getMonth() + 1,
        day: dateOfBirth.getDate(),
        year: dateOfBirth.getFullYear(),
      },
    });
    if (this.consultationRequestService.consultationOrderDetail?.gender) {
      this.patientInfoForm?.get('gender').markAsTouched();
    }
  }

  /**
   * Sets the calendar options.
   */
  private setCalendarOptions(): void {
    const currentYear = new Date().getFullYear();
    this.calendarOptions = {
      months: Months,
      days: this.getListOfNumbers(1, 31),
      years: this.getListOfNumbers(currentYear, currentYear - this.config.maxAgeAllowed),
    };
  }
}
