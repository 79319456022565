import { ConsultationRequestPayment } from './consultation-request-payment';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { ConsultationTypes } from '@enums/consultation-types';
import { ConsultationRequestAdditionalInfo } from '@models/consultation-request/consultation-request-additional-info';
import { Recharge } from '@models/recharge';
import { ConsultationStatuses } from '@enums/consultation-statuses';

export class ConsultationRequestRequest {
  date_of_birth: string;
  email: string;
  first_name: string;
  last_name: string;
  order_id: string;
  payment: ConsultationRequestPayment;
  phone: string;
  sex: string;
  type: ConsultationTypes;
  treatment_type: ConsultationTreatmentTypes;
  consultation_status: ConsultationStatuses;
  num_treatments: number;
  pharmacy_id: number;
  additional_info: string | ConsultationRequestAdditionalInfo;
  allergies: string;
  prescriptions: string;
  health_info: string;
  due_date: string;
  ethnicity: string;
  last_menstrual_date: string;
  positive: string[];
  race: string;
  recharge: Recharge;
  sex_with: string;
  terms: boolean;
  user_id: number;
  status: string;
}
