import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Question } from '@models/dynamic-forms/question';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, NgForOf],
})
export class CheckboxInputComponent {
  @Input() form: FormGroup;
  @Input() question: Question;

  /**
   * Gets the form array for the question's form control.
   *
   * @returns {FormArray} the form array
   */
  get formArray(): FormArray {
    return this.form.get(this.question.id) as FormArray;
  }

  /**
   * Handles the change event for a checkbox input.
   *
   * @param {Event} event the change event
   */
  onCheckboxChange(event: Event): void {
    this.formArray.markAllAsTouched();
    const { checked, value } = event.target as HTMLInputElement;
    if (checked) {
      this.formArray.push(new FormControl(value));

      return;
    }

    this.formArray.removeAt(this.formArray.controls.findIndex((control) => control.value == value));
  }

  /**
   * Determines if the checkbox should be checked based on the provided value.
   *
   * @param {string} value the value to check against the form controls
   *
   * @returns {boolean} true if the checkbox should be checked, false otherwise
   */
  shouldCheckCheckbox(value: string): boolean {
    return this.formArray.controls.some((control) => control.value === value);
  }
}
