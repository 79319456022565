import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { DomainService } from '@services/domain.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '@services/loading.service';
import { FormHelper } from '@common/form-helper';
import { ConsultationRequestOrderDetail } from '@models/consultation-request/consultation-request-order-detail';
import { FormGroup, Validators } from '@angular/forms';
import { FormService } from '@services/form.service';
import { StorageService } from '@services/storage.service';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { ConsultationStdPreventiveQuestionnaireService } from '@services/consultation-std-preventive-questionnaire.service';
import { ConsultationRequestFormAlreadySubmittedError } from '@errors/consultation-request-form-already-submitted-error';
import { Subscription } from 'rxjs';
import { MissingFormFieldsError } from '@errors/missing-form-fields-error';

@Component({
  selector: 'app-consultation-doxy-pep',
  templateUrl: './consultation-doxy-pep.component.html',
  styleUrls: ['./../consultation-request/consultation-request.component.scss', 'consultation-doxy-pep.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConsultationDoxyPepComponent extends FormHelper implements OnInit, OnDestroy {
  errorMessage: string;
  orderDetail: ConsultationRequestOrderDetail;
  consultationId: string;
  isProcessingRequest: boolean = false;
  submitError: string;
  private subscriptions: Subscription[] = [];

  /**
   * Get the FormGroup for the DoxyPEP consultation form
   */
  get consultationForm(): FormGroup {
    return this.formService.doxyPepConsultationRequest;
  }

  /**
   * Get the FormGroup for the pharmacy section
   */
  get pharmacyForm(): FormGroup {
    return this.consultationForm.get('pharmacy') as FormGroup;
  }

  /**
   * Get the FormGroup for the additional info section
   */
  get additionalInfoForm(): FormGroup {
    return this.consultationForm.get('additional_info') as FormGroup;
  }

  /**
   * Gets the questionnaire form group inside the additional info form group.
   */
  get questionnaireForm(): FormGroup {
    return this.additionalInfoForm.get('questionnaire') as FormGroup;
  }

  /**
   * Gets the date of birth.
   */
  get dob(): Date | null {
    return this.orderDetail.date_of_birth ? new Date(this.orderDetail.date_of_birth) : null;
  }

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    public consultationRequestService: ConsultationRequestService,
    public domainService: DomainService,
    private activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    private formService: FormService,
    private storageService: StorageService,
    private router: Router,
    private consultationStdPreventiveQuestionnaireService: ConsultationStdPreventiveQuestionnaireService
  ) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ consultationOrderDetails }) => {
      if (consultationOrderDetails?.error) {
        this.errorMessage = consultationOrderDetails.errorMessage;
        this.loadingService.toggleLoader(false);

        return;
      }

      this.orderDetail = consultationOrderDetails;

      this.storeDataOnLocalStorage();
      this.loadConsultations();
      this.addListenerToConsultationForm();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Add the necessary form's subscriptions to the subscriptions array.
   */
  addListenerToConsultationForm(): void {
    this.subscriptions.push(
      this.formService.doxyPepConsultationRequest.valueChanges.subscribe(() => {
        if (this.formService.doxyPepConsultationRequest.valid) {
          this.submitError = null;
        }
      })
    );
  }

  /**
   * Get the FormGroup for the terms section
   */
  get termsFormGroup(): FormGroup {
    return this.formService.doxyPepConsultationRequest.get('terms') as FormGroup;
  }

  /**
   * Return true if the current patient is female
   */
  get isFemale(): boolean {
    return this.orderDetail?.gender === 'F';
  }

  /**
   * Store data on localStorage
   */
  storeDataOnLocalStorage(): void {
    this.storageService.transactionId = this.orderDetail?.transaction_id;
    this.storageService.patient = {
      name: this.orderDetail?.account.first_name,
      email: this.orderDetail?.email,
      phone: this.orderDetail?.phone,
    };
  }

  /**
   * Retrieves consultations
   */
  loadConsultations(): void {
    const filters = {
      consultations_status: 'incomplete',
      treatment_type: 'std_prevention',
    };

    this.consultationRequestService
      .getConsultations(this.orderDetail?.transaction_id, this.orderDetail?.hash, JSON.stringify(filters))
      .subscribe({
        next: (data) => {
          if (!data.data.length) {
            this.errorMessage = new ConsultationRequestFormAlreadySubmittedError(this.config.email).message;
          } else {
            this.consultationId = data.data[0].id;
            this.toggleIsFemaleValidators();
          }

          this.loadingService.toggleLoader(false);
        },
        error: () => {
          this.errorMessage = `We are very sorry, it seems like there was a problem. Please contact support at ${this.config.email}.`;
          this.loadingService.toggleLoader(false);
        },
      });
  }

  /**
   * Toggle the validators for the "isFemale" controls
   */
  toggleIsFemaleValidators(): void {
    this.termsFormGroup.get('tos3').removeValidators(Validators.requiredTrue);
    if (this.isFemale) {
      this.termsFormGroup.get('tos3').setValidators(Validators.requiredTrue);
    }

    this.termsFormGroup.get('tos3').updateValueAndValidity();
  }

  /**
   * Submit the DoxyPEP consultation form
   */
  submit(): void {
    if (!this.doxyPepConsultationValidation()) {
      return;
    }

    this.isProcessingRequest = true;

    this.consultationRequestService
      .update(
        this.consultationId.toString(),
        this.orderDetail.transaction_id,
        this.orderDetail.hash,
        this.consultationStdPreventiveQuestionnaireService.getConsultationRequestPayload(this.isFemale)
      )
      .subscribe({
        next: () => this.onRequestSuccess(),
        error: () => this.onRequestError(),
      });
  }

  /**
   * Validate the DoxyPEP consultation form
   */
  private doxyPepConsultationValidation(): boolean {
    this.submitError = null;
    if (this.formService.doxyPepConsultationRequest.invalid) {
      this.submitError = new MissingFormFieldsError().message;
      if (!this.formService.doxyPepConsultationRequest.value.pharmacy.id) {
        this.submitError = `Please select a pharmacy.`;
      }

      this.formService.doxyPepConsultationRequest.markAllAsTouched();

      return false;
    }

    return true;
  }

  /**
   * Redirect to the consultation complete page
   */
  private onRequestSuccess(): void {
    this.router.navigateByUrl(
      `/${ConsultationTreatmentTypes.StdPrevention.replace(/_/g, '-')}/consultation-request-completed`
    );
  }

  /**
   * Set the request error message
   */
  private onRequestError(): void {
    this.isProcessingRequest = false;
    this.submitError = `There was an error processing your request. Please contact customer support.`;
  }
}
