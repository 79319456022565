// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-header {
  background-color: #809c58 !important;
}

.card-header-title {
  color: #fff;
  background-color: #809c58;
  font-weight: bold;
  font-size: 1.25em;
}
.card-header-title i.fa {
  margin-right: 5px;
}

.card-header-icon a, .card-header-icon a:hover {
  color: #fff;
}

.card-body {
  background-color: #ecf1e5;
  padding: 0 0.5rem;
}

.table {
  background-color: #ecf1e5;
}
.table td, .table th {
  border: none;
}
.table thead {
  border-bottom: 1px dashed #bebebe;
}
.table tfoot {
  border-top: 1px dashed #bebebe;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
