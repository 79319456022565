import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PaymentInfoComponent } from '@components/payment-info/payment-info.component';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { FormService } from '@services/form.service';
import { PaymentTypes } from '@enums/payment-types';

@Component({
  selector: 'app-consultation-request-payment-info',
  templateUrl: './consultation-request-payment-info.component.html',
  styleUrls: ['./consultation-request-payment-info.component.scss'],
})
export class ConsultationRequestPaymentInfoComponent implements AfterViewInit, OnInit {
  @Input() freeEnabled: boolean = false;
  @ViewChild('paymentInfo', { static: false }) paymentInfoComponent: PaymentInfoComponent;

  PaymentTypes: typeof PaymentTypes = PaymentTypes;
  consultationRequestPaymentForm: FormGroup;

  constructor(public consultationRequestService: ConsultationRequestService, private formService: FormService) {}

  /**
   * Gets the disabled payment methods.
   */
  get disabledPaymentMethods(): PaymentTypes[] {
    return [PaymentTypes.GiftCard, PaymentTypes.BitPay, ...(!this.freeEnabled ? [PaymentTypes.Free] : [])];
  }

  /**
   * Get consultation payment form.
   */
  get paymentForm(): FormGroup {
    return this.consultationRequestPaymentForm?.get('payment') as FormGroup;
  }

  /**
   * Listen for query params changes and initialize form.
   */
  ngOnInit(): void {
    this.consultationRequestPaymentForm = this.formService.consultationRequest.get('payment') as FormGroup;
  }

  /**
   * Add validators to payment form.
   */
  ngAfterViewInit(): void {
    this.paymentInfoComponent.addDynamicValidators(true);
  }
}
