// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h5 {
  color: #15232d;
  font-family: Open Sans, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.medical-text-area {
  height: 75px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
