import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectableOption } from '@models/selectable-option';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';

@Component({
  selector: 'app-radio-buttons-question-card',
  templateUrl: './radio-buttons-question-card.component.html',
  styleUrls: ['./radio-buttons-question-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RadioButtonsQuestionCardComponent {
  @Input() buttonText: string;
  @Input() displayButton: boolean = true;
  @Input() controlName: string;
  @Input() form: FormGroup;
  @Input() hints: string[] = [];
  @Input() question: string;
  @Input() processing: boolean = false;
  @Input() options: SelectableOption[] = [
    new SelectableOption(QuestionnaireAnswers.Yes),
    new SelectableOption(QuestionnaireAnswers.No),
  ];
  @Input() title: string;
  @Output() buttonClick = new EventEmitter<void>();

  constructor() {}
}
