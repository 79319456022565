// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

.same-width-btn {
  min-width: 200px;
}

.doxypep-content p,
.doxypep-content ul {
  line-height: 1.8;
}

.doxypep-img {
  width: 500px;
}

.limit-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.card {
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
}
.card .card-header {
  position: relative;
  padding: 1rem 2rem 1rem 1rem !important;
  border-radius: 0.5rem 0.5rem !important;
  background-color: white;
  color: black !important;
  font-size: medium !important;
}
.card .card-header::after {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px;
  content: "";
  right: 12px;
  border-bottom-width: 0;
  border-color: #ed9013 transparent transparent;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  transform: rotate(270deg);
}
.card.is-active .card-header {
  background-color: #ed9013;
  border-radius: 0.5rem 0.5rem 0 0 !important;
  color: white !important;
}
.card.is-active .card-header::after {
  border-color: white transparent transparent !important;
  transform: rotate(0deg);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
