import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';

@Component({
  selector: 'app-std-consultation-request-completed',
  templateUrl: './std-consultation-request-completed.component.html',
  styleUrls: [
    '../consultation-request/consultation-request.component.scss',
    './std-consultation-request-completed.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class StdConsultationRequestCompletedComponent implements OnInit {
  orderId: string;
  hash: string;

  constructor(@Inject(APP_CONFIG) public config: AppConfig, private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.config.titles.consultationRequest);
  }
}
