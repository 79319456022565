// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-stdcheck-layout .main-content {
  padding: 0px;
}
app-stdcheck-layout div.primary.title {
  padding: 1.5rem 0 0.5rem;
  background: linear-gradient(rgba(161, 191, 211, 0.5), rgba(230, 238, 244, 0.5)), url('bg-chevron.f9a6cfe3aed8c769.png');
  background-color: #a1bfd3;
  border-bottom: 1px solid #c9cbcc;
  margin-bottom: -1px;
}
app-stdcheck-layout div.primary.title h1 {
  text-shadow: 1px 1px 3px #fff;
  line-height: 1;
  margin: 0;
  font-size: 2.25rem;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: bold;
  color: #15232d;
}
app-stdcheck-layout .result-recommended {
  border-color: #ed9013 !important;
}
app-stdcheck-layout .result-recommended .recommended-label {
  background-color: #ed9013;
}
app-stdcheck-layout .result-recommended button {
  font-weight: bold;
}

app-healthlabs-layout div.primary.title {
  text-align: center;
  margin-top: 1em;
}
app-healthlabs-layout div.primary.title h1 {
  font-weight: bold;
  font-size: 37px;
  color: #42ab68;
}
app-healthlabs-layout div.primary.title h1 span {
  font-size: 37px;
  color: #2a6290;
}
app-healthlabs-layout .result-recommended {
  border-color: #2a6290 !important;
}
app-healthlabs-layout .result-recommended .recommended-label {
  background-color: #2a6290;
}

.notification p {
  font-size: inherit;
  line-height: 1.6;
  margin-bottom: 1rem;
  text-rendering: optimizeLegibility;
}
.notification p:last-child {
  margin-bottom: 0;
}

button {
  border-radius: 0.5rem !important;
}

@media only screen and (max-width: 768px) {
  div.findalab-container-mobile {
    height: 795px !important;
  }
  app-stdcheck-layout div.primary.title {
    padding: 1.5rem 0 0.5rem !important;
  }
  app-stdcheck-layout div.primary.title h1 {
    font-size: 1.5rem;
  }
  app-stdcheck-layout .findalab__nav button {
    border-radius: 0px !important;
  }
  app-stdcheck-layout .main-content #betterlab-container .container {
    margin: 0.625rem;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
