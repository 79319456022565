import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConsultationRequestOrderDetail } from '@models/consultation-request/consultation-request-order-detail';
import { Symptom } from '@enums/symptoms';
import { FormService } from './form.service';
import { PaymentTypes } from '@enums/payment-types';
import { Treatment } from '@enums/treatment';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ConsultationRequest } from '@models/consultation-request/consultation-request';
import { StdConsultationRequestRequest } from '@models/consultation-request/std-consultation-request-request';
import { NewConsultationRequestResponse } from '@models/consultation-request/new-consultation-request-response';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationTypes } from '@enums/consultation-types';
import { ConsultationRequestRequest } from '@models/consultation-request/consultation-request-request';
import { ConsultationRequestOrderStatus } from '@enums/consultation-request-order-status';
import { ConsultationRequestOrderCanceledError } from '@errors/consultation-request-order-canceled-error';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { SessionStorageService } from '@services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ConsultationRequestService {
  static cost = 9500;
  static symptoms = Object.keys(Symptom).map((key) => ({ name: key, value: Symptom[key] }));
  static partnerTreatmentSTDs = ['chlamydia', 'gonorrhea', 'trichomoniasis'];

  private apiUrl = `${this.config.analyteCareApi}/api/v1`;
  private apiConsultationUrl = `${this.apiUrl}/consultation`;
  protected consultationRequestOrderDetail: ConsultationRequestOrderDetail;

  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected http: HttpClient,
    protected formService: FormService,
    protected sessionStorageService: SessionStorageService
  ) {}

  /**
   * Get consultation request form group from form service.
   */
  get consultationForm(): FormGroup {
    return this.formService.consultationRequest;
  }

  /**
   * Get the consultation form treatment control.
   */
  get treatmentControl(): AbstractControl {
    return this.consultationForm.get('prescription').get('treatment');
  }

  /**
   * Get the consultation order detail.
   */
  get consultationOrderDetail(): ConsultationRequestOrderDetail {
    return this.consultationRequestOrderDetail;
  }

  /**
   * Get the public manager user info.
   *
   * @param {number} userId Manager user id
   */
  getManageUser(userId: number): Observable<{ id: number }> {
    return this.http.get<{ id: number }>(`${this.config.analyteCareApi}/api/v1/users/${userId}`);
  }

  /**
   * Get the details of the requested order.
   *
   * TODO: the treatmentType is a temporary fix, we need to consolidate DoxyPEP and remove it in the future.
   * Issue: https://github.com/Medology/ng-checkout/issues/2445
   *
   * @param {string}                     orderId       Order Id
   * @param {string}                     hash          The hash of the order
   * @param {ConsultationTreatmentTypes} treatmentType The treatment type (only children classes could use it)
   */
  getConsultationOrderDetails(
    orderId: string,
    hash: string,
    treatmentType: ConsultationTreatmentTypes
  ): Observable<ConsultationRequestOrderDetail> {
    return this.http
      .get<ConsultationRequestOrderDetail>(`${this.apiConsultationUrl}/order/${orderId}`, {
        headers: this.createAuthorizationHeader(hash, orderId),
      })
      .pipe(
        map((orderDetails) => {
          if (orderDetails.status === ConsultationRequestOrderStatus.Canceled) {
            throw new ConsultationRequestOrderCanceledError(this.config.siteUrls.home, this.config.domain);
          }

          return orderDetails;
        })
      );
  }

  /**
   * Get the consultation request positives array.
   */
  getConsultationRequestPositives(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiConsultationUrl}/request/positives`);
  }

  /**
   * Get consultation request discount.
   *
   * @returns the consultation request discount amount (cents)
   */
  getDiscount(): number {
    const discount = this.formService.consultationRequest.get('payment').get('discount').value;
    if (isNaN(discount) || discount <= 0) {
      return 0;
    }

    return discount * 100;
  }

  /**
   * Get the subtotal to pay for the consultation request by multiplying the number of treatments
   * by the cost of each consultation request.
   *
   * @returns the subtotal amount (cents) to be paid
   */
  getSubtotal(): number {
    const treatments = this.treatmentControl.value === Treatment.SelfAndPartner ? 2 : 1;

    return treatments * ConsultationRequestService.cost;
  }

  /**
   * Get consultation request total, by subtracting the discount from the subtotal.
   *
   * @returns the amount (cents) to be paid for the consultation request
   */
  getTotal(): number {
    const paymentMethod = this.consultationForm.get('payment').get('payment').get('method').value;
    if (paymentMethod === PaymentTypes.Free) {
      return 0;
    }

    const total = this.getSubtotal() - this.getDiscount();

    return total > 0 ? total : 0;
  }

  /**
   * Check if consultation request type is Async.
   *
   * @returns boolean
   */
  isAsync(): boolean {
    return this.consultationForm.get('type').value.consultationType === ConsultationTypes.Asynchronous;
  }

  /**
   * Check if consultation request type is Scheduled.
   *
   * @returns boolean
   */
  isScheduled(): boolean {
    return this.consultationForm.get('type').value.consultationType === ConsultationTypes.Scheduled;
  }

  /**
   * Call to postConsultationRequest using variables from this service.
   *
   * @param positiveTestsSlugs slugs of the positive tests
   * @param userId             the manager user id
   * @param deviceData         the device data from Braintree
   */
  submitConsultationRequest(
    positiveTestsSlugs: string[],
    userId: number,
    deviceData: string
  ): Observable<NewConsultationRequestResponse> {
    const requestBody = new StdConsultationRequestRequest(
      this.consultationForm,
      this.consultationOrderDetail,
      positiveTestsSlugs,
      userId,
      this.getSubtotal(),
      deviceData
    );

    // Be careful changing this order. The consultation request fails if the
    // request does not have the expected order.
    const expectedRequestObjectKeysOrder = [
      'order_id',
      'dob',
      'positive',
      'symptoms',
      'race',
      'payment',
      'additional_info',
      'user_id',
      'first_name',
      'last_name',
      'email',
      'phone',
      'gender',
      'sex',
      'ethnicity',
      'pregnant',
      'sex_with',
      'recharge',
      'allergies',
      'prescriptions',
      'health_info',
      'pharmacy_id',
      'terms',
      'date_of_birth',
      'num_treatments',
      'due_date',
      'last_menstrual_date',
      'type',
    ];

    return this.postConsultationRequest(
      this.getSortedKeysObject(requestBody, expectedRequestObjectKeysOrder) as StdConsultationRequestRequest,
      this.consultationOrderDetail.hash,
      this.consultationOrderDetail.transaction_id
    );
  }

  /**
   * Sorts the keys of an object according to the array of keys in the expected order.
   * Object keys that are not in the array will be added to the end of the object.
   *
   * @param object the original object
   * @param expectedOrder an array of the keys in the order they should appear in the object
   * @returns the object with the keys in order
   */
  private getSortedKeysObject(object: StdConsultationRequestRequest, expectedOrder: string[]): Object {
    const sortedKeysObject = {};

    expectedOrder.forEach((key) => {
      sortedKeysObject[key] = object[key];
      delete object[key];
    });

    Object.keys(object).forEach((key) => (sortedKeysObject[key] = object[key]));

    return sortedKeysObject;
  }

  /**
   * Makes a post request to store a new consultation request.
   *
   * @param requestBody consultation request request body
   * @param hash hash id from the order
   * @param orderId transaction id from the order
   * @returns
   */
  postConsultationRequest(
    requestBody: ConsultationRequestRequest,
    hash: string,
    orderId: string
  ): Observable<NewConsultationRequestResponse> {
    return this.http.post<NewConsultationRequestResponse>(`${this.apiConsultationUrl}/request`, requestBody, {
      headers: this.createAuthorizationHeader(hash, orderId),
    });
  }

  /**
   * Generate the Http Headers that enable us to authenticate into stdcheck
   *
   * @param hash        the hash of the order
   * @param customerId  the id of the order
   */
  createAuthorizationHeader(hash: string, customerId: string): HttpHeaders {
    return new HttpHeaders({ 'X-API-Hash': hash, 'X-API-Id': customerId });
  }

  /**
   * Request to retrieve a Consultation Request
   *
   * @param consultationRequestId the consultation request id
   * @param customerId            the order id
   * @param hash                  the hash of the order
   */
  getConsultationRequest(
    consultationRequestId: string,
    customerId: string,
    hash: string
  ): Observable<ConsultationRequest> {
    return this.http.get<any>(`${this.apiConsultationUrl}/request/${consultationRequestId}`, {
      headers: this.createAuthorizationHeader(hash, customerId),
    });
  }

  /**
   * Request to update a Consultation Request
   *
   * @param consultationRequestId the consultation request id
   * @param customerId            the order id
   * @param hash                  the hash of the order
   * @param body                  the body to the request
   */
  update(consultationRequestId: string, customerId: string, hash: string, body: {}): Observable<any> {
    return this.http.put<any>(`${this.apiConsultationUrl}/request/${consultationRequestId}`, body, {
      headers: this.createAuthorizationHeader(hash, customerId),
    });
  }

  /**
   * Get the consultations of the requested order.
   *
   * @param {string} orderId Order Id
   * @param {string} hash    The hash of the order
   * @param {string} filters The filters to apply to the request
   */
  getConsultations(orderId: string, hash: string, filters: string): Observable<{ data: ConsultationRequest[] }> {
    return this.http.get<any>(`${this.apiUrl}/order/${orderId}/consultations`, {
      headers: this.createAuthorizationHeader(hash, orderId),
      params: new HttpParams().append('filters', filters),
    });
  }

  /**
   * Store the attachment.
   *
   * @param {string} transactionId The order id
   * @param {string} hash The hash of the order
   * @param {string} consultationId The consultation id
   * @param body
   */
  storeAttachment(transactionId: string, hash: string, consultationId: string, body): Observable<any> {
    return this.http.post(`${this.apiUrl}/order/${transactionId}/consultation/${consultationId}/attachment`, body, {
      headers: this.createAuthorizationHeader(hash, transactionId),
    });
  }

  /**
   * Get an array of ordered upsells names from the consultation order detail.
   *
   * @returns an array of ordered upsells names
   */
  getOrderedUpsells(): string[] {
    return this.consultationOrderDetail?.tests
      .filter((customerTest) => customerTest.customer_tests_type === 'Upsell Individual')
      .map((customerTest) => customerTest.customer_tests_name);
  }

  /**
   * Stores the ordered upsells name list on session storage.
   */
  storeOrderedUpsellsOnSessionStorage(): void {
    this.sessionStorageService.orderedUpsells = this.getOrderedUpsells();
  }
}
